import { Product } from '@omniafishing/core';
import { Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDone, isPending } from '../../constants/loading_state';
import { useFlashMessage } from '../../hooks/use_flash_message';
import { usePrevious } from '../../hooks/use_previous';
import { useUser } from '../../hooks/use_user';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { eventNotificationSubscribe, getLoadingState } from '../../redux/event_notifications';
import { ProductCarryForm } from './product_carry_form';
import styles from './product_carry_modal.less';

interface ProductCarryModalProps {
  product: Product;
  open: boolean;
  onClose: () => void;
}

export const ProductCarryModal = (props: ProductCarryModalProps) => {
  const { open, product, onClose } = props;
  const { user } = useUser();
  const loadingState = useSelector(getLoadingState);
  const formRef = React.createRef<FormInstance>();
  const prevLoadingState = usePrevious(loadingState);
  const { setFlashMessage } = useFlashMessage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPending(prevLoadingState) && isDone(loadingState)) {
      setFlashMessage({
        header: 'You’re on the list',
        subheader: `We will notify you when Omnia carries ${product.title}`,
      });
      onCancel();
    }
  }, [loadingState]);

  const onOk = () => {
    const form = formRef.current;
    interface FormValues {
      email?: string;
    }
    form
      .validateFields()
      .then((values: FormValues) => {
        dispatch(
          eventNotificationSubscribe({
            subscribable_id: product.id,
            event: 'product_added',
            email: values.email,
          })
        );
      })
      .catch((e) => null);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="Carry this product"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
      okText="Subscribe"
      okButtonProps={{
        loading: isPending(loadingState),
      }}
      cancelButtonProps={{
        disabled: isPending(loadingState),
      }}
    >
      <h1 className={styles.h1}>Receive an email when we carry this product?</h1>
      {product && (
        <div className={styles.product}>
          <div className={styles.img}>
            <img src={setShopifyImgWidth(product.img_url, 300)} alt="" />
          </div>
          <h2 className={styles.h2}>{`${product.title}`}</h2>
        </div>
      )}
      <ProductCarryForm formRef={formRef} user={user} />
    </Modal>
  );
};
