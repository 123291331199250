import { CartPromo, Category, isCartPromoCartPrice, ShopifyCartLine } from '@omniafishing/core';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCategoryByProductType } from '../../categories_subcategories';
import { useCart } from '../../hooks/use_cart';
import { useReferenceData } from '../../hooks/use_reference_data';
import { useRewardsPercentages } from '../../hooks/use_rewards_percentages';
import { useUser } from '../../hooks/use_user';
import { toDollars } from '../../lib/to_dollars';
import { CartActions, getCartPromos, getRebateBalance, hasRebateBalance } from '../../redux/cart';
import { SignupLoginModalActions } from '../../redux/signup_login_modal';
import { RoutePaths } from '../../routes';
import { PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS } from '../login_signup_modal/signup_login_modal';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './cart.less';

const lineItemIsGiftCard = (lineItem: ShopifyCartLine) => {
  const title = lineItem.merchandise.product.title.toLowerCase();
  return title.includes('gift') || title.includes('card');
};
const lineItemIsOnSaleOverThreshold = (lineItem: ShopifyCartLine) => {
  return (
    Number(lineItem.merchandise.price.amount) /
      Number(lineItem.merchandise.compareAtPrice.amount) <=
    0.8
  );
};

const lineItemIsCartPromo = (
  lineItem: ShopifyCartLine,
  cartPricePromos: CartPromo[],
  cartPromoHandles: string[]
) => {
  if (!cartPromoHandles.includes(lineItem.merchandise.product.handle)) {
    return false;
  }
  const cartPromo = cartPricePromos.find(
    (p) => p.product_family.handle === lineItem.merchandise.product.handle
  );
  const itemPrices = cartPromo.cart_promos.map((p) => p.item_price);
  let price = Number(lineItem.merchandise.price.amount) * lineItem.quantity;
  const totalDiscounts = lineItem.discountAllocations.reduce((sum, allocation) => {
    return sum + Number(allocation.discountedAmount.amount);
  }, 0);
  if (totalDiscounts > 0) {
    price = price - totalDiscounts;
  }
  // if an line item price matches the promo price, its has promo pricing
  return itemPrices.includes(price);
};

const splitLineItemsIntoSingleDoubleRewards = (
  lineItems: ShopifyCartLine[],
  categories: Category[]
) => {
  const singleRewardLineItems: ShopifyCartLine[] = [];
  const doubleRewardLineItems: ShopifyCartLine[] = [];
  lineItems.forEach((lineItem) => {
    if (
      getCategoryByProductType(categories, lineItem.merchandise.product.productType)?.name ===
      'apparel'
    ) {
      doubleRewardLineItems.push(lineItem);
    } else {
      singleRewardLineItems.push(lineItem);
    }
  });
  return [singleRewardLineItems, doubleRewardLineItems];
};

export const sumLineItemsSubtotal = (lineItems: ShopifyCartLine[]) => {
  return lineItems.reduce((acc, lineItem) => {
    const lineSubtotal = parseFloat(lineItem.merchandise.price?.amount) * lineItem.quantity;
    const lineDiscount = lineItem.discountAllocations.reduce(
      (lineAcc, discount) => lineAcc + parseFloat(discount.discountedAmount.amount),
      0
    );

    const lineTotal = lineSubtotal - lineDiscount;
    return acc + lineTotal;
  }, 0);
};

export const CartProUpsell = () => {
  const promos = useSelector(getCartPromos);
  const { single_rewards_percent, double_rewards_percent } = useRewardsPercentages();

  const { cart, applyProRewards, removeProRewards } = useCart();
  const {
    lines: { nodes: lineItems },
  } = cart;
  const yesRebateBalance = useSelector(hasRebateBalance);
  const rebateBalance = useSelector(getRebateBalance);
  const { allCategories } = useReferenceData();
  const dispatch = useDispatch();

  const { isPro, isPremium } = useUser();
  const hasPremiumMembership = isPro || isPremium;

  const hasGiftCards = cart.appliedGiftCards.length > 0;
  const giftCardsAppliedInCents = cart.appliedGiftCards.reduce(
    (acc, giftCard) => acc + Number(giftCard.amountUsed.amount),
    0
  );

  const calculateRewardsAmount = () => {
    const cartPricePromos = promos.filter((p) => isCartPromoCartPrice(p));
    const cartPromoHandles = cartPricePromos.map((p) => p.product_family.handle);

    const rewardableLineItems = lineItems
      .filter((li) => !lineItemIsGiftCard(li))
      .filter((li) => !lineItemIsOnSaleOverThreshold(li))
      .filter((li) => !lineItemIsCartPromo(li, cartPricePromos, cartPromoHandles));

    const [singleRewardLineItems, doubleRewardLineItems] = splitLineItemsIntoSingleDoubleRewards(
      rewardableLineItems,
      allCategories
    );

    let singleRewardLineItemsSubtotal = sumLineItemsSubtotal(singleRewardLineItems);
    let doubleRewardLineItemsSubtotal = sumLineItemsSubtotal(doubleRewardLineItems);

    const giftCardsAppliedInDollars = giftCardsAppliedInCents / 100;

    if (doubleRewardLineItemsSubtotal > 0) {
      doubleRewardLineItemsSubtotal -= giftCardsAppliedInDollars;
      if (doubleRewardLineItemsSubtotal < 0) {
        // adding because doubleRewardLineItemsSubtotal is a negative number
        singleRewardLineItemsSubtotal += doubleRewardLineItemsSubtotal;
        doubleRewardLineItemsSubtotal = 0;
      }
    } else {
      singleRewardLineItemsSubtotal -= giftCardsAppliedInDollars;
    }

    const rewardEarned =
      singleRewardLineItemsSubtotal * single_rewards_percent +
      doubleRewardLineItemsSubtotal * double_rewards_percent;

    return rewardEarned;
  };

  const rewardsAmount = calculateRewardsAmount();

  return (
    <div className={styles.section}>
      <div>
        <p className={styles.sectionHeading}>PRO Credits</p>

        <div className={styles.premiumImg}>
          <svg width="95" height="114" viewBox="0 0 95 114" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M65.571.084H29.719C13.705.084.722 13.067.722 29.082v28.319l26.434-16.118V29.55a7.556 7.556 0 017.556-7.557H60.58a7.56 7.56 0 017.56 7.559v54.259a7.557 7.557 0 01-7.557 7.558H34.241c-11.04 0-9.133-11.467-5.579-16.369l7.598 3.93c-1.645-9.661 1.026-27.941 4.756-38.656C18.343 55.741.722 68.054.722 88.323c0 12.639 9.51 24.955 26.454 24.955h38.399c16.016 0 28.998-12.983 28.998-28.998V29.086C94.573 13.068 81.589.084 65.571.084"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <div className={styles.premiumText}>
          {yesRebateBalance && (
            <>
              <div>
                Available Omnia PRO Credits: <strong>{toDollars(rebateBalance)}</strong>
              </div>
              {hasGiftCards ? (
                <button
                  className={classNames(styles.buttonAsLink, styles.rebateButton)}
                  onClick={() => {
                    removeProRewards.mutate({ giftCardCodes: [] });
                  }}
                >
                  Remove Omnia PRO Credits
                </button>
              ) : (
                <button
                  className={classNames(styles.buttonAsLink, styles.rebateButton)}
                  onClick={() => {
                    applyProRewards.mutate();
                  }}
                >
                  Apply To This Order
                </button>
              )}
            </>
          )}

          {hasPremiumMembership ? (
            <>
              <div>
                <p className={styles.rewards}>
                  Omnia PRO Credits earned for this order:{' '}
                  <strong>{toDollars(rewardsAmount)}</strong>*
                </p>
                <p>
                  <em>
                    *Orders with a 20% off or greater discounts are not eligible to earn Omnia PRO
                    Credits.
                  </em>
                </p>
              </div>
            </>
          ) : (
            <div className={styles.premiumBlurb}>
              <p>
                {rewardsAmount > 0 && (
                  <>
                    <strong>Earn {toDollars(rewardsAmount)} credit *</strong>
                    <br />
                  </>
                )}
                You could be earning 10% back &amp; getting FREE SHIPPING on every order (no
                minimums) as an Omnia PRO Member.
              </p>
              {/* TODO: maybe we can determine % off by looking at line items or something  */}
              <p>
                <em>
                  * Orders with a 20% off or greater discounts are not eligible to earn Omnia PRO
                  Credits.
                </em>
              </p>
              <div className={styles.premiumActions}>
                <OmniaButton
                  onPress={() => {
                    dispatch(CartActions.CART_CLOSE());
                    dispatch(
                      SignupLoginModalActions.SIGNUP_LOGIN_MODAL_OPEN(
                        PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS
                      )
                    );
                  }}
                  kind="primary"
                  size="md"
                  isDisabled={isPro}
                >
                  Join Omnia PRO
                </OmniaButton>
                <Link
                  to={RoutePaths.PREMIUM_PRO}
                  onClick={() => {
                    dispatch(CartActions.CART_CLOSE());
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
