import { ShopifyCartLine } from '@omniafishing/core';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { usePrevious } from '../../hooks/use_previous';
import { useQueryString } from '../../hooks/use_query_string';
import { isBase64 } from '../../lib/base64';
import { attributionQueryParamToCustomAttributes } from '../../lib/line_item_attribution';
import { GlobalQueryParams } from '../../lib/query_string';
import { ShopifyCartLineAddInput } from '../../lib/shopify';
import { FlashMessageActions } from '../../redux/flash_message';

export const getLineItemsAdded = (
  previousLineItems: ShopifyCartLine[],
  lineItems: ShopifyCartLine[]
) => {
  return lineItems.filter((li) => !previousLineItems.some((prevLi) => prevLi.id === li.id));
};

export const CartProductAdder = (): null => {
  const dispatch = useDispatch();
  const { cartLines, addCartLinesMutation, cartId } = useCart();
  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{
    [GlobalQueryParams.add_to_cart]: string;
    [GlobalQueryParams.attribution]: string[];
  }>();
  let addToCartId = currentQuery.add_to_cart;
  if (isBase64(addToCartId)) {
    addToCartId = atob(addToCartId);
  }
  const lineItemAttribution = attributionQueryParamToCustomAttributes(
    currentQuery.attribution || []
  );

  const prevLineItems = usePrevious<ShopifyCartLine[]>(cartLines);
  const previousLineItems = prevLineItems || []; // prevLineItems can be undefined
  const lineItemsAdded = getLineItemsAdded(previousLineItems, cartLines);
  const flashMessageShown = useRef(false);
  const itemAddedToCart = useRef(false);

  useEffect(() => {
    if (cartId && addToCartId != null && !itemAddedToCart.current) {
      const linesToAdd: ShopifyCartLineAddInput[] = [
        {
          merchandiseId: addToCartId,
          quantity: 1,
          attributes: [...lineItemAttribution],
        },
      ];
      addCartLinesMutation.mutate({
        linesToAdd,
        notification: 'none',
      });
      itemAddedToCart.current = true;
    }
  }, [cartId, addToCartId]);

  useEffect(() => {
    if (addToCartId) {
      const addToCartLineItem = lineItemsAdded.find((li) => li.merchandise.id === addToCartId);
      if (addToCartLineItem && !flashMessageShown.current) {
        flashMessageShown.current = true;

        setTimeout(() => {
          // without this timeout, the flash message will not show, not sure why
          dispatch(
            FlashMessageActions.FLASH_MESSAGE_SET({
              header: 'Added to Cart',
              subheader: `${addToCartLineItem.merchandise.product.title} - ${addToCartLineItem.merchandise.title} has been added to your cart`,
            })
          );
        });
      }
    }
  }, [
    lineItemsAdded
      .map((li) => li.id)
      .sort()
      .join(),
    addToCartId,
  ]);

  return null;
};
