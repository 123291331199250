import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryString } from '../../hooks/use_query_string';
import { GlobalQueryParams } from '../../lib/query_string';
import { fetchCartId, fetchCartPromos, getCartId } from '../../redux/cart';

export const UserCartHandler = (): null => {
  const dispatch = useDispatch();
  const { getCurrentQuery } = useQueryString();

  const currentQuery = getCurrentQuery<{
    [GlobalQueryParams.cart_to_add]: string;
    [GlobalQueryParams.checkout_to_add]: string;
  }>();
  const cartId = useSelector(getCartId);
  // cart_to_add must be base64 encoded because checkouts have a key param baked in like:
  // gid://shopify/Cart/31lk4j2l5j23hqkjnajf3jhkl2j3?key=abc12345
  // which would break the url param
  const mergeCartId = currentQuery.cart_to_add ? atob(currentQuery.cart_to_add) : null;

  useEffect(() => {
    dispatch(
      fetchCartId({
        guest_cart_id: cartId,
        merge_cart_id: mergeCartId,
      })
    );
    dispatch(fetchCartPromos());
  }, []);

  return null;
};
