import {
  EventNotificationFrequencies,
  EventNotificationFrequencyRealTime,
  EventNotificationFrequencyWeekly,
  WaterbodyDetail,
} from '@omniafishing/core';
import { Form, Popover, Radio } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { ReactElement, useRef, useState } from 'react';
import { useUser } from '../../../../hooks/use_user';
import { useUserWaterbodies } from '../../../../hooks/use_user_waterbodies';
import { WebAnalytics } from '../../../../lib/web_analytics';

interface WaterbodyDetailFollowTooltipProps {
  waterbody: WaterbodyDetail;
  children: ReactElement;
  type?: 'follow' | 'update_notifications';
}
export const WaterbodyDetailFollowTooltip = (props: WaterbodyDetailFollowTooltipProps) => {
  const { waterbody, children, type = 'follow' } = props;
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const formRef = useRef<FormInstance>();

  const { favoriteWaterbody, updateWaterbodyEventNotifications } = useUserWaterbodies();

  const formFields = {
    frequency: 'frequency',
  };

  const onRadioSelect = () => {
    const form = formRef.current;
    form.validateFields().then(({ frequency }: { frequency: EventNotificationFrequencies }) => {
      const event_notifications = {
        new_fishing_report: true,
        new_article: true,
        frequency,
      };
      if (type === 'follow') {
        favoriteWaterbody.mutate(
          { event_notifications, url_slug: waterbody.url_slug },
          {
            onSettled: () => setOpen(false),
          }
        );
        WebAnalytics.followUpEnabled(user.email);
      } else {
        updateWaterbodyEventNotifications.mutate(
          {
            event_notifications,
            url_slug: waterbody.url_slug,
          },
          {
            onSettled: () => setOpen(false),
          }
        );
        WebAnalytics.followUpEnabled(user.email);
      }
    });
  };
  return (
    <Popover
      open={open}
      trigger="click"
      placement="bottomLeft"
      overlayClassName="loggedInFollowBtn"
      destroyTooltipOnHide
      onOpenChange={(openStatus) => setOpen(openStatus)}
      content={
        <Form
          ref={formRef}
          initialValues={{
            [formFields.frequency]: null,
          }}
          layout="vertical"
        >
          <Form.Item name={formFields.frequency} label="How often do you want updates?">
            <Radio.Group
              options={[
                { label: 'Realtime', value: EventNotificationFrequencyRealTime },
                { label: 'Weekly', value: EventNotificationFrequencyWeekly },
              ]}
              optionType="button"
              size="large"
              buttonStyle="solid"
              onChange={onRadioSelect}
            />
          </Form.Item>
        </Form>
      }
    >
      {children}
    </Popover>
  );
};
