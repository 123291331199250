import { AmbassadorDetail, AmbassadorMini, EventNotificationFrequencies } from '@omniafishing/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../constants/query_keys';
import { AMBASSADOR_EXCLUDE_ALL, apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { WebAnalytics } from '../lib/web_analytics';
import { hasAccessToken } from '../redux/auth';
import { useFlashMessage } from './use_flash_message';

interface EventNotifications {
  frequency: EventNotificationFrequencies;
  new_fishing_report: boolean;
  new_article: boolean;
}

interface AmbassadorArgs {
  ambassador: AmbassadorMini;
  event_notifications: EventNotifications;
  email?: string;
  onCompletion?: () => void;
  onResponse?: (userProfile: AmbassadorDetail) => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useUserAmbassadors = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const { setFlashMessage } = useFlashMessage();
  const queryClient = useQueryClient();

  const userAmbassadorsFetchQuery = useQuery({
    queryKey: QueryKeys.ME.AMBASSADORS,
    queryFn: async () => {
      const res = await apiV1.userAmbassadorsFetch({
        // hard coded for now but could be moved to params
        exclude: ['brands', 'lists', 'organizations', 'species', 'waterbodies'],
      });
      return res.data.data;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });

  const { data: userAmbassadors = [], isLoading: userAmbassadorsFetchIsLoading } =
    userAmbassadorsFetchQuery;

  const followAmbassador = useMutation({
    mutationFn: async (args: AmbassadorArgs) => {
      const { ambassador, event_notifications, email } = args;
      const { slug } = ambassador;
      if (!isLoggedIn && !email) {
        return Promise.reject('User not logged in or email not provided');
      }
      const res = await apiV1.userProfileFavorite(
        slug,
        event_notifications,
        email,
        AMBASSADOR_EXCLUDE_ALL
      );

      return res.data.data;
    },
    onSuccess: (data, args) => {
      const { ambassador, onResponse, onSuccess } = args;
      WebAnalytics.itemFavorited('ambassador', ambassador.slug);
      setFlashMessage({
        header: 'Success!',
        subheader: `You subscribed to ${ambassador.full_name}'s updates`,
      });
      onSuccess?.();
      onResponse?.(data);

      queryClient.setQueryData(QueryKeys.ME.AMBASSADORS, (oldData: AmbassadorDetail[] = []) => {
        return [...oldData, data];
      });
    },
    onError: (error, args) => {
      args.onError?.();
    },
    onSettled: (data, error, args) => {
      args.onCompletion?.();
    },
    // TODO: consider optimistic updates
    // https://tanstack.com/query/v5/docs/framework/react/guides/optimistic-updates
  });

  const unfollowAmbassador = useMutation({
    mutationFn: async (args: Omit<AmbassadorArgs, 'event_notifications'>) => {
      const { ambassador } = args;
      const { slug } = ambassador;
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.userProfileUnfavorite(slug);

      return res.data.data;
    },
    onSuccess: (data, args) => {
      const { ambassador, onResponse, onSuccess } = args;
      setFlashMessage({
        header: 'Success',
        subheader: `You unfollowed ${ambassador.full_name}`,
      });
      onSuccess?.();
      onResponse?.(data);

      queryClient.setQueryData(QueryKeys.ME.AMBASSADORS, (oldData: AmbassadorDetail[] = []) => {
        return oldData.filter((a) => a.slug !== ambassador.slug);
      });
    },
    onError: (error, args) => {
      args.onError?.();
    },
    onSettled: (data, error, args) => {
      args.onCompletion?.();
    },
    // TODO: consider optimistic updates
    // https://tanstack.com/query/v5/docs/framework/react/guides/optimistic-updates
  });

  return {
    userAmbassadors,
    userAmbassadorsFetchIsLoading,
    followAmbassador,
    unfollowAmbassador,
  };
};
