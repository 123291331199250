import { update } from '@intercom/messenger-js-sdk';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { TrackJS } from 'trackjs';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { hasAccessToken } from '../redux/auth';
import { CookieKeys, getCookieConfig } from '../redux/cookie_storage_middleware';
import { UserActions } from '../redux/user';

export const useUser = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const dispatch = useDispatch();

  const userFetchQuery = useQuery({
    queryKey: QueryKeys.ME.USER,
    queryFn: async () => {
      const res = await apiV1.userFetch();
      const userData = res.data.data;
      TrackJS.configure({
        userId: userData.email,
      });
      Cookies.set(CookieKeys.experimentUuid, userData.experiment_uuid, getCookieConfig());
      update({
        email: userData.email,
        name: userData.full_name,
        user_id: userData.id.toString(),
      });
      window.rudderanalytics.identify(userData.id);
      dispatch(UserActions.USER_ID_SET(userData.id));
      return userData;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });
  const { data: user, isLoading: userFetchLoading } = userFetchQuery;

  const isPremium = !!user?.premium;
  const isPro = !!user?.premium_pro;
  const isAdmin = !!user?.admin;
  const isVerified = !!user?.verified;

  return {
    user,
    userFetchLoading,
    isPremium,
    isPro,
    isAdmin,
    isVerified,
  };
};
