import _ from 'lodash';
import { useEffect } from 'react';
import { useCart } from '../../hooks/use_cart';
import { ShopifyCartLineUpdateInput } from '../../lib/shopify';

export const LineItemCustomAttributeFixer = (): null => {
  const { updateCartLines, cartLines } = useCart();

  useEffect(() => {
    const hasCustomAttributesToFix = cartLines.some((lineItem) =>
      lineItem.attributes.some((customAttribute) => !customAttribute.key.startsWith('_'))
    );

    if (!hasCustomAttributesToFix) {
      return;
    }

    const lineItemsFixed: ShopifyCartLineUpdateInput[] = cartLines.map((lineItem) => {
      const attributes = lineItem.attributes.map((customAttribute) => {
        if (customAttribute.key.startsWith('_')) {
          return {
            key: customAttribute.key,
            value: customAttribute.value,
          };
        }
        return {
          key: `_${customAttribute.key}`,
          value: customAttribute.value,
        };
      });

      return {
        id: lineItem.id,
        quantity: lineItem.quantity,
        attributes,
      };
    });

    if (!_.isEqual(lineItemsFixed, cartLines)) {
      updateCartLines.mutate({
        linesToUpdate: lineItemsFixed,
      });
    }
  }, [cartLines.map((li) => li.id).join(',')]);

  return null;
};
