import { DownOutlined, EnvironmentOutlined, StarFilled } from '@ant-design/icons';
import {
  AmbassadorMini,
  FishingReport,
  Product,
  ProductFamily,
  RecommendedProductFamily,
  Waterbody,
} from '@omniafishing/core';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isNotStarted, LoadingState } from '../../constants/loading_state';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { useUserAmbassadors } from '../../hooks/use_user_ambassadors';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { apiV1 } from '../../lib/api';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { isProductInStock } from '../../lib/products';
import { productFamilyColorOptions, productFamilyPriceRange } from '../../lib/product_families';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { NAME_FALLBACK } from '../../lib/user';
import { ClickKey_ProductCard, WebAnalytics } from '../../lib/web_analytics';
import { LineItemCustomAttribute } from '../../redux/cart';
import { getIpState } from '../../redux/geographic_location';
import {
  ProductFamilyQuickModalActions,
  QuickModalContexts,
} from '../../redux/product_family_quick_modal';
import { getBrands, getStates } from '../../redux/reference_data';
import { ProductDetailPageHashes } from '../../routes/product_detail_page/product_detail_page';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import { FishingReportsStatsPopover } from '../fishing_reports_stats/fishing_reports_stats_popover';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from '../product_card/product_card.less';
import { ProductChip } from '../product_card/product_chip';
import SvgLakes from '../svg/lakes';

// should this be combined with the one in product_card.tsx?
export const LinkToProductDetailPage = (props: {
  children: ReactNode;
  className?: string;
  currentColor?: boolean;
  onClick?: () => void;
  position: number;
  productFamily: ProductFamily;
  url: string;
  list_uuid: string;
}) => {
  const { url, className, productFamily, position, children, onClick, list_uuid } = props;
  return (
    <Link
      to={url}
      className={className}
      onClick={() => {
        WebAnalytics.productClick({
          productOrProductFamily: productFamily,
          show_modal: false,
          position,
          list_uuid,
        });
        onClick?.();
      }}
      style={{ color: 'currentColor' }}
    >
      {children}
    </Link>
  );
};

interface Props {
  className?: string;
  icon?: React.ReactNode;
  lineItemAttribution?: LineItemCustomAttribute[];
  onReportedOnLakeClick?: () => void;
  onProductClick?: () => void;
  position: number;
  quickViewContext?: QuickModalContexts;
  recommendation_id?: string;
  recommendedFamily: RecommendedProductFamily;
  reportedByAmbassador?: AmbassadorMini;
  reportedOnLake?: Waterbody;
  stateBestSeller?: string;
  showStateTotal?: boolean;
  list_uuid: string;
}

export const ProductFamilyCard = (props: Props) => {
  const {
    recommendedFamily,
    className,
    position,
    recommendation_id,
    reportedOnLake,
    onReportedOnLakeClick,
    reportedByAmbassador,
    stateBestSeller,
    icon,
    lineItemAttribution = [],
    quickViewContext = 'shopping',
    onProductClick,
    showStateTotal,
    list_uuid,
  } = props;
  const { product_family, products } = recommendedFamily;
  const { title_short, popularity, created_at, id } = product_family;

  const history = useHistory();
  const dispatch = useDispatch();
  const { isPro } = useUser();
  const ipStateAbbr = useSelector(getIpState);
  const allStates = useSelector(getStates);
  const ipState = allStates.find((s) => s.abbr.toLowerCase() === ipStateAbbr?.toLowerCase());
  const { isMobile } = useResponsive();
  const allBrands = useSelector(getBrands);
  const brand = allBrands.find((b) => b.name === product_family.brand);
  const [selectedImg, setSelectedImg] = useState(product_family.img_url);
  const productUrlSearch = `?${customAttributesToAttributionQueryParams(lineItemAttribution)}`;
  const [pdpUrl, setPdpUrl] = useState(
    OmniaUrls.productFamily(product_family.handle) + productUrlSearch
  );
  const [fishingReports, setFishingReports] = useState<FishingReport[]>([]);
  const [fishingReportLoadingState, setFishingReportsLoadingState] = useState(
    LoadingState.NOT_STARTED
  );
  const colorOptions = productFamilyColorOptions(product_family, products);
  const defaultColorOption = colorOptions.find((o) => o.src === product_family.img_url);
  const firstColorMatchInStockProduct = products.find(
    (p) =>
      p.shopify_options.some(
        (option) =>
          option.name.toLowerCase() === 'color' && option.value === defaultColorOption?.name
      ) && isProductInStock(p)
  );
  const firstInStockProduct = products.find((p) => isProductInStock(p));

  const [selectedProduct, setSelectedProduct] = useState<Product>(
    firstColorMatchInStockProduct || firstInStockProduct || products[0]
  );
  const { userWaterbodies } = useUserWaterbodies();
  const userWaterbodySlugs = userWaterbodies.map((w) => w.url_slug);
  const { userAmbassadors } = useUserAmbassadors();
  const userAmbassadorSlugs = userAmbassadors.map((a) => a.slug);
  const reportedByName = reportedByAmbassador?.full_name || NAME_FALLBACK;

  const stateSum =
    ipStateAbbr != null && showStateTotal
      ? products.reduce((acc, p) => {
          return acc + p.state_fishing_report_counters[ipStateAbbr];
        }, 0)
      : 0;

  const webAnalyticsClick = (click_key: ClickKey_ProductCard) => {
    WebAnalytics.productCardClick({
      card_type: 'ProductFamily',
      product_family_id: id,
      product_sku: null,
      click_key,
    });
  };

  const highestCompareAt = Math.max(
    ...products.map((p) => Number(p.compare_at_price)).filter(Boolean)
  );
  const highestPrice = Math.max(...products.map((p) => Number(p.price)));

  const userIsProAndHasProPrice = isPro && product_family.price_pro != null;
  const priceRange = userIsProAndHasProPrice
    ? toDollars(product_family.price_pro)
    : productFamilyPriceRange(product_family);
  const onSale = products.some((product) => product.on_sale) || userIsProAndHasProPrice;
  const compareAtPriceToDisplay = userIsProAndHasProPrice
    ? Math.max(...(highestCompareAt != null ? [highestCompareAt] : []), highestPrice)
    : highestCompareAt;

  const maxMiniThumbnails = isMobile ? 4 : 6;
  const showMoreColorsCount = colorOptions.length > maxMiniThumbnails;
  const colorsCount = showMoreColorsCount ? maxMiniThumbnails - 1 : maxMiniThumbnails;
  const colorsSlice = colorOptions.slice(0, colorsCount);
  const fishingReportsCount = products.reduce(
    (sum, p) =>
      sum +
      p.zone_1_fishing_reports_count +
      p.zone_2_fishing_reports_count +
      p.zone_3_fishing_reports_count,
    0
  );

  const sharedLinkProps = {
    url: pdpUrl,
    position,
    productFamily: product_family,
    lineItemAttribution,
    list_uuid,
  };

  return (
    <div className={classNames(styles.product, className)} onClick={onProductClick}>
      <LinkToProductDetailPage {...sharedLinkProps} className={styles.imgWrapper}>
        <div
          className={styles.productImg}
          onClick={() => {
            webAnalyticsClick('[product_card].(image)');
          }}
        >
          <img
            src={setShopifyImgWidth(selectedImg, 300)}
            alt={title_short}
            loading="lazy"
            width="250"
            height="250"
          />
        </div>
        {icon && <span className={styles.icon}>{icon}</span>}
        <ProductChip
          stateBestSeller={!!stateBestSeller}
          createdAt={created_at}
          onSale={onSale}
          popularity={popularity}
        />
      </LinkToProductDetailPage>

      {colorsSlice.length > 1 && (
        <div className={styles.miniProductImgs}>
          {colorsSlice.map((color) => (
            <div
              key={color.name + color.src}
              className={classNames(styles.miniProductImg, {
                [styles.miniProductImg__selected]: selectedImg === color.src,
              })}
              onClick={() => {
                setSelectedImg(color.src);
                const firstColorMatchProduct =
                  products.find((p) =>
                    p.shopify_options.some(
                      (option) =>
                        option.name.toLowerCase() === 'color' && option.value === color.name
                    )
                  ) || products[0];
                setSelectedProduct(firstColorMatchProduct);
                setPdpUrl(
                  OmniaUrls.product(product_family.handle, firstColorMatchProduct.shopify_options)
                );
                webAnalyticsClick('[product_card].(variant_img)');
              }}
            >
              <img
                src={setShopifyImgWidth(color.src, 100)}
                alt={color.name}
                title={color.name}
                width="50"
                height="50"
                loading="lazy"
              />
            </div>
          ))}
          {showMoreColorsCount && (
            <LinkToProductDetailPage
              {...sharedLinkProps}
              className={styles.plusMore}
              onClick={() => {
                webAnalyticsClick('[product_card].(variant_plus)');
              }}
            >
              +{colorOptions.length - colorsSlice.length}
            </LinkToProductDetailPage>
          )}
        </div>
      )}
      {brand && (
        <p className={styles.brand}>
          <Link
            to={OmniaUrls.brand(brand)}
            style={{ color: 'currentcolor' }}
            onClick={() => {
              webAnalyticsClick('[product_card].(brand)');
            }}
          >
            {brand.display_name}
          </Link>
        </p>
      )}
      <LinkToProductDetailPage
        {...sharedLinkProps}
        onClick={() => {
          webAnalyticsClick('[product_card].(title)');
        }}
      >
        <p className={styles.title}>{title_short}</p>
      </LinkToProductDetailPage>

      {fishingReportsCount > 0 && (
        <FishingReportsStatsPopover
          fishingReports={fishingReports}
          products={products}
          onViewAllReportsClick={() => {
            history.push({
              pathname: OmniaUrls.productFamily(product_family.handle),
              search: `?${customAttributesToAttributionQueryParams(lineItemAttribution)}`,
              hash: ProductDetailPageHashes.fishing_reports,
            });
          }}
          loadingState={fishingReportLoadingState}
          placement="bottom"
          className={styles.fishingReportsPopoverWrapper}
          hideArrow
        >
          <p
            className={styles.fishingReportsCount}
            onClick={() => {
              webAnalyticsClick('[product_card].(stats_widget)');
              if (isNotStarted(fishingReportLoadingState)) {
                setFishingReportsLoadingState(LoadingState.PENDING);
                apiV1.productFamilyFishingReportsFetch(product_family.handle).then((res) => {
                  setFishingReports(res.data.data);
                  setFishingReportsLoadingState(LoadingState.DONE);
                });
              }
            }}
          >
            <SvgLakes className={styles.inlineIcon} />
            <span>
              {fishingReportsCount} <em>{pluralize('Fishing Reports', fishingReportsCount)}</em>
              <DownOutlined />
            </span>
          </p>
        </FishingReportsStatsPopover>
      )}

      {reportedOnLake && (
        <Link
          to={OmniaUrls.waterbody(reportedOnLake)}
          onClick={() => {
            webAnalyticsClick('[product_card].(reported_on)');
            onReportedOnLakeClick?.();
          }}
          className={styles.reportedOnLake}
        >
          <EnvironmentOutlined className={styles.inlineIcon} />
          <span>
            Reported on{' '}
            <span className={styles.noBreak}>
              <em>{reportedOnLake.primary_name}</em>
              {userWaterbodySlugs.includes(reportedOnLake.url_slug) && (
                <StarFilled className={styles.star} />
              )}
            </span>
          </span>
        </Link>
      )}

      {reportedByAmbassador && (
        <AmbassadorLink
          ambassador={reportedByAmbassador}
          onClick={() => {
            webAnalyticsClick('[product_card].(reported_on)');
            onReportedOnLakeClick?.();
          }}
          className={styles.reportedByAmbassador}
        >
          <EnvironmentOutlined className={styles.inlineIcon} />
          <span>
            Reported by{' '}
            <span className={styles.noBreak}>
              <em>{reportedByName}</em>
              {userAmbassadorSlugs.includes(reportedByAmbassador.slug) && (
                <StarFilled className={styles.star} />
              )}
            </span>
          </span>
        </AmbassadorLink>
      )}

      {!reportedOnLake && !reportedByAmbassador && stateSum > 0 && (
        <Link
          to={OmniaUrls.state(ipState)}
          onClick={() => {
            webAnalyticsClick('[product_card].(reported_on)');
            onReportedOnLakeClick?.();
          }}
          className={styles.reportedInLake}
        >
          <EnvironmentOutlined className={styles.inlineIcon} />
          <span>
            Reported {stateSum} times in <em>{ipStateAbbr}</em>
          </span>
        </Link>
      )}

      <div className={styles.bottom}>
        <p className={styles.price}>
          <LinkToProductDetailPage {...sharedLinkProps}>
            {userIsProAndHasProPrice && (
              <span className={classNames(styles.price__sale, 'text-xs w-full block')}>
                PRO price
              </span>
            )}
            <span
              className={classNames({
                [styles.price__sale]: onSale,
              })}
            >
              {priceRange}
            </span>
            {onSale && <s className={styles.priceCompare}>{toDollars(compareAtPriceToDisplay)}</s>}
          </LinkToProductDetailPage>
        </p>

        <OmniaButton
          kind="secondary"
          size="lg"
          fontSize={15}
          block
          style={{ letterSpacing: '.5px' }}
          className={styles.productButtons}
          onPress={() => {
            dispatch(
              ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_OPEN({
                recommendedFamily,
                context: quickViewContext,
                recommendation_id,
                lineItemAttribution,
                selectedProductSku: selectedProduct?.sku,
                list_uuid,
              })
            );
            webAnalyticsClick('[product_card].(cta)');
            WebAnalytics.productClick({
              productOrProductFamily: product_family,
              show_modal: true,
              position,
              recommendation_id,
              list_uuid,
            });
          }}
        >
          Quick View
        </OmniaButton>
      </div>
    </div>
  );
};
