import { CartFragmentText } from '@omniafishing/core';
import gql from 'graphql-tag';
import { cacheExchange, Client, fetchExchange } from 'urql';

export const shopifyClient = new Client({
  url: 'https://omnia-fishing.myshopify.com/api/2024-10/graphql.json',
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    return {
      headers: {
        'X-Shopify-Storefront-Access-Token':
          process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN || '422b2444dfe54529ddc6697d888017d0',
      },
    };
  },
});

export interface ShopifyCartLineInput {
  attributes?: {
    key: string;
    value: string;
  }[];
  quantity?: number;
  sellingPlanID?: string;
}
export type ShopifyCartLineAddInput = ShopifyCartLineInput & {
  merchandiseId: string;
};
export type ShopifyCartLineUpdateInput = ShopifyCartLineInput & {
  id: string;
  merchandiseId?: string;
};

export const cartFetchQueryGql = gql`
  query CartFetch($cart_id: ID!) {
    cart(id: $cart_id) {
      ...CartFragment
    }
  }
  ${CartFragmentText}
`;

export const addCartLinesMutationGql = gql`
  mutation ($cart_id: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cart_id, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }
  ${CartFragmentText}
`;

export const removeCartLinesMutationGql = gql`
  mutation ($cart_id: ID!, $line_ids: [ID!]!) {
    cartLinesRemove(cartId: $cart_id, lineIds: $line_ids) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }

  ${CartFragmentText}
`;

export const updateCartLinesMutationGql = gql`
  mutation ($cart_id: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cart_id, lines: $lines) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }

  ${CartFragmentText}
`;

export const cartDiscountCodesUpdateMutationGql = gql`
  mutation ($cart_id: ID!, $discount_codes: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cart_id, discountCodes: $discount_codes) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }

  ${CartFragmentText}
`;

export const cartGiftCardCodesUpdateMutationGql = gql`
  mutation ($cart_id: ID!, $gift_card_codes: [String!]!) {
    cartGiftCardCodesUpdate(cartId: $cart_id, giftCardCodes: $gift_card_codes) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }

  ${CartFragmentText}
`;

export const cartAttributesUpdateMutationGql = gql`
  mutation ($cart_id: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cart_id, attributes: $attributes) {
      cart {
        ...CartFragment
      }
      userErrors {
        field
        message
      }
      warnings {
        code
        message
      }
    }
  }

  ${CartFragmentText}
`;
