import { Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDone, isPending } from '../../constants/loading_state';
import { useFlashMessage } from '../../hooks/use_flash_message';
import { usePrevious } from '../../hooks/use_previous';
import { useUser } from '../../hooks/use_user';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { WebAnalytics } from '../../lib/web_analytics';
import {
  getArea,
  getLoadingState,
  getProduct,
  InventoryModalActions,
  isOpen,
  outOfStockSubscribe,
} from '../../redux/inventory_modal';
import { OmniaButton } from '../omnia_button/omnia_button';
import { InventoryForm } from './inventory_form';
import styles from './inventory_modal.less';

const InventoryModal = () => {
  const { user } = useUser();
  const open = useSelector(isOpen);
  const loadingState = useSelector(getLoadingState);
  const product = useSelector(getProduct);
  const area = useSelector(getArea);
  const formRef = useRef<FormInstance>(null);
  const prevLoadingState = usePrevious(loadingState);
  const { setFlashMessage } = useFlashMessage();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(InventoryModalActions.INVENTORY_MODAL_CLOSE());
  };

  useEffect(() => {
    const wasSubmitting = isPending(prevLoadingState);
    const submitSuccess = isDone(loadingState);
    if (wasSubmitting && submitSuccess) {
      setFlashMessage({
        header: "You're on the list",
        subheader: `We will notify you when ${product.title} is back in stock`,
      });
      onCancel();
      WebAnalytics.outOfStockNotified(product, area);
    }
  }, [loadingState, prevLoadingState, product, area, setFlashMessage]);

  const onOk = () => {
    const form = formRef.current;
    interface FormValues {
      email?: string;
    }
    form
      .validateFields()
      .then((values: FormValues) => {
        dispatch(outOfStockSubscribe(product.id, values.email));
      })
      .catch((e) => null);
  };

  const onCancel = () => {
    close();
  };

  const ProductContent = () => {
    if (!product) {
      return null;
    }
    return (
      <div className={styles.product}>
        <div className={styles.img}>
          <img src={setShopifyImgWidth(product.img_url, 300)} alt="" />
        </div>
        <h2 className={styles.h2}>{`${product.title}`}</h2>
      </div>
    );
  };

  return (
    <Modal
      title="Out of Stock"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
      okText="Subscribe"
      footer={null}
    >
      <h1 className={styles.h1}>Receive an email when we restock?</h1>
      <ProductContent />
      <InventoryForm formRef={formRef} user={user} />
      <div className={styles.modalFooter}>
        <OmniaButton
          kind="tertiary"
          size="lg"
          isDisabled={isPending(loadingState)}
          onPress={onCancel}
        >
          Cancel
        </OmniaButton>
        <OmniaButton kind="secondary" size="lg" onPress={onOk} loading={isPending(loadingState)}>
          Subscribe
        </OmniaButton>
      </div>
    </Modal>
  );
};

export default InventoryModal;
