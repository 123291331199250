import { Product } from '@omniafishing/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlashMessage } from '../../hooks/use_flash_message';
import { useUserProducts } from '../../hooks/use_user_products';
import { WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { OmniaButton } from '../omnia_button/omnia_button';
import SignupPopover from '../signup_popover/signup_popover';

interface AddToWishlistProps {
  children?: React.ReactNode;
  className?: string;
  products: Product[];
}

export const AddToWishlist = (props: AddToWishlistProps) => {
  const { products, children, className } = props;
  const isLoggedIn = useSelector(hasAccessToken);
  const [open, setOpen] = useState(false);
  const skus = products.map((p) => p.sku);
  const { userProductCreate } = useUserProducts();
  const { setFlashMessage } = useFlashMessage();

  const onClick = () => {
    if (isLoggedIn) {
      userProductCreate.mutate({ skus, type: 'wishlist' });
      setFlashMessage({
        header: 'Product Added',
        subheader: 'This product was added to your wishlist',
      });
      products.forEach((p) => {
        WebAnalytics.productAddedToWishlist(p);
      });
    } else {
      setOpen(true);
    }
  };

  const content = (
    <OmniaButton size="lg" kind="tertiary" fontSize={14} onPress={onClick} className={className}>
      {children}
    </OmniaButton>
  );

  if (!isLoggedIn) {
    return (
      <SignupPopover actionText="use Wishlist" initialOpen={open}>
        {content}
      </SignupPopover>
    );
  }

  return content;
};
