import { NextFunction } from 'connect';
import { Store } from 'redux';
import { TrackJS } from 'trackjs';
import { ReduxActions } from '../constants/redux_actions';
import { AuthActions } from './auth';
import { UserActions } from './user';

export const INTERNALS = {
  getTrackjs: () => TrackJS,
};

const createTrackjsMiddleware =
  () => (store: Store) => (next: NextFunction) => (action: UserActions | AuthActions) => {
    if (action) {
      switch (action.type) {
        case ReduxActions.AUTH_LOGOUT: {
          INTERNALS.getTrackjs().configure({
            userId: undefined,
          });
          break;
        }

        default:
          break;
      }
    }

    return next(action);
  };

const trackjsMiddleware = createTrackjsMiddleware();

export default trackjsMiddleware;
