import { NextFunction } from 'connect';
import Cookies from 'js-cookie';
import { Store } from 'redux';
import { ReduxActions } from '../constants/redux_actions';
import { EXPERIMENT_UUID, WebAnalytics } from '../lib/web_analytics';
import { initialState as UserState, reducerName as userSlice } from '../redux/user';
import { RoutePaths } from '../routes';
import { AuthActions } from './auth';
import { CookieKeys, getCookieConfig } from './cookie_storage_middleware';
import { RouterActions } from './router';
import { UserActions } from './user';
import { UtmActions } from './utm';

export const INTERNALS = {
  getRudderstackAnalytics: () => {
    if ((window as any).__IS_BOT__) {
      return {
        identify: console.log,
        reset: console.log,
      };
    }
    return window.rudderanalytics;
  },
  getWebAnalytics: () => {
    if ((window as any).__IS_BOT__) {
      return {
        page: console.log,
        getRudderstackAnonymousId: console.log,
      };
    }
    return WebAnalytics;
  },
  getCookieConfig,
};

export enum SegmentActions {
  ACCOUNT_CREATED = 'Account Created',
}

export function pageTrackAllowed(path: string) {
  return !(
    path.match(new RegExp(`^${RoutePaths.WATERS}\/[^/]+$`, 'mi')) ||
    path.match(new RegExp(`${RoutePaths.PRODUCTS}\/.+`, 'mi')) ||
    path.match(new RegExp(`${RoutePaths.ARTICLES}\/.+`, 'mi')) ||
    path.match(new RegExp(`${RoutePaths.OMNIA_VIDEOS}\/.+`, 'mi')) ||
    path.match(new RegExp(`${RoutePaths.FISHING_REPORTS}\/.+`, 'mi'))
  );
}

const createWebAnalyticsMiddleware =
  () =>
  (store: Store) =>
  (next: NextFunction) =>
  (action: RouterActions | UserActions | AuthActions | UtmActions) => {
    if (action) {
      const rudderstackAnalytics =
        typeof window !== 'undefined' && INTERNALS.getRudderstackAnalytics();
      const webAnalytics = typeof window !== 'undefined' && INTERNALS.getWebAnalytics();

      const userId = (store.getState()[userSlice] as typeof UserState).userId || '';

      switch (action.type) {
        case ReduxActions.ROUTER_LOCATION_CHANGE: {
          const { path } = action.payload;
          // slight delay to ensure everything is changed before tracking
          setTimeout(() => {
            if (pageTrackAllowed(path)) {
              webAnalytics.page();
            }
          }, 500);
          break;
        }

        case ReduxActions.USER_EXPERIMENT_IDS_SET: {
          const { uuid } = action.payload;
          rudderstackAnalytics.identify(userId, {
            [EXPERIMENT_UUID]: uuid,
          });
          break;
        }

        case ReduxActions.AFFILIATE_FETCH_SUCCESS: {
          const affiliateSource = action.payload.data.campaign_source;
          rudderstackAnalytics.identify(userId, {
            affiliate: affiliateSource,
          });
          break;
        }

        case ReduxActions.AUTH_LOGOUT:
        case ReduxActions.AUTH_REFRESH_ERROR:
          const cookieExperimentUuid = Cookies.get(CookieKeys.experimentUuid);
          rudderstackAnalytics.identify('', {
            [EXPERIMENT_UUID]: cookieExperimentUuid,
          });

          break;

        default:
          break;
      }
    }

    return next(action);
  };

const webAnalyticsMiddleware = createWebAnalyticsMiddleware();

export default webAnalyticsMiddleware;
