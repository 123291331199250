import { CartPromo, CartUpsellProduct, OmniaResponse } from '@omniafishing/core';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction, StringMap } from './actions_helper';
import { AuthActions } from './auth';
import { RouterActions } from './router';

export const reducerName = 'cart';

export const SHIPPING_FREE_CUTOFF = 50.0;
export const SHIPPING_GROUND_PRICE_CENTS = 500;
export const SHIPPING_GROUND_PRICE_DOLLARS = SHIPPING_GROUND_PRICE_CENTS / 100;
export const SHIPPING_EXPRESS_PRICE_DOLLARS = 6;

export type CartBannerType = 'kit_add' | 'kit_remove';

export interface CartBanner {
  type: CartBannerType;
  message: string | null;
  kitId?: string;
}

export enum StateKeys {
  cartHeaderBanners = 'cartHeaderBanners',
  cartId = 'cartId',
  cartUpsellProducts = 'cartUpsellProducts',
  isOpen = 'isOpen',
  promos = 'promos',
  rebateBalance = 'rebateBalance',
}

export const initialState = {
  [StateKeys.cartHeaderBanners]: [] as CartBanner[],
  [StateKeys.cartId]: null as string,
  [StateKeys.cartUpsellProducts]: [] as CartUpsellProduct[],
  [StateKeys.isOpen]: false,
  [StateKeys.promos]: [] as CartPromo[],
  [StateKeys.rebateBalance]: null as string,
};

export const getCartId = (state: ApplicationState) => state[reducerName][StateKeys.cartId];
export const getIsOpen = (state: ApplicationState) => state[reducerName][StateKeys.isOpen];
export const getCartPromos = (state: ApplicationState) => state[reducerName][StateKeys.promos];
export const getCartUpsellProducts = (state: ApplicationState) =>
  state[reducerName][StateKeys.cartUpsellProducts];
export const getCartBanner = (state: ApplicationState) =>
  state[reducerName][StateKeys.cartHeaderBanners];
export const getRebateBalance = (state: ApplicationState) =>
  state[reducerName][StateKeys.rebateBalance];
export const hasRebateBalance = createSelector([getRebateBalance], (rebateBalance) => {
  return Number(rebateBalance) > 0;
});

export default function cartReducer(
  state = initialState,
  action: CartActions | AuthActions | RouterActions
) {
  switch (action.type) {
    case ReduxActions.CART_ID_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.cartId]: action.payload.data.cart_id,
      };
    }

    case ReduxActions.CART_REBATE_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.rebateBalance]: action.payload.data,
      };
    }

    case ReduxActions.CART_OPEN: {
      return {
        ...state,
        [StateKeys.isOpen]: true,
      };
    }

    case ReduxActions.CART_CLOSE: {
      return {
        ...state,
        [StateKeys.isOpen]: false,
        [StateKeys.cartHeaderBanners]: [],
      };
    }

    case ReduxActions.CART_PROMOS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.promos]: action.payload.data,
      };

    case ReduxActions.CART_UPSELL_PRODUCTS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.cartUpsellProducts]: action.payload.data,
      };
    }

    case ReduxActions.ROUTER_LOCATION_CHANGE: {
      return {
        ...state,
        [StateKeys.isOpen]: false,
      };
    }

    case ReduxActions.AUTH_REFRESH_ERROR:
    case ReduxActions.AUTH_LOGOUT: {
      return {
        ...initialState,
      };
    }

    case ReduxActions.CART_HEADER_BANNER_APPEND: {
      return {
        ...state,
        [StateKeys.cartHeaderBanners]: [...state.cartHeaderBanners, action.payload],
      };
    }

    case ReduxActions.CART_HEADER_BANNER_CLEAR: {
      return {
        ...state,
        [StateKeys.cartHeaderBanners]: [] as CartBanner[],
      };
    }

    default:
      return state;
  }
}

export const errorMessages = {
  DISCOUNT_NOT_FOUND: 'Discount Code not found.',
} as StringMap<string>;

export const getErrorMessage = (code: string) => {
  let errorMessage = 'Unknown error. Please try again.';
  if (errorMessages[code]) {
    errorMessage = errorMessages[code];
  }
  return errorMessage;
};

export const INTERNALS = {
  getErrorMessage,
};

export const CartActions = {
  CART_CLOSE: () => createAction(ReduxActions.CART_CLOSE),
  CART_OPEN: () => createAction(ReduxActions.CART_OPEN),

  CART_ID_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_ID_FETCH_ERROR, err),
  CART_ID_FETCH_PENDING: () => createAction(ReduxActions.CART_ID_FETCH_PENDING),
  CART_ID_FETCH_SUCCESS: (response: OmniaResponse<{ cart_id: string; was_cart_null: boolean }>) =>
    createAction(ReduxActions.CART_ID_FETCH_SUCCESS, response),

  CART_REBATE_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_REBATE_FETCH_ERROR, err),
  CART_REBATE_FETCH_PENDING: () => createAction(ReduxActions.CART_REBATE_FETCH_PENDING),
  CART_REBATE_FETCH_SUCCESS: (response: OmniaResponse<string>) =>
    createAction(ReduxActions.CART_REBATE_FETCH_SUCCESS, response),

  CART_PROMOS_FETCH_ERROR: (err: any) => createAction(ReduxActions.CART_PROMOS_FETCH_ERROR, err),
  CART_PROMOS_FETCH_PENDING: () => createAction(ReduxActions.CART_PROMOS_FETCH_PENDING),
  CART_PROMOS_FETCH_SUCCESS: (response: OmniaResponse<CartPromo[]>) =>
    createAction(ReduxActions.CART_PROMOS_FETCH_SUCCESS, response),

  CART_UPSELL_PRODUCTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.CART_UPSELL_PRODUCTS_FETCH_ERROR, err),
  CART_UPSELL_PRODUCTS_FETCH_PENDING: () =>
    createAction(ReduxActions.CART_UPSELL_PRODUCTS_FETCH_PENDING),
  CART_UPSELL_PRODUCTS_FETCH_SUCCESS: (response: OmniaResponse<CartUpsellProduct[]>) =>
    createAction(ReduxActions.CART_UPSELL_PRODUCTS_FETCH_SUCCESS, response),

  CART_HEADER_BANNER_APPEND: (args: CartBanner) =>
    createAction(ReduxActions.CART_HEADER_BANNER_APPEND, args),
  CART_HEADER_BANNER_CLEAR: () => createAction(ReduxActions.CART_HEADER_BANNER_CLEAR),
};
export type CartActions = ActionsUnion<typeof CartActions>;

export function fetchCartId({
  guest_cart_id,
  merge_cart_id,
}: {
  guest_cart_id?: string;
  merge_cart_id?: string;
}): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_ID_FETCH_PENDING());

    return apiV1
      .cartIdFetch({
        guest_cart_id,
        merge_cart_id,
      })
      .then((response) => {
        return dispatch(CartActions.CART_ID_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`Error: fetchCartId: ${guest_cart_id}, ${merge_cart_id}`, error);
        return dispatch(CartActions.CART_ID_FETCH_ERROR(error));
      });
  };
}

export interface LineItemCustomAttribute {
  key: LINE_ATTRIBUTION;
  value: string;
}

export enum LINE_ATTRIBUTION {
  AFFILIATE = '_affiliate', // used in affiliate video
  AMBASSADOR_CODE = '_ambassador_code', // @deprecated, currently only in lists which are phased out
  FISHING_REPORT_ID = '_fishing_report_id', // for fishing report adds
  OMNIA_VIDEO_ID = '_omnia_video_id', // for video cart adds
  PAGE_TYPE = '_page_type', // used in all cart adds
  URL = '_url', // used in all cart adds
  WATERBODY_ID = '_waterbody_id', // for waterbody page cart adds
  KIT = '_kit', // dynamic kit add to carts
  STATE_ABBR = '_state_abbr', // for state page cart adds
}

export type AddItemNotifications = 'cart' | 'flash' | 'none';

export function fetchUserCartRebate(): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_REBATE_FETCH_PENDING());

    return apiV1
      .userPremiumRewardsFetch()
      .then((response) => {
        return dispatch(CartActions.CART_REBATE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserCartRebate`, error);
        return dispatch(CartActions.CART_REBATE_FETCH_ERROR(error));
      });
  };
}

export function fetchCartPromos(): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_PROMOS_FETCH_PENDING());

    return apiV1
      .cartPromosFetch()
      .then((response) => {
        return dispatch(CartActions.CART_PROMOS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchCartPromos`, error);
        return dispatch(CartActions.CART_PROMOS_FETCH_ERROR(error));
      });
  };
}

export function fetchCartUpsellProducts(skus = [] as string[]): RequestThunk {
  return (dispatch) => {
    dispatch(CartActions.CART_UPSELL_PRODUCTS_FETCH_PENDING());

    return apiV1
      .cartUpsellProductsFetch(skus)
      .then((response) => {
        return dispatch(CartActions.CART_UPSELL_PRODUCTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchCartUpsellProducts`, error);
        return dispatch(CartActions.CART_UPSELL_PRODUCTS_FETCH_ERROR(error));
      });
  };
}
