import { EventNotificationFrequencies, Waterbody } from '@omniafishing/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { WebAnalytics } from '../lib/web_analytics';
import { hasAccessToken } from '../redux/auth';
import { FlashMessageActions } from '../redux/flash_message';
import { useFlashMessage } from './use_flash_message';

interface EventNotifications {
  frequency: EventNotificationFrequencies;
  new_fishing_report: boolean;
  new_article: boolean;
}

interface FavoriteWaterbodyArgs {
  url_slug: string;
  event_notifications: EventNotifications;
  email?: string;
}

export const useUserWaterbodies = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setFlashMessage } = useFlashMessage();

  const userWaterbodiesFetchQuery = useQuery({
    queryKey: QueryKeys.ME.WATERBODIES,
    queryFn: async () => {
      const res = await apiV1.userWaterbodiesFetch();
      return res.data.data;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });

  const {
    data: userWaterbodies = [],
    isFetching: userWaterbodiesFetchIsFetching,
    isLoading: userWaterbodiesFetchIsLoading,
    isError: userWaterbodiesFetchIsError,
    isSuccess: userWaterbodiesFetchIsSuccess,
  } = userWaterbodiesFetchQuery;

  const favoriteWaterbody = useMutation({
    mutationFn: async (args: FavoriteWaterbodyArgs) => {
      const { event_notifications, email, url_slug } = args;
      if (!isLoggedIn && !email) {
        return Promise.reject('User not logged in or email not provided');
      }
      const res = await apiV1.waterbodyFavorite(url_slug, event_notifications, email);
      return res.data.data;
    },
    onSuccess: (data) => {
      WebAnalytics.itemFavorited('waterbody', data.url_slug);

      setFlashMessage({
        header: 'Success!',
        subheader: `You subscribed to ${data.primary_name} fishing report updates`,
      });
      queryClient.setQueryData(QueryKeys.ME.WATERBODIES, (oldData: Waterbody[] = []) => {
        return [...oldData, data];
      });
    },
    // TODO: consider optimistic updates
    // https://tanstack.com/query/v5/docs/framework/react/guides/optimistic-updates
  });

  const unfavoriteWaterbody = useMutation({
    mutationFn: async (url_slug: string) => {
      const res = await apiV1.waterbodyUnfavorite(url_slug);
      return res.data.data;
    },
    onSuccess: (data) => {
      WebAnalytics.itemFavorited('waterbody', data.url_slug);

      setFlashMessage({
        header: 'Complete',
        subheader: `You unsubscribed to ${data.primary_name} fishing report updates`,
        type: 'warning',
      });
      queryClient.setQueryData(QueryKeys.ME.WATERBODIES, (oldData: Waterbody[] = []) => {
        return oldData.filter((w) => w.id !== data.id);
      });
    },
    // TODO: consider optimistic updates
    // https://tanstack.com/query/v5/docs/framework/react/guides/optimistic-updates
  });

  const updateWaterbodyEventNotifications = useMutation({
    mutationFn: async (args: FavoriteWaterbodyArgs) => {
      const { event_notifications, email, url_slug } = args;
      const res = await apiV1.waterbodyUpdateNotifications(url_slug, event_notifications, email);
      return res.data.data;
    },
    onSuccess: (waterbody) => {
      WebAnalytics.itemFavorited('waterbody', waterbody.url_slug);
      dispatch(
        FlashMessageActions.FLASH_MESSAGE_SET({
          header: 'Success!',
          subheader: `You updated your notifications for ${waterbody.primary_name}`,
        })
      );
    },
  });

  const userWaterbodyIds = useMemo(
    () => userWaterbodies.map((waterbody) => waterbody.id),
    [userWaterbodies]
  );

  return {
    favoriteWaterbody,
    isWaterbodyInUserWaterbodies: (waterbody: Waterbody | null) => {
      if (!waterbody) {
        return false;
      }
      return userWaterbodyIds.includes(waterbody.id);
    },
    unfavoriteWaterbody,
    updateWaterbodyEventNotifications,
    userWaterbodies,
    userWaterbodiesFetchIsError,
    userWaterbodiesFetchIsFetching,
    userWaterbodiesFetchIsLoading,
    userWaterbodiesFetchIsSuccess,
    userWaterbodyIds,
  };
};
