import { PlusCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import classNames from 'classnames';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useOnClickOutside } from '../../hooks/use_on_click_outside';
import { useUser } from '../../hooks/use_user';
import { isAmbassador } from '../../lib/user';
import { ClickKey_TopNav, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { CartActions } from '../../redux/cart';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { RoutePaths } from '../../routes';
import '../../styles/flicking.less';
import { AccountWidget } from '../account_widget/account_widget';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { SearchUniversal } from '../search_universal/search_universal';
import SvgHamburgerWithCaret from '../svg/hamburger_with_caret';
import SvgLock from '../svg/lock';
import SvgUser from '../svg/user';
import { AmbassadorBadge } from './ambassador_badge';
import styles from './header_desktop.less';
import logo from './logo.svg';
import { MobileDrawer } from './mobile_drawer';

export const HeaderDesktop = ({
  searchModalVisibleRef,
  measureRefCallback,
}: {
  searchModalVisibleRef: MutableRefObject<boolean>;
  measureRefCallback: (node: HTMLElement | null) => void;
}) => {
  const { user } = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const { cartLines } = useCart();
  const lineItemsCount = cartLines.reduce((acc, line) => acc + line.quantity, 0);
  const isLoggedIn = useSelector(hasAccessToken);
  const [menuOpen, setMenuOpen] = useState(false);

  const onCartClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(CartActions.CART_OPEN());
  };

  const onFishingReportClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    webAnalyticsTopNavHelper('[top_nav].(report)', RoutePaths.DASHBOARD);
    dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN());
  };

  const widgetRef = useRef();
  const [widgetOpen, setWidgetOpen] = useState(false);

  const onWidgetClick = (e: React.MouseEvent) => {
    webAnalyticsTopNavHelper('[top_nav].(dashboard)', RoutePaths.DASHBOARD);
    if (widgetOpen === false) {
      e.stopPropagation();
      e.preventDefault();
      setWidgetOpen(true);
    }
  };

  useOnClickOutside(widgetRef, () => {
    if (widgetOpen) {
      setWidgetOpen(false);
    }
  });

  useEffect(() => {
    setWidgetOpen(false);
  }, [location.pathname]);

  const AccountWidgetCard = <AccountWidget ref={widgetRef} />;

  const webAnalyticsTopNavHelper = (click_key: ClickKey_TopNav, target_url?: string) => {
    WebAnalytics.topNavClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
    });
  };

  const fileFishingReportButton = (
    <li className={classNames(styles.navListItem, styles.navLink)} onClick={onFishingReportClick}>
      <div className={styles.reportButton}>
        <PlusCircleOutlined />
        <span className={styles.navText}>File a Fishing Report</span>
      </div>
    </li>
  );

  const ambassadorBadge = (
    <span className={styles.navBadge}>
      <AmbassadorBadge user={user} />
    </span>
  );

  const pathIsMap = location.pathname.startsWith(RoutePaths.MAP);

  return (
    <>
      <header className={styles.header} ref={measureRefCallback}>
        <ContentWrapper>
          <div className={styles.headerContent}>
            <Link
              to={RoutePaths.HOME}
              className={styles.logoLink}
              onClick={() => webAnalyticsTopNavHelper('[top_nav].(logo)', RoutePaths.HOME)}
            >
              <img src={logo} width={224} height={24} alt="Omnia Fishing" />
            </Link>
            <ul className={styles.nav}>
              <li className={classNames(styles.navListItem, styles.navListItem__search)}>
                <SearchUniversal searchModalVisibleRef={searchModalVisibleRef} />
              </li>
              {pathIsMap && (
                <li className={styles.navListItem__shopTackle} onClick={() => setMenuOpen(true)}>
                  Shop Tackle
                </li>
              )}
              {fileFishingReportButton}
              {isLoggedIn ? (
                <>
                  <Popover
                    overlayClassName={styles.popover}
                    open={widgetOpen}
                    content={AccountWidgetCard}
                    placement="bottomRight"
                    trigger="click"
                  >
                    <li
                      className={classNames(styles.navListItem, styles.navLink)}
                      onClick={onWidgetClick}
                    >
                      <SvgUser className={styles.userIcon} />
                      <div className={styles.accountButton}>
                        <span className={styles.navText}>Your Account</span>
                        {isAmbassador(user) && ambassadorBadge}
                      </div>
                      <SvgHamburgerWithCaret className={styles.navIcon} />
                    </li>
                  </Popover>
                </>
              ) : (
                <>
                  <li className={styles.navListItem}>
                    <NavLink
                      to={{
                        pathname: RoutePaths.SIGNUP,
                        state: {
                          from: location.pathname,
                        },
                      }}
                      className={styles.navLink}
                      activeClassName={styles.navLink__selected}
                      onClick={() =>
                        webAnalyticsTopNavHelper('[top_nav].(signup)', RoutePaths.SIGNUP)
                      }
                    >
                      <SvgUser className={styles.navIcon} />
                      <span className={styles.navText}>Sign Up</span>
                    </NavLink>
                  </li>
                  <li className={styles.navListItem}>
                    <NavLink
                      to={{
                        pathname: RoutePaths.LOGIN,
                        state: {
                          from: location.pathname,
                        },
                      }}
                      className={styles.navLink}
                      activeClassName={styles.navLink__selected}
                      onClick={() =>
                        webAnalyticsTopNavHelper('[top_nav].(login)', RoutePaths.LOGIN)
                      }
                    >
                      <SvgLock className={styles.lockIcon} />
                      <span className={styles.navText}>Log In</span>
                    </NavLink>
                  </li>
                </>
              )}

              <li className={styles.navListItem}>
                <a
                  href="#"
                  className={classNames(styles.navListItem, styles.navLink)}
                  onClick={(e) => {
                    webAnalyticsTopNavHelper('[top_nav].(cart)');
                    onCartClick(e);
                  }}
                >
                  <span className={styles.cartWrapper}>
                    <Badge count={lineItemsCount}>
                      <ShoppingCartOutlined className={styles.navIcon} />
                    </Badge>
                  </span>
                  <span className={styles.navText}>Cart</span>
                </a>
              </li>
            </ul>
          </div>
        </ContentWrapper>
        {pathIsMap && (
          <MobileDrawer menuOpen={menuOpen} closeMenu={() => setMenuOpen(false)} width={375} />
        )}
      </header>
    </>
  );
};
