import { OmniaResponse, Product } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';
import { UserActions } from './user';

export const reducerName = 'wishlist';

export enum StateKeys {
  loadingState = 'loadingState',
  wishlist = 'wishlist',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.wishlist]: [] as Product[],
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getWishlist = (state: ApplicationState) => state[reducerName][StateKeys.wishlist];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function wishlistReducer(
  state = initialState,
  action: WishlistActions | UserActions
) {
  switch (action.type) {
    case ReduxActions.WISHLIST_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.WISHLIST_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };

    case ReduxActions.WISHLIST_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.wishlist]: action.payload.data,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const WishlistActions = {
  WISHLIST_FETCH_PENDING: () => createAction(ReduxActions.WISHLIST_FETCH_PENDING),
  WISHLIST_FETCH_SUCCESS: (response: OmniaResponse<Product[]>) =>
    createAction(ReduxActions.WISHLIST_FETCH_SUCCESS, response),
  WISHLIST_FETCH_ERROR: (err: any) => createAction(ReduxActions.WISHLIST_FETCH_ERROR, err),
};
export type WishlistActions = ActionsUnion<typeof WishlistActions>;

export function fetchWishlist(wishlistHash: string): RequestThunk {
  return (dispatch) => {
    dispatch(WishlistActions.WISHLIST_FETCH_PENDING());

    return apiV1
      .wishlistFetch(wishlistHash)
      .then((response) => {
        return dispatch(WishlistActions.WISHLIST_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWishlist: ${wishlistHash}`, error);
        return dispatch(WishlistActions.WISHLIST_FETCH_ERROR(error));
      });
  };
}
