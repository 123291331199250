import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { WebAnalytics } from '../../lib/web_analytics';
import { isOpen, ProUpsellModalActions } from '../../redux/pro_upsell_modal';
import { SignupLoginModalActions } from '../../redux/signup_login_modal';
import { ProComparisonChart } from '../../routes/pro_page/pro_comparison_chart';
import { PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS } from '../login_signup_modal/signup_login_modal';
import { OmniaButton } from '../omnia_button/omnia_button';
import { ProTrialButton } from '../pro_buttons/pro_trial_button';
import styles from './pro_upsell_modal.less';

export const ProUpsellModal = () => {
  const open = useSelector(isOpen);
  const dispatch = useDispatch();
  const { isPro } = useUser();
  const { isMobile } = useResponsive();

  return (
    <Modal
      open={open}
      onCancel={() => dispatch(ProUpsellModalActions.PRO_UPSELL_MODAL_CLOSE())}
      footer={
        <div className={styles.footer}>
          <div className={styles.footerButtons}>
            <ProTrialButton
              size={isMobile ? 'md' : 'xl'}
              kind="tertiary"
              onClick={() => {
                WebAnalytics.proButtonClicked({
                  location: 'pro_upsell_modal',
                  type: 'trial',
                  style: 'button',
                });
              }}
            />
            <OmniaButton
              onPress={() => {
                dispatch(
                  SignupLoginModalActions.SIGNUP_LOGIN_MODAL_OPEN(
                    PRO_SIGNUP_LOGIN_MODAL_OPEN_PARAMS
                  )
                );
              }}
              kind="primary"
              size={isMobile ? 'md' : 'xl'}
              isDisabled={isPro}
            >
              {isPro ? 'Already PRO' : 'Join Omnia PRO'}
            </OmniaButton>
          </div>
        </div>
      }
      width={'575px'}
      wrapClassName={styles.wrapper}
    >
      <div className="px-4 sm:px-8 pb-6 pt-4">
        <span className="font-sans text-lg text-center font-semibold block">
          Eliminate Dead Water with Omnia Pro{' '}
        </span>
        <span className="text-sm font-sans text-center block mb-6">$59/year</span>
        <ProComparisonChart
          fontSize={isMobile ? 13.5 : 14.5}
          className="bg-[#E9EEEC] py-1.5 px-3 -m-3 rounded-[20px]"
        />
      </div>
    </Modal>
  );
};
