import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';
import { AuthActions } from './auth';

export const reducerName = 'user';

export enum StateKeys {
  userId = 'userId',
  experimentUuid = 'experimentUuid',
  experimentGroupReady = 'experimentGroupReady',
  rudderstackAnonymousId = 'rudderstackAnonymousId',
}

export const initialState = {
  [StateKeys.experimentUuid]: null as string,
  [StateKeys.experimentGroupReady]: false,
  [StateKeys.userId]: null as number,
  [StateKeys.rudderstackAnonymousId]: null as string,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getUserId = (state: ApplicationState) => state[reducerName][StateKeys.userId];
export const getExperimentUuid = (state: ApplicationState) =>
  state[reducerName][StateKeys.experimentUuid];
// this is used to create experiment users on the server
export const getExperimentGroupReady = (state: ApplicationState) =>
  state[reducerName][StateKeys.experimentGroupReady];
export const getRudderstackAnonymousId = (state: ApplicationState) =>
  state[reducerName][StateKeys.rudderstackAnonymousId];

// ========================================================================== //
// Reducers
// ========================================================================== //

export default function userReducer(state = initialState, action: UserActions | AuthActions) {
  switch (action.type) {
    case ReduxActions.AUTH_LOGIN_SUCCESS:
    case ReduxActions.AUTH_SIGNUP_SUCCESS: {
      return {
        ...state,
        [StateKeys.experimentUuid]: action.payload.data.user.experiment_uuid,
        [StateKeys.experimentGroupReady]: true,
      };
    }

    case ReduxActions.AUTH_REFRESH_ERROR:
    case ReduxActions.AUTH_LOGOUT:
      return {
        ...initialState,
        [StateKeys.experimentUuid]: state[StateKeys.experimentUuid],
        [StateKeys.experimentGroupReady]: state[StateKeys.experimentGroupReady],
      };

    case ReduxActions.USER_ID_SET: {
      const { id } = action.payload;
      return {
        ...state,
        [StateKeys.userId]: id,
      };
    }

    case ReduxActions.USER_EXPERIMENT_IDS_SET: {
      const { uuid } = action.payload;
      return {
        ...state,
        [StateKeys.experimentUuid]: uuid,
        [StateKeys.experimentGroupReady]: true,
      };
    }

    case ReduxActions.USER_EXPERIMENT_GROUP_ID_READY: {
      return {
        ...state,
        [StateKeys.experimentGroupReady]: true,
      };
    }

    case ReduxActions.RUDDERSTACK_ANONYMOUS_ID_SET: {
      return {
        ...state,
        [StateKeys.rudderstackAnonymousId]: action.payload,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const UserActions = {
  USER_ID_SET: (id: number) => createAction(ReduxActions.USER_ID_SET, { id }),
  USER_EXPERIMENT_IDS_SET: ({ uuid }: { uuid: string }) =>
    createAction(ReduxActions.USER_EXPERIMENT_IDS_SET, { uuid }),
  USER_EXPERIMENT_GROUP_ID_READY: () => createAction(ReduxActions.USER_EXPERIMENT_GROUP_ID_READY),

  // analytics
  RUDDERSTACK_ANONYMOUS_ID_SET: (id: string) =>
    createAction(ReduxActions.RUDDERSTACK_ANONYMOUS_ID_SET, id),
};
export type UserActions = ActionsUnion<typeof UserActions>;
