import { DownOutlined, WarningOutlined } from '@ant-design/icons';
import {
  CategoryNames,
  FishingReport,
  Media,
  Product,
  ProductFamily,
  ProductFamilySummary,
} from '@omniafishing/core';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useExperiment } from 'statsig-react';
import {
  getCategoryByProductType,
  getSubcategoryByProductType,
  subcategoriesExcludePickup,
} from '../../categories_subcategories';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import ErrorPage from '../../components/error_page/error_page';
import { LoadingCardCarousel } from '../../components/home/loading_card_carousel';
import { MediaFishingReportsList } from '../../components/media_fishing_reports/media_fishing_reports_list';
import {
  OmniaBreadcrumb,
  OmniaBreadcrumbItem,
} from '../../components/omnia_breadcrumb/omnia_breadcrumb';
import { OmniaLinkButton } from '../../components/omnia_button/omnia_link_button';
import { ProductDetailFishingReports } from '../../components/product_detail_fishing_reports/product_detail_fishing_reports';
import { ProductDetailGrid } from '../../components/product_detail_grid/product_detail_grid';
import { ScrollToTop } from '../../components/scroll_to_top/scroll_to_top';
import { SEO } from '../../components/seo/seo';
import { baitCategoryNames } from '../../components/waterbody_detail/waterbody_detail_lib/get_top_products';
import { isDone, isError, isPending, LoadingState } from '../../constants/loading_state';
import { usePrevious } from '../../hooks/use_previous';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { AlgoliaEvents } from '../../lib/algolia_events';
import { apiV1 } from '../../lib/api';
import { FacebookEvents } from '../../lib/facebook_events';
import { GoogleEvents } from '../../lib/google_events';
import { stripHtml } from '../../lib/html_strip';
import { isProductInStock } from '../../lib/products';
import { scrollToElementTop } from '../../lib/scroll';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { getIpLatitude, getIpLongitude } from '../../redux/geographic_location';
import {
  fetchProductFamilyFishingReports,
  getFishingReports,
  getProductFamily,
  getProductFamilyLoadingState,
  getSelectedVariant,
  getShopifyProduct,
  getShopifyProductLoadingState,
  ProductDetailActions,
} from '../../redux/product_detail';
import { getCategories } from '../../redux/reference_data';
import { getFullUrl } from '../../redux/router';
import { getIsWebview } from '../../redux/window';
import { RoutePaths } from '../../routes';
import { ComplementaryProducts } from './complementary_products';
import { ProductDescription } from './product_description';
import styles from './product_detail_page.less';
import { ProductDetailUpperSection } from './product_detail_upper_section';
import { ProductVideosAndArticles } from './product_videos_articles';
import { RelatedProducts } from './related_products';

dayjs.extend(isBetween);

export const SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX = 'PRO_PRICE:';

enum PanelKeys {
  productDescription = 'productDescription',
  productGrid = 'productGrid',
  productFishingReports = 'productFishingReports',
}

export enum ProductDetailPageHashes {
  fishing_reports = 'fishing_reports',
}

interface FishingReportWithDistanceUnifiedReportDate extends FishingReport {
  distance: number;
  reportDate: string;
}

export interface PDPMediaFishingReport extends FishingReportWithDistanceUnifiedReportDate {
  alternateProductToDisplay: Product;
}

export const productFamilyIsBait = (productFamily: ProductFamily) => {
  if (!productFamily) {
    return false;
  }
  return baitCategoryNames.includes(productFamily.category);
};

const rodReelAndLineCategoryNames: CategoryNames[] = ['rods', 'reels', 'lines'];

const productFamilyIsRodReelOrLine = (productFamily: ProductFamily) => {
  if (!productFamily) {
    return false;
  }
  return rodReelAndLineCategoryNames.includes(productFamily.category);
};

export interface ProductDetailPageProps {
  media: Media[];
  productHandle: string;
  variant_options: string;
  productFamilySummary: ProductFamilySummary | null;
}

export const ProductDetailPage = (props: ProductDetailPageProps) => {
  const { variant_options, productHandle, media, productFamilySummary } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const historyWentBack = useRef(false);
  const isWebView = useSelector(getIsWebview);
  const selectedVariant = useSelector(getSelectedVariant);
  const productFamily = useSelector(getProductFamily);
  const selectedProduct = useMemo(() => {
    if (selectedVariant && productFamily) {
      return productFamily.products.find(
        (familyProduct) => familyProduct.sku === selectedVariant.sku
      );
    }
    return null;
  }, [selectedVariant, productFamily]);

  const categories = useSelector(getCategories);
  const fishingReports = useSelector(getFishingReports);
  const prevFishingReports = usePrevious(fishingReports);
  const ipLatitude = useSelector(getIpLatitude);
  const ipLongitude = useSelector(getIpLongitude);
  const productFamilyLoadingState = useSelector(getProductFamilyLoadingState);
  const shopifyProduct = useSelector(getShopifyProduct);
  const shopifyProductLoadingState = useSelector(getShopifyProductLoadingState);
  const { user } = useUser();
  const fullUrl = useSelector(getFullUrl);

  const { setUserPreferencesBrand } = useUserPreferences();
  const { isMobile, isDesktop } = useResponsive();
  const fishingReportsHeaderRef = useRef<HTMLDivElement>(null);
  const productGridRef = useRef<HTMLDivElement>(null);

  const [reportsWidgetProductSku, setReportsWidgetProductSku] = useState<string>(null);
  const [reportsWidgetSpeciesName, setReportsWidgetSpeciesName] = useState<string>();
  const [reportsWidgetTechniques, setReportsWidgetTechniques] = useState<string[]>();
  const [totalFilteredFishingReports, setTotalFilteredFishingReports] = useState<number>();
  const initialActivePanels = [
    PanelKeys.productDescription,
    PanelKeys.productGrid,
    PanelKeys.productFishingReports,
  ];
  const [activePanels, setActivePanels] = useState(initialActivePanels);

  const showFishingReportsSection = location.hash === '#' + ProductDetailPageHashes.fishing_reports;

  // this is the A:A test that does nothing
  useExperiment('pdp_baseline').config.get<boolean>('not_used', false);

  // reset page data on unmount
  useEffect(() => {
    return () => {
      dispatch(ProductDetailActions.PRODUCT_DETAIL_CLEAR());
    };
  }, []);

  useEffect(() => {
    if (productFamily?.handle) {
      AlgoliaEvents.ViewItem(productFamily, user?.id?.toString());
      dispatch(fetchProductFamilyFishingReports(productHandle));
      setUserPreferencesBrand(productFamily.brand);
    }
  }, [productFamily?.handle]);

  useEffect(() => {
    // fishing reports loaded
    if (
      prevFishingReports?.length === 0 &&
      fishingReports.length > 0 &&
      showFishingReportsSection
    ) {
      openAndScrollToFishingReportsPanel();
    }
  }, [prevFishingReports, fishingReports, showFishingReportsSection]);

  useEffect(() => {
    if (selectedProduct) {
      FacebookEvents.ViewContentProduct(selectedProduct.shopify_variant_id, selectedProduct.title);
      GoogleEvents.ViewItem(selectedProduct);
      WebAnalytics.productDetailPageView({
        product_brand: selectedProduct.brand,
        product_category: selectedProduct.subcategory_name,
        product_sku: selectedProduct.sku,
        product_slug: selectedProduct.shopify_product_handle,
        product_title: selectedProduct.title_short,
        variant_out_of_stock: !isProductInStock(selectedProduct),
      });
    }
  }, [selectedProduct?.sku]);

  useEffect(() => {
    if (isWebView && !historyWentBack.current) {
      history.goBack();
      historyWentBack.current = true;
    }
  }, []);

  const openAndScrollToFishingReportsPanel = useCallback(() => {
    setActivePanels((prev) =>
      prev.includes(PanelKeys.productFishingReports)
        ? prev
        : [...prev, PanelKeys.productFishingReports]
    );
    setTimeout(() => {
      scrollToElementTop(fishingReportsHeaderRef.current, isMobile ? 90 : 40);
    }, 0);
  }, [fishingReportsHeaderRef, isMobile]);

  const openAndScrollToProductGridPanel = useCallback(() => {
    setActivePanels((prev) =>
      prev.includes(PanelKeys.productGrid) ? prev : [...prev, PanelKeys.productGrid]
    );
    setTimeout(() => {
      scrollToElementTop(productGridRef.current, isMobile ? 120 : 70);
    }, 0);
  }, [productGridRef.current, isMobile]);

  const isProductFamilyBait = productFamilyIsBait(productFamily);
  const isProductFamilyRodReelOrLine = productFamilyIsRodReelOrLine(productFamily);

  const [nearbyMediaFishingReportsLoadingState, setNearbyMediaFishingReportsLoadingState] =
    useState(LoadingState.NOT_STARTED);
  const [nearbyMediaFishingReports, setNearbyMediaFishingReports] = useState<
    PDPMediaFishingReport[]
  >([]);

  useEffect(() => {
    if (productFamily?.handle) {
      setNearbyMediaFishingReportsLoadingState(LoadingState.PENDING);
      apiV1
        .productFamilyMediaFishingReportsNearbyFetch(productFamily.handle, {
          lat: ipLatitude,
          lng: ipLongitude,
          miles: 100,
          per_page: 6,
        })
        .then((response) => {
          setNearbyMediaFishingReports(
            response.data.data.map((r) => {
              const reportProducts = [r.featured_product, ...r.products];
              const reportProductProductFamilyMatch = reportProducts.find((p) => {
                return p?.shopify_product_handle === productFamily.handle;
              });

              return {
                ...r,
                distance: null,
                reportDate: dayjs(r.outing_date || r.accepted_at).format('MM/DD/YYYY'),
                alternateProductToDisplay: reportProductProductFamilyMatch,
              };
            })
          );
          setNearbyMediaFishingReportsLoadingState(LoadingState.DONE);
        })
        .catch(() => {
          setNearbyMediaFishingReportsLoadingState(LoadingState.ERROR);
        });
    }
  }, [productFamily?.handle]);

  const showRecentMediaCarousel =
    (isDesktop && nearbyMediaFishingReports.length >= 3) ||
    (isMobile && nearbyMediaFishingReports.length >= 1);

  const onProductDescriptionTechniqueClick = useCallback(
    (technique: string) => {
      setReportsWidgetTechniques([technique]);
      openAndScrollToFishingReportsPanel();
    },
    [openAndScrollToFishingReportsPanel]
  );

  const onViewFishingReport = useCallback(
    (productSku: string) => {
      setReportsWidgetProductSku(productSku);
      openAndScrollToFishingReportsPanel();
    },
    [openAndScrollToFishingReportsPanel]
  );

  if (isError(shopifyProductLoadingState) || isError(productFamilyLoadingState)) {
    return <ErrorPage context="error" />;
  }
  if (!isDone(shopifyProductLoadingState) || !isDone(productFamilyLoadingState)) {
    return null;
  }

  const getCanonicalUrl = () => {
    const productHandleIndex = fullUrl.indexOf(productHandle);
    return fullUrl.slice(0, productHandleIndex + productHandle.length);
  };

  const getSchema = () => {
    if (productFamily == null) {
      return null;
    }

    const category = getCategoryByProductType(categories, shopifyProduct.productType);
    const subcategory = getSubcategoryByProductType(categories, shopifyProduct.productType);
    const schemaCategory =
      category && subcategory ? `${category.display_name} / ${subcategory.display_name}` : '';

    const gtin = selectedProduct != null ? selectedProduct?.mfr_item_number : null;

    const now = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(now.getFullYear() + 1);

    const productOffer = {
      '@type': 'Offer',
      url: fullUrl,
      priceCurrency: 'USD',
      price: selectedVariant.price.amount,
      priceValidUntil: nextYear.toISOString(),
      itemCondition: 'https://schema.org/NewCondition',
      availability: selectedVariant.availableForSale
        ? 'http://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
      seller: {
        '@type': 'Organization',
        name: 'Omnia Fishing',
      },
    };

    const showLocalShopping =
      queryString.parse(location.search)?.store === 'OMNIAFLAGSHIP' &&
      subcategoriesExcludePickup.indexOf(subcategory.name) === -1;

    const offers = showLocalShopping
      ? [
          productOffer,
          {
            ...productOffer,
            availableAtOrFrom: {
              '@type': 'Place',
              name: 'Omnia Fishing',
            },
            availableDeliveryMethod: 'http://schema.org/OnSitePickup',
            potentialAction: {
              '@type': 'http://schema.org/BuyAction',
            },
            deliveryLeadTime: {
              '@type': 'http://schema.org/QuantitativeValue',
              value: 0,
            },
          },
        ]
      : [productOffer];

    return {
      '@context': 'http://www.schema.org',
      '@type': 'Product',
      logo: 'https://www.omniafishing.com/logo.svg',
      name: shopifyProduct.title,
      brand: { '@type': 'Brand', name: shopifyProduct.vendor },
      sku: selectedVariant.sku,
      category: schemaCategory,
      image: setShopifyImgWidth(selectedVariant.image.url, 1000),
      description: shopifyProduct.description,
      productId: gtin,
      gtin12: gtin,
      offers,
    };
  };

  const getBreadCrumbData = (): OmniaBreadcrumbItem[] => {
    const type = shopifyProduct.productType;
    const category = getCategoryByProductType(categories, type);
    const subcategory = getSubcategoryByProductType(categories, type);

    const listItems = [
      {
        url: `${RoutePaths.SHOP}`,
        name: 'Products',
      },
    ];

    if (category) {
      listItems.push({
        name: category.display_name,
        url: OmniaUrls.category(category),
      });
    }
    if (subcategory) {
      listItems.push({
        name: subcategory.display_name,
        url: OmniaUrls.subcategory(category, subcategory),
      });
    }

    return listItems;
  };

  const isCanonical = variant_options == null;

  const title = isCanonical
    ? shopifyProduct.title
    : `${shopifyProduct.title}—${selectedVariant.title}`;

  const { Panel } = Collapse;
  const hasMedia = media.length!!;
  const isGridPanelOpen = activePanels.includes(PanelKeys.productGrid);
  const discontinued = productFamily?.eol || false;
  const initialFishingReportsCount = fishingReports?.length;

  return (
    <ScrollToTop>
      <SEO
        title={title}
        description={stripHtml(shopifyProduct.description)}
        canonicalUrl={getCanonicalUrl()}
      />
      <section className={styles.page}>
        <section>
          <ContentWrapper>
            <div>
              <OmniaBreadcrumb
                items={getBreadCrumbData()}
                className={styles.breadcrumb}
                onLinkClick={() => {
                  WebAnalytics.productDetailPageClick('[pg_navigation].(breadcrumbs)');
                }}
              />
            </div>
            {discontinued && (
              <div className={styles.discontinued}>
                <p>
                  <WarningOutlined className={styles.discontinuedIcon} />
                  This product has been discontinued by the manufacturer or is no longer carried
                  in-stock by Omnia Fishing.
                </p>
                {productFamily?.eol_replacement && (
                  <>
                    <p>
                      However, we have some good news! The manufacturer has released a new and
                      improved product that we think you'll love even more
                    </p>
                    <OmniaLinkButton
                      size="md"
                      kind="secondary"
                      fontSize={14}
                      to={OmniaUrls.productFamily(productFamily.eol_replacement.handle)}
                      className={styles.eolReplacementButton}
                    >
                      {productFamily.eol_replacement.title}
                    </OmniaLinkButton>
                  </>
                )}
                <p>
                  The fishing reports that mention the discontinued product are still available
                  below.
                </p>
              </div>
            )}
          </ContentWrapper>
        </section>

        <ContentWrapper>
          <section className={styles.contentWrapper}>
            <ProductDetailUpperSection
              fishingReports={fishingReports}
              media={media}
              openAndScrollToProductGridPanel={openAndScrollToProductGridPanel}
              productFamily={productFamily}
              selectedVariant={selectedVariant}
              shopifyProduct={shopifyProduct}
              productFamilySummary={productFamilySummary}
            />

            {isPending(nearbyMediaFishingReportsLoadingState) ? (
              <LoadingCardCarousel
                blockButtons={false}
                loadingText="Finding Fishing Reports"
                numberOfCards={4}
                placeholderHeight={508}
                placeholderWidth={323}
                sectionTitle={'Recent Fishing Reports Near You'}
                showLines={false}
                wrapperClassName={styles.sectionHeading__recentMedia}
              />
            ) : (
              showRecentMediaCarousel && (
                <div className={styles.sectionHeading__recentMedia}>
                  <h3 className={styles.h3}>
                    Nearby Fishing Reports For {productFamily.title_short}
                  </h3>
                  <MediaFishingReportsList
                    fishingReports={nearbyMediaFishingReports}
                    onSeeMoreClick={() => openAndScrollToFishingReportsPanel()}
                    allFishingReportsCount={initialFishingReportsCount}
                    showTechnique={isProductFamilyRodReelOrLine}
                    showSeason={isProductFamilyBait}
                  />
                </div>
              )
            )}

            <section className={styles.lowerContainer}>
              <Collapse
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                ghost
                expandIconPosition="end"
                onChange={(panels) => {
                  setActivePanels(panels as PanelKeys[]);
                }}
                activeKey={activePanels}
                destroyInactivePanel
              >
                {!discontinued && (
                  <Panel
                    header={`All Variations (${productFamily.products?.length})`}
                    key={PanelKeys.productGrid}
                    className={styles.productGridContainer}
                  >
                    <div ref={productGridRef}>
                      <ProductDetailGrid
                        fishingReports={fishingReports}
                        isPanelOpen={isGridPanelOpen}
                        productFamily={productFamily}
                        openAndScrollToFishingReportsPanel={openAndScrollToFishingReportsPanel}
                        onViewFishingReport={onViewFishingReport}
                        shopifyProduct={shopifyProduct}
                      />
                    </div>
                  </Panel>
                )}
                {!discontinued && (
                  <>
                    <ComplementaryProducts productFamily={productFamily} />
                    <Panel header="Product Description" key={PanelKeys.productDescription}>
                      <ProductDescription
                        selectedProduct={selectedProduct}
                        shopifyProduct={shopifyProduct}
                        productFamily={productFamily}
                        fishingReports={fishingReports}
                        onTechniqueClick={onProductDescriptionTechniqueClick}
                      />
                    </Panel>
                    <RelatedProducts productFamily={productFamily} />
                  </>
                )}
                {initialFishingReportsCount > 0 && (
                  <Panel
                    header={`Fishing Reports (${
                      totalFilteredFishingReports ?? initialFishingReportsCount
                    })`}
                    key={PanelKeys.productFishingReports}
                    className={styles.fishingReports}
                  >
                    <ProductDetailFishingReports
                      reportsWidgetProductSku={reportsWidgetProductSku}
                      reportsWidgetSpeciesName={reportsWidgetSpeciesName}
                      reportsWidgetTechniques={reportsWidgetTechniques}
                      onWidgetSpeciesNameChange={(speciesName: string) =>
                        setReportsWidgetSpeciesName(speciesName)
                      }
                      onWidgetProductSkuChange={(productSku: string) =>
                        setReportsWidgetProductSku(productSku)
                      }
                      onWidgetTechniqueNameChange={(technique: string[]) =>
                        setReportsWidgetTechniques(technique)
                      }
                      onTotalFishingReportsChange={(total: number) => {
                        setTotalFilteredFishingReports(total);
                      }}
                      productFamily={productFamily}
                      fishingReports={fishingReports}
                      fishingReportsHeaderRef={fishingReportsHeaderRef}
                      totalFilteredFishingReports={totalFilteredFishingReports}
                    />
                  </Panel>
                )}
                {hasMedia && !discontinued && <ProductVideosAndArticles media={media} />}
              </Collapse>
            </section>
          </section>
        </ContentWrapper>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(getSchema()) }}
        />
      </section>
    </ScrollToTop>
  );
};
