import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthActions,
  getIsAuthExpired,
  getRefreshToken,
  hasAccessToken,
  refreshAuth,
} from '../../redux/auth';

const MINUTE = 1000 * 60;
export const CHECK_AUTH_INTERVAL = 9 * MINUTE;

const UserAuthHandler = (): null => {
  const isLoggedIn = useSelector(hasAccessToken);
  const isAuthExpired = useSelector(getIsAuthExpired);
  const refreshToken = useSelector(getRefreshToken);
  const dispatch = useDispatch();

  const checkAuthExpired = useCallback(() => {
    dispatch(AuthActions.AUTH_EXPIRED_CHECK());
  }, [dispatch]);

  useEffect(() => {
    checkAuthExpired();

    const interval = window.setInterval(checkAuthExpired, CHECK_AUTH_INTERVAL);

    return () => {
      window.clearInterval(interval);
    };
  }, [checkAuthExpired]);

  useEffect(() => {
    if (isLoggedIn && isAuthExpired) {
      dispatch(refreshAuth(refreshToken));
    }
  }, [isLoggedIn, isAuthExpired, refreshToken]);

  return null;
};

export default UserAuthHandler;
