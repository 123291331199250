import { CheckCircleFilled } from '@ant-design/icons';
import { isCartPromoCartPrice, ShopifyCartLine } from '@omniafishing/core';
import { Divider, Drawer, Space } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { apiV1 } from '../../lib/api';
import { isCartUpsellProductActive } from '../../lib/cart_upsell_product';
import { toDollars } from '../../lib/to_dollars';
import {
  CartActions,
  getCartBanner,
  getCartPromos,
  getCartUpsellProducts,
  getIsOpen,
  SHIPPING_FREE_CUTOFF,
  SHIPPING_GROUND_PRICE_CENTS,
} from '../../redux/cart';
import { getAffiliateCampaign, getAffiliateSource } from '../../redux/utm';
import { getIsWebview } from '../../redux/window';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgShipped from '../svg/shipped';
import styles from './cart.less';
import { CartContainsPromo, cartQualifiesForPromo } from './cart_contains_promo';
import { CartItem, lineItemPricing } from './cart_item';
import { CartPricePromo } from './cart_price_promo';
import { CartDiscountCode } from './cart_promo_code';
import { CartProUpsell } from './cart_pro_upsell';
import { useUpsellProductsClosedSkus } from './use_upsell_products_closed_skus';

export const Cart = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsOpen);
  const isWebview = useSelector(getIsWebview);
  const cartHeaderBanners = useSelector(getCartBanner);
  const cartUpsellProducts = useSelector(getCartUpsellProducts);
  const { cartUpsellProductsClosedSkus } = useUpsellProductsClosedSkus();
  const { isMobile } = useResponsive();
  const { cart, removeCartLines, updateCartLines } = useCart();
  const { isPro, isPremium } = useUser();
  const promos = useSelector(getCartPromos);
  const affiliateCampaign = useSelector(getAffiliateCampaign);
  const affiliateSource = useSelector(getAffiliateSource);

  const parentRef = useRef<HTMLDivElement>(null);

  const onClose = () => {
    dispatch(CartActions.CART_CLOSE());
  };

  const onQuantityChange = (line: ShopifyCartLine) => (quantity: number) => {
    if (quantity === 0) {
      removeLineItem(line)();
    } else {
      updateCartLines.mutate({
        linesToUpdate: [
          {
            id: line.id,
            quantity,
          },
        ],
      });
    }
  };

  const removeLineItem = (line_item: ShopifyCartLine) => () => {
    removeCartLines.mutate({ lineIds: [line_item.id] });
  };

  if (!cart) {
    return null;
  }

  const {
    lines: { nodes: lineItems },
    checkoutUrl,
    cost: { subtotalAmount },
  } = cart;

  const activeCartUpsellProducts = cartUpsellProducts.filter((cartUpsellProduct) =>
    isCartUpsellProductActive(cartUpsellProduct, new Date())
  );

  const lineItemHandles = lineItems.map((li) => li.merchandise.product.handle);
  const lineItemsWithUnaddedUnclosedCartUpsells = lineItems.filter((li) =>
    activeCartUpsellProducts.some(
      (cup) =>
        li.merchandise.product.handle === cup.item_in_cart.handle &&
        !lineItemHandles.includes(cup.item_to_recommend.shopify_product_handle) &&
        !cartUpsellProductsClosedSkus.includes(cup.item_to_recommend.sku)
    )
  );

  const hasPremiumMembership = isPro || isPremium;
  const toFreeShipping = Number(
    (SHIPPING_FREE_CUTOFF - parseFloat(subtotalAmount.amount)).toFixed(2)
  );
  const hasGiftCards = cart.appliedGiftCards.length > 0;
  const shippingAmountCents = hasPremiumMembership
    ? 0
    : parseFloat(subtotalAmount.amount) >= SHIPPING_FREE_CUTOFF
    ? 0
    : SHIPPING_GROUND_PRICE_CENTS;
  const shippingCost = shippingAmountCents === 0 ? 'FREE' : toDollars(shippingAmountCents / 100);

  const DiscountCodeSection = <CartDiscountCode key={`${isOpen}`} cart={cart} />;
  const ShippingSection = (
    <div className={styles.section}>
      <div className={styles.shipWrapper}>
        <SvgShipped height="2.2em" width="2.2em" className={styles.shipIcon} />
        <div>
          <p className={styles.sectionHeading}>Shipping</p>
          <p className={styles.shipFast}>
            <strong>We ship fast!</strong>
            <br />
            Orders placed before 1pm Central ship the same&nbsp;day.
          </p>
          <p>
            $5 Ground, FREE for orders over $50
            <br />
            $6 2-day Shipping (excludes rods &amp; tackle boxes)
          </p>
          {!hasPremiumMembership && (
            <div>
              {toFreeShipping > 0 ? (
                <p>
                  Spend <strong>${toFreeShipping}</strong> more and get{' '}
                  <strong>FREE SHIPPING</strong>
                </p>
              ) : (
                <p>
                  This order qualifies for <strong>FREE SHIPPING</strong>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const lineItemSkus = lineItems.map((lineItem) => lineItem.merchandise.sku);
  const promosToShow = promos
    .filter((promo) => (promo.premium_only && hasPremiumMembership) || !promo.premium_only)
    .filter((promo) => {
      const productFamilySkus = promo.product_family.products.map((p) => p.sku);
      const cartDoesNotContainPromoProduct =
        _.intersection(lineItemSkus, productFamilySkus).length === 0;
      if (isCartPromoCartPrice(promo)) {
        return cartDoesNotContainPromoProduct;
      } else {
        if (promo.hide_from_cart) {
          return cartQualifiesForPromo(promo, lineItems) && cartDoesNotContainPromoProduct;
        } else {
          return !cartQualifiesForPromo(promo, lineItems) || cartDoesNotContainPromoProduct;
        }
      }
    });

  const PromosSection =
    promosToShow.length > 0 ? (
      <div className={styles.section}>
        <p className={styles.sectionHeading}>Available Deals</p>
        <div className={styles.sectionBreakout}>
          <Space direction="vertical" split={<Divider />} className={styles.promosSpace}>
            {promosToShow.map((promo) => {
              if (isCartPromoCartPrice(promo)) {
                return <CartPricePromo promo={promo} key={promo.product_family.handle} />;
              } else {
                return <CartContainsPromo promo={promo} key={promo.product_family.handle} />;
              }
            })}
          </Space>
        </div>
      </div>
    ) : null;

  const discountTotal = lineItems.reduce((acc, lineItem) => {
    const { discountAmount } = lineItemPricing(lineItem);
    return acc + discountAmount;
  }, 0);
  const hasDiscounts = discountTotal > 0;

  const giftCardsAppliedInCents = cart.appliedGiftCards.reduce(
    (acc, giftCard) => acc + Number(giftCard.amountUsed.amount) * 100,
    0
  );
  const giftCardsApplied = toDollars(giftCardsAppliedInCents / 100);

  const itemsFullPrice = lineItems.reduce((acc, lineItem) => {
    const { priceFull } = lineItemPricing(lineItem);
    return acc + priceFull;
  }, 0);
  const cartSubtotal = toDollars(cart.cost.subtotalAmount.amount);

  return (
    <section ref={parentRef}>
      <Drawer
        title={null}
        placement="right"
        getContainer={() => parentRef.current || window.document.body}
        onClose={onClose}
        open={!isWebview && isOpen}
        width={isMobile ? 375 : 420}
        className={styles.drawer}
      >
        <div className={styles.cart}>
          <div className={styles.header}>
            {lineItems.length > 0 ? (
              <>
                <p className={classNames(styles.sectionHeading, styles.headerHeading)}>
                  {cartSubtotal} Subtotal
                </p>
                <p className={styles.itemsCount}>
                  {lineItems.reduce((sum, li) => sum + li.quantity, 0)} items
                </p>
                {cartHeaderBanners.map((banner) => {
                  return (
                    <div className={styles.cartHeaderBanner} key={banner.message}>
                      <div className={styles.cartHeaderContent}>
                        <CheckCircleFilled />
                        <p>{banner.message}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className={classNames(styles.sectionHeading, styles.headerHeading)}>
                Your Cart is Empty
              </p>
            )}
          </div>
          <div className={styles.body}>
            {lineItems.length > 0 ? (
              <>
                <div className={styles.lineItems}>
                  {lineItems.map((lineItem) => {
                    return (
                      <CartItem
                        key={lineItem.id}
                        lineItem={lineItem}
                        inventory={lineItem.merchandise.quantityAvailable}
                        onQuantityChange={onQuantityChange(lineItem)}
                        removeLineItem={removeLineItem(lineItem)}
                        className={styles.lineItem}
                        // only the most recently added upsell match should be shown
                        showUpsell={
                          lineItem.id ===
                          lineItemsWithUnaddedUnclosedCartUpsells[
                            lineItemsWithUnaddedUnclosedCartUpsells.length - 1
                          ]?.id
                        }
                      />
                    );
                  })}
                </div>

                {DiscountCodeSection}
                {ShippingSection}
                {lineItems.length > 0 && (
                  <div className={styles.section}>
                    <div>
                      <p className={styles.sectionHeading}>Order Summary</p>
                      <table className={styles.table}>
                        <tbody>
                          <tr>
                            <td>Items:</td>
                            <td>{toDollars(itemsFullPrice)}</td>
                          </tr>
                          {hasDiscounts && (
                            <tr>
                              <td>Discounts:</td>
                              <td>-{toDollars(discountTotal)}</td>
                            </tr>
                          )}
                          <tr>
                            <td>Shipping:</td>
                            <td>{shippingCost}</td>
                          </tr>
                          <tr>
                            <td>Sales Tax:</td>
                            <td>See at checkout</td>
                          </tr>
                          {hasGiftCards && (
                            <tr>
                              <td>PRO Credits:</td>
                              <td>-{giftCardsApplied}</td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <strong>Subtotal:</strong>
                            </td>
                            <td>
                              <strong>{cartSubtotal}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <CartProUpsell />
                {PromosSection}
              </>
            ) : (
              <>
                {PromosSection}
                {DiscountCodeSection}
                <CartProUpsell />
                {ShippingSection}
              </>
            )}
          </div>
          <div className={styles.footer}>
            <OmniaButton
              kind="primary"
              size="lg"
              isDisabled={lineItems.length === 0 || !checkoutUrl}
              onPress={() => {
                if (affiliateSource) {
                  apiV1.cartAffiliate({
                    cart_id: cart.id,
                    campaign_source: affiliateSource,
                    campaign: affiliateCampaign,
                  });
                }
                window.location.href = checkoutUrl;
              }}
            >
              Check Out
            </OmniaButton>
          </div>
        </div>
      </Drawer>
    </section>
  );
};
