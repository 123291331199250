import { Waterbody, WaterbodyDetail } from '@omniafishing/core';
import Cookies from 'js-cookie';
import ls from 'localstorage-slim';
import _ from 'lodash';
import { apiV1 } from './lib/api';
import { StorageKeys } from './lib/local_storage';
import { ONE_DAY_IN_SECONDS } from './lib/time';
import { CookieKeys, INTERNALS } from './redux/cookie_storage_middleware';

// migrations for locally stored data in cookies or localstorage

export function migrateLocalData() {
  const cookieConfig = INTERNALS.getCookieConfig();

  // 04/16/2019 localstorage to cookies
  (() => {
    // this should never change, its just for migrating old localstorage keys to cookies
    enum HISTORICAL_LOCALSTORAGE_KEYS {
      refreshToken = 'refreshToken',
      accessToken = 'accessToken',
      accessTokenExpiration = 'accessTokenExpiration',
    }

    if (typeof localStorage !== 'undefined') {
      const accessToken = localStorage.getItem(HISTORICAL_LOCALSTORAGE_KEYS.accessToken);
      if (accessToken) {
        Cookies.set(CookieKeys.accessToken, accessToken, cookieConfig);
        localStorage.removeItem(HISTORICAL_LOCALSTORAGE_KEYS.accessToken);
      }

      const accessTokenExpiration = localStorage.getItem(
        HISTORICAL_LOCALSTORAGE_KEYS.accessTokenExpiration
      );
      if (accessTokenExpiration) {
        Cookies.set(CookieKeys.accessTokenExpiration, accessTokenExpiration, cookieConfig);
        localStorage.removeItem(HISTORICAL_LOCALSTORAGE_KEYS.accessTokenExpiration);
      }

      const refreshToken = localStorage.getItem(HISTORICAL_LOCALSTORAGE_KEYS.refreshToken);
      if (refreshToken) {
        Cookies.set(CookieKeys.refreshToken, refreshToken, cookieConfig);
        localStorage.removeItem(HISTORICAL_LOCALSTORAGE_KEYS.refreshToken);
      }
    }
  })();

  // 07/20/2022 season user preferences migrate to a TTL version
  (() => {
    enum StorageKeysAtThisTime {
      userPreferencesSeasonGroup = 'omnia_userPreferences_seasonGroup',
    }
    const seasonGroup = JSON.parse(
      localStorage.getItem(StorageKeysAtThisTime.userPreferencesSeasonGroup)
    );
    if (seasonGroup && !seasonGroup.hasOwnProperty('ttl')) {
      localStorage.removeItem(StorageKeysAtThisTime.userPreferencesSeasonGroup);
      ls.set(StorageKeysAtThisTime.userPreferencesSeasonGroup, seasonGroup, {
        ttl: ONE_DAY_IN_SECONDS,
      });
    }
  })();
}

export function migrateUserPrefsWaterbodiesMissingLocales() {
  const userPrefsWaterbodies = JSON.parse(
    localStorage.getItem(StorageKeys.userPreferencesWaterbodies)
  ) as Waterbody[];
  if (userPrefsWaterbodies && userPrefsWaterbodies.some((w) => w.locales == null)) {
    const newUserPrefsWaterbodies = userPrefsWaterbodies.map((w) => {
      if (w.locales == null) {
        return {
          ...w,
          locales: [],
        };
      }

      return w;
    });
    localStorage.setItem(
      StorageKeys.userPreferencesWaterbodies,
      JSON.stringify(newUserPrefsWaterbodies)
    );
  }
}

// Any old user preferences waterbodies that were Waterbody, upgrade them to WaterbodyDetail
export async function migrateUserPrefsWaterbodiesMissingSpecies() {
  const userPrefsWaterbodies = JSON.parse(
    localStorage.getItem(StorageKeys.userPreferencesWaterbodies)
  ) as WaterbodyDetail[];

  if (!userPrefsWaterbodies) {
    return;
  }

  const userPrefsWaterbodiesWithoutSpecies = userPrefsWaterbodies.filter((w) => w.species == null);

  if (!userPrefsWaterbodiesWithoutSpecies.length) {
    return;
  }

  const newUserPrefsWaterbodies = [...userPrefsWaterbodies];

  const requests = userPrefsWaterbodiesWithoutSpecies.map((w) => apiV1.waterbodyFetch(w.url_slug));

  const promises = await Promise.allSettled(requests);

  promises.forEach((promise, i) => {
    const requestWaterbodyId = userPrefsWaterbodies[i].id;
    const index = _.findIndex(newUserPrefsWaterbodies, (w) => w.id === requestWaterbodyId);
    if (promise.status === 'rejected') {
      newUserPrefsWaterbodies.splice(index, 1);
    } else {
      const response = promise.value;
      const waterbody = response.data.data;
      if (index > -1) {
        newUserPrefsWaterbodies[index] = waterbody;
      }
    }
  });

  localStorage.setItem(
    StorageKeys.userPreferencesWaterbodies,
    JSON.stringify(newUserPrefsWaterbodies)
  );
}
