import { CloseOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { Collapse, Drawer } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  getNavigationGroups,
  NavigationGroupLowercase,
  NavigationGroupNames,
} from '../../categories_subcategories';
import { useUser } from '../../hooks/use_user';
import { OmniaUrls } from '../../lib/urls';
import { isAmbassador } from '../../lib/user';
import { ClickKey_TopNav, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { getCategories } from '../../redux/reference_data';
import { getWidth } from '../../redux/window';
import { RoutePaths } from '../../routes';
import SvgLock from '../svg/lock';
import SvgUser from '../svg/user';
import { AmbassadorBadge } from './ambassador_badge';
import styles from './header_mobile.less';

interface MobileDrawerProps {
  closeMenu: () => void;
  menuOpen: boolean;
  width?: number;
}

export const MobileDrawer = (props: MobileDrawerProps) => {
  const { closeMenu, menuOpen, width } = props;
  const isLoggedIn = useSelector(hasAccessToken);
  const categories = useSelector(getCategories);
  const { user } = useUser();
  const location = useLocation();
  const windowWidth = useSelector(getWidth);
  const [activePanelKeys, setActivePanelKeys] = useState<string[]>([]);

  const webAnalyticsTopNavHelper = (click_key: ClickKey_TopNav, target_url?: string) => {
    WebAnalytics.topNavClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
    });
  };

  const { Panel } = Collapse;

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={closeMenu}
      open={menuOpen}
      forceRender={true}
      destroyOnClose={true}
      bodyStyle={{ padding: 0 }}
      width={width || windowWidth * 0.86}
    >
      <section className={styles.drawer}>
        <header className={styles.drawerHeader}>
          <div className={styles.closeIcon}>
            <CloseOutlined onClick={closeMenu} />
          </div>
          {isLoggedIn ? (
            <NavLink
              to={RoutePaths.DASHBOARD}
              className={classNames(styles.nav_link, styles.nav_link__userInfo)}
              onClick={() => {
                webAnalyticsTopNavHelper('[top_nav].(dashboard)', RoutePaths.DASHBOARD);
                closeMenu();
              }}
            >
              <div className={styles.dashboardLink}>
                <SvgUser className={styles.userIcon} />
                <span>View Dashboard</span>
              </div>
              {isAmbassador(user) && (
                <div>
                  <span className={styles.navBadge}>
                    <AmbassadorBadge user={user} />
                  </span>
                </div>
              )}
            </NavLink>
          ) : (
            <div className={styles.authActions}>
              <NavLink
                className={styles.nav_link}
                to={{
                  pathname: RoutePaths.SIGNUP,
                  state: {
                    from: location.pathname,
                  },
                }}
                onClick={() => {
                  webAnalyticsTopNavHelper('[top_nav].(signup)', RoutePaths.SIGNUP);
                  closeMenu();
                }}
              >
                <SvgUser className={styles.userIcon} />
                <span>Sign Up</span>
              </NavLink>
              <NavLink
                className={styles.nav_link}
                to={{
                  pathname: RoutePaths.LOGIN,
                  state: {
                    from: location.pathname,
                  },
                }}
                onClick={() => {
                  webAnalyticsTopNavHelper('[top_nav].(login)', RoutePaths.SIGNUP);
                  closeMenu();
                }}
              >
                <SvgLock className={styles.lockIcon} />
                <span>Log In</span>
              </NavLink>
            </div>
          )}
        </header>

        <nav className={styles.drawerNav}>
          <div className={styles.section}>
            <span className={styles.sectionHeading}>Shop</span>
            <ul className={styles.list}>
              <li className={styles.navItem} onClick={closeMenu}>
                <Link
                  to={RoutePaths.BRANDS}
                  className={styles.brandsLink}
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  Brands
                </Link>
              </li>
              {getNavigationGroups(categories).map((group) => {
                const isDealsGroup = group.name === NavigationGroupNames.DEALS;
                const isIceGroup = group.name === NavigationGroupNames.ICE;
                if (isIceGroup) {
                  return;
                }
                const lowerCaseGroupName = group.name.toLowerCase() as NavigationGroupLowercase;
                if (group.url) {
                  return (
                    <li className={styles.navItem} key={`li:${group.name}`} onClick={closeMenu}>
                      <NavLink
                        to={group.url}
                        className={styles.navLink}
                        onClick={() => {
                          webAnalyticsTopNavHelper(`[top_nav].(${lowerCaseGroupName})`, group.url);
                          closeMenu();
                        }}
                      >
                        {group.name}
                        {isDealsGroup && (
                          <span className={styles.dealsIcon}>
                            <DollarCircleOutlined />
                          </span>
                        )}
                      </NavLink>
                    </li>
                  );
                }

                return (
                  <li
                    key={`li:${group.name}`}
                    className={styles.navItem}
                    onClick={() => {
                      webAnalyticsTopNavHelper(`[top_nav].(${lowerCaseGroupName})`, group.name);
                    }}
                  >
                    <Collapse
                      expandIconPosition="end"
                      className={styles.collapse}
                      activeKey={activePanelKeys}
                      onChange={(keys) => {
                        if (Array.isArray(keys)) {
                          setActivePanelKeys(keys);
                        } else {
                          setActivePanelKeys([keys]);
                        }
                      }}
                    >
                      <Panel key={group.name} header={group.name}>
                        <ul className={styles.navGroupList}>
                          {group.categories.map((category) => {
                            if (group.name === NavigationGroupNames.BAIT) {
                              return (
                                <li key={`link:${category.name}`}>
                                  <Link
                                    to={OmniaUrls.category(category)}
                                    className={styles.navGroupListLink}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      closeMenu();
                                      setActivePanelKeys([]);
                                    }}
                                  >
                                    {category.display_name}
                                  </Link>
                                </li>
                              );
                            }

                            return category.subcategories
                              .filter((s) => !s.nav_hidden)
                              .map((subcategory) => (
                                <li key={subcategory.url_path} onClick={closeMenu}>
                                  <Link
                                    to={`${RoutePaths.CATEGORIES}/${category.url_path}/${subcategory.url_path}`}
                                    className={styles.navGroupListLink}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      closeMenu();
                                      setActivePanelKeys([]);
                                    }}
                                  >
                                    {subcategory.display_name}
                                  </Link>
                                </li>
                              ));
                          })}
                        </ul>
                      </Panel>
                    </Collapse>
                  </li>
                );
              })}
            </ul>
          </div>
        </nav>
      </section>
    </Drawer>
  );
};
