import { LineItemCustomAttribute, LINE_ATTRIBUTION } from '../redux/cart';
import { getPageTypeByPathname } from '../routes';

export function getLineItemCustomAttributes(path: string): LineItemCustomAttribute[] {
  const pageType = getPageTypeByPathname(path);
  const slug = path.split('/').slice(-1)[0] || '/';
  const attributes = {
    [LINE_ATTRIBUTION.PAGE_TYPE]: pageType,
    [LINE_ATTRIBUTION.URL]: slug,
  } as Record<LINE_ATTRIBUTION, string>;

  const customAttributes: LineItemCustomAttribute[] = [];
  for (const key in attributes) {
    if (attributes.hasOwnProperty(key as LINE_ATTRIBUTION)) {
      const k = key as LINE_ATTRIBUTION;
      const value = attributes[k];
      if (value != null) {
        customAttributes.push({
          key: k,
          value,
        });
      }
    }
  }

  return customAttributes;
}
