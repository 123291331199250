import { PlusOutlined } from '@ant-design/icons';
import { AlgoliaProductFamily, ProductFamily } from '@omniafishing/core';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import searchInsights from 'search-insights';
import { AlgoliaSearchEvents } from '../../algolia';
import { getEnv } from '../../env';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { useListViewed } from '../../hooks/use_list_viewed';
import { useUser } from '../../hooks/use_user';
import { WebAnalytics } from '../../lib/web_analytics';
import { SearchProductFamily } from './search_product_family';
import styles from './search_universal.less';

interface SearchProductsTabProps {
  allResultsCount?: number;
  currentQueryString: string;
  getRankingInfo?: boolean;
  isFocused: boolean;
  lastQueryId: string;
  productFamilies: AlgoliaProductFamily[] | ProductFamily[];
  onSeeAllClick?: () => void;
}

const env = getEnv();

export const SearchProductFamilyResultsList = (props: SearchProductsTabProps) => {
  const {
    allResultsCount,
    currentQueryString,
    getRankingInfo = false,
    isFocused,
    lastQueryId,
    productFamilies,
    onSeeAllClick,
  } = props;
  const { user } = useUser();
  const { coupon, createListId } = useListViewed();
  const list_uuid = useListAttributionUuid();
  const viewedRef = useRef(false);

  useEffect(() => {
    if (isFocused && !viewedRef.current) {
      viewedRef.current = true;
      WebAnalytics.productFamilyListViewed({
        productFamilies,
        coupon,
        list_id: createListId('search'),
        list_uuid,
      });
    }
  }, [productFamilies, isFocused]);

  return (
    <>
      <ul className={classNames(styles.list, styles.list__wrap, styles.list__products)}>
        {productFamilies.map((p, index) => (
          <li key={p.handle}>
            <SearchProductFamily
              productFamily={p}
              position={index}
              list_uuid={list_uuid}
              onClick={(target_url: string) => {
                if (lastQueryId) {
                  searchInsights(AlgoliaSearchEvents.clickedObjectIDsAfterSearch, {
                    index: env.ALGOLIA_INDEX_PRODUCT_FAMILIES,
                    eventName: 'search_bar_product_family_clicked',
                    queryID: lastQueryId,
                    objectIDs: [currentQueryString],
                    positions: [index + 1],
                    userToken: user?.id?.toString(),
                    getRankingInfo,
                  });
                }
                WebAnalytics.searchResultClick(currentQueryString, 'product_family', target_url);
              }}
            />
          </li>
        ))}
        {allResultsCount && allResultsCount > productFamilies.length && (
          <li>
            <div
              className={
                'flex gap-1 border border-gray-300 rounded-md p-3 h-full justify-items-center flex-col sm:text-left hover:border-black text-black hover:text-black items-center justify-center cursor-pointer'
              }
              onClick={onSeeAllClick}
            >
              <p className="text-base text-center">
                <span className="text-4xl block">
                  <PlusOutlined />
                </span>
                {allResultsCount - productFamilies.length} more products for "{currentQueryString}"
              </p>
            </div>
          </li>
        )}
      </ul>
    </>
  );
};
