import { CartPromoCartPrice, CartPromoPrices } from '@omniafishing/core';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { productFamilyPriceRange } from '../../lib/product_families';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { WebAnalytics } from '../../lib/web_analytics';
import { CartActions } from '../../redux/cart';
import { ProductFamilyQuickModalActions } from '../../redux/product_family_quick_modal';
import styles from './cart_price_promo.less';

interface PromoProps {
  promo: CartPromoCartPrice;
}

export const CartPricePromo = (props: PromoProps) => {
  const { product_family, cart_promos, message } = props.promo;

  const dispatch = useDispatch();
  const [currentCartPromo, setCurrentCartPromo] = useState(null as CartPromoPrices);
  const [nextCartPromo, setNextCartPromo] = useState(cart_promos[0]);
  const { cart } = useCart();
  const {
    lines: { nodes: lineItems },
    cost: { subtotalAmount },
  } = cart;
  const subtotal = parseFloat(subtotalAmount.amount);

  useEffect(() => {
    const cartPromo = cart_promos.find((promo) => subtotal < promo.cart_price);

    if (!_.isEqual(cartPromo, nextCartPromo)) {
      setNextCartPromo(cartPromo);
    }
  }, [subtotal]);

  useEffect(() => {
    let cartPromo = null;
    cart_promos.find((promo) => {
      if (subtotal > promo.cart_price) {
        cartPromo = promo;
      }
    });
    if (!_.isEqual(cartPromo, currentCartPromo)) {
      setCurrentCartPromo(cartPromo);
    }
  }, [subtotal]);

  const cartDifference = (nextCartPromo != null && nextCartPromo.cart_price - subtotal) || 0;
  const nextPromoItemPriceText =
    nextCartPromo != null
      ? nextCartPromo.item_price === 0
        ? 'FREE'
        : toDollars(nextCartPromo.item_price)
      : null;
  const currentPromoItemPriceText =
    currentCartPromo != null
      ? currentCartPromo.item_price === 0
        ? 'FREE'
        : toDollars(currentCartPromo.item_price)
      : null;

  const open = () => {
    dispatch(
      ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_OPEN({
        recommendedFamily: {
          product_family,
          products: currentCartPromo
            ? product_family.products.map((p) => {
                return {
                  ...p,
                  price: currentCartPromo.item_price.toString(),
                };
              })
            : product_family.products,
          contains_all_colors: true,
        },
        context: 'shopping',
        onCartAdd: () => {
          WebAnalytics.cartDealAdded({
            deal_message: message,
            cart_total_amount: parseFloat(subtotalAmount.amount),
            cart_total_items: lineItems.length,
            cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
          });
        },
      })
    );
    dispatch(CartActions.CART_CLOSE());
    WebAnalytics.productClick({
      productOrProductFamily: product_family,
      show_modal: true,
      position: null,
    });
    WebAnalytics.cartClick('[cart].(deals_product_click)', {
      cart_total_amount: parseFloat(subtotalAmount.amount),
      cart_total_items: lineItems.length,
      cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
    });
  };

  const priceRange = productFamilyPriceRange(product_family);

  return (
    <div className={styles.promo} onClick={currentCartPromo ? open : null}>
      <div className={styles.img}>
        <img src={setShopifyImgWidth(product_family.img_url, 200)} alt="" width="100" />
      </div>
      <div>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: message }} />
        {currentCartPromo && (
          <Button className={styles.currentPromo}>Add for {currentPromoItemPriceText}</Button>
        )}
        {cartDifference > 0 && (
          <p className={styles.upsell}>
            Spend <strong>{toDollars(nextCartPromo.cart_price - subtotal)}</strong> more to get this
            for <strong>{nextPromoItemPriceText}</strong> (Reg {priceRange})
          </p>
        )}
      </div>
    </div>
  );
};
