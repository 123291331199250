import { useSelector } from 'react-redux';
import { getPath } from '../redux/router';
import { useCart } from './use_cart';

export type WebAnalyticsListViewedType =
  | 'carousel'
  | 'store'
  | 'video'
  | 'search'
  | 'report'
  | 'other'
  | 'kit';

export const useListViewed = () => {
  const { cartDiscountCodes } = useCart();
  const path = useSelector(getPath);

  const createListId = (list_type: WebAnalyticsListViewedType, list_heading?: string) => {
    let listId = `${path}:${list_type}`;
    if (list_heading) {
      listId = listId + `:${list_heading}`;
    }
    return listId;
  };

  return {
    coupon: cartDiscountCodes[0]?.code,
    createListId,
  };
};
