import { Affiliate, OmniaResponse } from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';
import { CartActions } from './cart';

export const UTM_MEDIUM_AFFILIATE = 'omnia-affiliate';

export const reducerName = 'utm';

export enum StateKeys {
  utm_tags = 'utm_tags',
  affiliate_source = 'affiliate_source',
  affiliate_campaign = 'affiliate_campaign',
  affiliate_loading_state = 'affiliate_loading_state',
  referer = 'referer',
}

export const initialState = {
  [StateKeys.utm_tags]: {} as Record<'source' | 'medium' | 'name' | 'term' | 'content', string>,
  [StateKeys.referer]: null as string | undefined,
  [StateKeys.affiliate_source]: null as string,
  [StateKeys.affiliate_campaign]: null as string,
  [StateKeys.affiliate_loading_state]: LoadingState.NOT_STARTED,
};

export const getUtmTags = (state: ApplicationState) => state[reducerName][StateKeys.utm_tags];
export const getReferer = (state: ApplicationState) => state[reducerName][StateKeys.referer];
export const getAffiliateSource = (state: ApplicationState) =>
  state[reducerName][StateKeys.affiliate_source];
export const getAffiliateCampaign = (state: ApplicationState) =>
  state[reducerName][StateKeys.affiliate_campaign];
export const getAffiliateLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.affiliate_loading_state];

export function UtmReducer(state = initialState, action: UtmActions | CartActions) {
  switch (action.type) {
    case ReduxActions.AFFILIATE_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.affiliate_source]: action.payload.data.campaign_source,
        [StateKeys.affiliate_campaign]: action.payload.affiliate_campaign,
        [StateKeys.affiliate_loading_state]: LoadingState.DONE,
      };
    }
    case ReduxActions.CART_ID_FETCH_SUCCESS: {
      // null carts need to clear affiliate
      if (action.payload.data.was_cart_null) {
        return {
          ...state,
          [StateKeys.affiliate_source]: null,
          [StateKeys.affiliate_campaign]: null,
        };
      }
      return state;
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const UtmActions = {
  AFFILIATE_FETCH_PENDING: () => createAction(ReduxActions.AFFILIATE_FETCH_PENDING),
  AFFILIATE_FETCH_SUCCESS: (
    response: OmniaResponse<Affiliate> & { affiliate_campaign: string; referer: string }
  ) => createAction(ReduxActions.AFFILIATE_FETCH_SUCCESS, response),
  AFFILIATE_FETCH_ERROR: (err: any) => createAction(ReduxActions.AFFILIATE_FETCH_ERROR, err),
};
export type UtmActions = ActionsUnion<typeof UtmActions>;

export function fetchAffiliate(
  source: string,
  affiliate_campaign: string,
  referer: string
): RequestThunk {
  return (dispatch) => {
    dispatch(UtmActions.AFFILIATE_FETCH_PENDING());

    return apiV1
      .affiliateFetch(source)
      .then((response) => {
        return dispatch(
          UtmActions.AFFILIATE_FETCH_SUCCESS({
            ...response.data,
            affiliate_campaign,
            referer,
          })
        );
      })
      .catch((error) => {
        errorHandler('ERROR: fetchAffiliate', error);
        return dispatch(UtmActions.AFFILIATE_FETCH_ERROR(error));
      });
  };
}
