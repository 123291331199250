import { ListTypes } from '@omniafishing/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { hasAccessToken } from '../redux/auth';

export const useUserProducts = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const queryClient = useQueryClient();

  const userProductsFetchQuery = useQuery({
    queryKey: QueryKeys.ME.PRODUCTS,
    queryFn: async () => {
      const res = await apiV1.userProductsFetch();
      return res.data.data;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });

  const {
    data: userProducts = {
      wishlist: [],
    },
  } = userProductsFetchQuery;

  const userProductCreate = useMutation({
    mutationFn: async (args: { skus: string[]; type: ListTypes }) => {
      const { skus, type } = args;
      const res = await apiV1.userProductsCreate({
        skus,
        type,
      });
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.ME.PRODUCTS, data);
    },
  });

  const userProductDelete = useMutation({
    mutationFn: async (args: { sku: string; type: ListTypes }) => {
      const { sku, type } = args;
      const res = await apiV1.userProductDelete({
        sku,
        type,
      });
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.ME.PRODUCTS, data);
    },
  });

  return {
    userProducts,
    userProductCreate,
    userProductDelete,
  };
};
