import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { hasAccessToken } from '../redux/auth';

export const useUserProfile = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const queryClient = useQueryClient();

  const userUserProfileFetchQuery = useQuery({
    queryKey: QueryKeys.ME.USER_PROFILE,
    queryFn: async () => {
      const res = await apiV1.userUserProfileFetch();
      return res.data.data;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });

  const { data: userProfile } = userUserProfileFetchQuery;

  const updateUserProfile = useMutation({
    mutationFn: async (formData: FormData) => {
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.userProfileUpdate(formData);
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.ME.USER_PROFILE, data);
    },
  });

  return {
    userProfile,
    updateUserProfile,
  };
};
