import { ReactNode } from 'react';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { ActionsUnion, createAction } from './actions_helper';
import { CartActions } from './cart';
import { UserActions } from './user';

export const reducerName = 'flashMessage';

export enum StateKeys {
  header = 'header',
  subheader = 'subheader',
  seconds = 'seconds',
  type = 'type',
}

export type FlashMessageType = 'success' | 'info' | 'warning' | 'error';

export const initialState = {
  [StateKeys.header]: null as string,
  [StateKeys.subheader]: null as ReactNode,
  [StateKeys.seconds]: 5,
  [StateKeys.type]: 'success' as FlashMessageType,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getHeader = (state: ApplicationState) => state[reducerName][StateKeys.header];
export const getSubheader = (state: ApplicationState) => state[reducerName][StateKeys.subheader];
export const getSeconds = (state: ApplicationState) => state[reducerName][StateKeys.seconds];
export const getType = (state: ApplicationState) => state[reducerName][StateKeys.type];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function FlashMessageReducer(
  state = initialState,
  action: FlashMessageActions | UserActions | CartActions
) {
  switch (action.type) {
    case ReduxActions.FLASH_MESSAGE_SET:
      return {
        ...state,
        [StateKeys.header]: action.payload.header,
        [StateKeys.subheader]: action.payload.subheader,
        [StateKeys.seconds]: action.payload.seconds,
        [StateKeys.type]: action.payload.type,
      };

    case ReduxActions.FLASH_MESSAGE_CLEAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const FlashMessageActions = {
  FLASH_MESSAGE_SET: (args: {
    header: string;
    subheader: string;
    seconds?: number;
    type?: FlashMessageType;
  }) => {
    const { header, subheader, seconds = initialState[StateKeys.seconds], type = 'success' } = args;
    return createAction(ReduxActions.FLASH_MESSAGE_SET, { header, subheader, seconds, type });
  },
  FLASH_MESSAGE_CLEAR: () => createAction(ReduxActions.FLASH_MESSAGE_CLEAR),
};
export type FlashMessageActions = ActionsUnion<typeof FlashMessageActions>;
