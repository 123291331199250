import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { omniaStringify } from '../../hooks/use_query_string';
import { useUser } from '../../hooks/use_user';
import { useUserProfile } from '../../hooks/use_user_profile';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { isAmbassador, roleAsText } from '../../lib/user';
import { ClickKey_AccountWidget, WebAnalytics } from '../../lib/web_analytics';
import { AuthActions, hasAccessToken } from '../../redux/auth';
import { getIsMobile } from '../../redux/window';
import { DashboardPaths, RoutePaths } from '../../routes';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { DashboardParams, DashboardTabParam } from '../dashboard/dashboard';
import Loading from '../loading/loading';
import SvgCrown from '../svg/crown';
import SvgEmail from '../svg/email';
import SvgProducts from '../svg/products';
import SvgReports from '../svg/reports';
import SvgWishlist from '../svg/wishlist';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import styles from './account_widget.less';

export const AccountWidget = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { user, isPro } = useUser();
  const isLoggedIn = useSelector(hasAccessToken);
  const isMobile = useSelector(getIsMobile);
  const { userProfile } = useUserProfile();
  const { userWaterbodies } = useUserWaterbodies();

  const dispatch = useDispatch();

  const userSpeciesCount = userProfile?.species.length;
  const userLakesCount = userWaterbodies?.length;
  const userBrandsCount = userProfile?.brands.length;

  const webAnalyticsTopNavHelper = (click_key: ClickKey_AccountWidget, target_url?: string) => {
    WebAnalytics.accountWidgetClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
    });
  };

  const onLogout = () => {
    webAnalyticsTopNavHelper(
      '[account].(account_logout_link)',
      'https://www.omniafishing.com/logout'
    );
    dispatch(AuthActions.AUTH_LOGOUT());
  };

  const dashboardReportsQueryString = omniaStringify({
    [DashboardParams.TAB]: DashboardTabParam.FISHING_REPORTS,
  });

  return (
    <>
      {user && userProfile ? (
        <div className={styles.ambassadorCard} ref={ref}>
          <div className={styles.upper}>
            <div className={styles.firstContainer}>
              <UserProfileImg image={userProfile.image} imageSize={60} />
            </div>
            <div className={styles.secondContainer}>
              <div>
                <span className={styles.displayName}>{user.full_name || 'Your Account'}</span>
                <ul className={styles.userInfo}>
                  <li className={styles.userInfoItem}>
                    {isAmbassador(user) && (
                      <>
                        <AmbassadorColorDot user={user} dotSize={11} className={styles.dot} />
                        {roleAsText(user.role)} Ambassador
                        <Link
                          className={classNames(styles.navLink, styles.navLink__info)}
                          to={`${RoutePaths.AMBASSADOR_APPLICATION}`}
                          onClick={() => {
                            webAnalyticsTopNavHelper(
                              '[account].(ambassador_link)',
                              RoutePaths.AMBASSADOR_APPLICATION
                            );
                          }}
                        >
                          <InfoCircleOutlined />
                        </Link>
                      </>
                    )}
                  </li>
                  {isPro ? (
                    <li className={styles.userInfoItem}>
                      <span className={styles.userPremium}>
                        <SvgCrown className={styles.svgPremium} width={13} height={11} />
                        {user.premium_pro ? 'Omnia PRO' : 'Premium Member'}
                      </span>
                    </li>
                  ) : (
                    <li className={styles.userInfoItem}>
                      <span className={styles.userPremium}>
                        <SvgCrown className={styles.svgPremium} width={13} height={11} />
                        Join Omnia PRO
                        <Link
                          className={classNames(styles.navLink, styles.navLink__info)}
                          to={`${RoutePaths.PREMIUM_PRO}`}
                          onClick={() => {
                            WebAnalytics.proButtonClicked({
                              location: 'account_widget',
                              type: 'learn_more',
                              style: 'link',
                            });
                          }}
                        >
                          Learn More
                        </Link>
                      </span>
                    </li>
                  )}
                  <li className={styles.userInfoItem}>
                    <span className={styles.userFavorites}>
                      {userSpeciesCount && userLakesCount && userBrandsCount
                        ? `${userSpeciesCount} Species / ${userLakesCount} Lakes / ${userBrandsCount} Brands`
                        : null}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={styles.profileNav}>
                <Link
                  className={styles.navLink}
                  to={`${RoutePaths.DASHBOARD}/${DashboardPaths.PROFILE_EDIT}`}
                  onClick={() => {
                    webAnalyticsTopNavHelper(
                      '[account].(edit_profile_link)',
                      `${RoutePaths.DASHBOARD}/${DashboardPaths.PROFILE_EDIT}`
                    );
                  }}
                >
                  Edit Profile
                </Link>
                <Link
                  className={styles.navLink}
                  to={`${RoutePaths.DASHBOARD}`}
                  onClick={() => {
                    webAnalyticsTopNavHelper('[account].(view_dashboard)', RoutePaths.DASHBOARD);
                  }}
                >
                  View Dashboard
                </Link>
                <a className={styles.navLink} onClick={onLogout}>
                  Log out
                </a>
              </div>
            </div>
          </div>

          <div className={styles.verticalLine}></div>
          <div className={styles.lower}>
            <div className={styles.thirdContainer}>
              {isAmbassador(user) && (
                <div className={styles.dashNav}>
                  <SvgReports className={styles.dashNavIcon} width={18} height={18} />
                  <Link
                    to={{
                      search: dashboardReportsQueryString,
                      pathname: RoutePaths.DASHBOARD,
                    }}
                    onClick={() => {
                      webAnalyticsTopNavHelper(
                        '[account].(fishing_report_link)',
                        dashboardReportsQueryString
                      );
                    }}
                  >
                    Fishing Reports
                  </Link>
                </div>
              )}
              <div className={styles.baseNav}>
                <div className={styles.dashNav}>
                  <SvgProducts className={styles.ordersIcon} />
                  <Link
                    to={`${RoutePaths.DASHBOARD}/${DashboardPaths.ORDERS}`}
                    onClick={() => {
                      webAnalyticsTopNavHelper(
                        '[account].(order_history_link)',
                        `${RoutePaths.DASHBOARD}/${DashboardPaths.ORDERS}`
                      );
                    }}
                  >
                    {isMobile ? 'Orders' : 'Order History'}
                  </Link>
                </div>
                <div className={styles.dashNav}>
                  <SvgWishlist className={styles.dashNavIcon} width={18} height={18} />
                  <Link
                    to={`${RoutePaths.WISHLIST}/${user?.wishlist_hash}`}
                    onClick={() => {
                      webAnalyticsTopNavHelper(
                        '[account].(wishlist_link)',
                        `${RoutePaths.WISHLIST}/${user?.wishlist_hash}`
                      );
                    }}
                  >
                    Wishlist
                  </Link>
                </div>
                <div className={styles.dashNav}>
                  <SvgEmail className={styles.emailIcon} />
                  <Link
                    to={`${RoutePaths.DASHBOARD}/${DashboardPaths.EMAIL_PREFERENCES}`}
                    onClick={() => {
                      webAnalyticsTopNavHelper(
                        '[account].(email_preferences_link)',
                        `${RoutePaths.DASHBOARD}/${DashboardPaths.EMAIL_PREFERENCES}`
                      );
                    }}
                  >
                    {isMobile ? 'Alerts' : 'Email Alerts'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.ambassadorCard}>
          <Loading text={'Loading User Profile'} />
        </div>
      )}
    </>
  );
});
