import { OmniaVideo, OmniaVideoItem, Product } from '@omniafishing/core';
import { useDispatch } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { WebAnalytics } from '../../lib/web_analytics';
import { CartActions, LineItemCustomAttribute, LINE_ATTRIBUTION } from '../../redux/cart';

export const webAnalyticsVideoItemClick = (
  omnia_video_item: OmniaVideoItem,
  position: number,
  omnia_video: OmniaVideo
) => {
  const { title, publication_date, slug, livestream } = omnia_video;
  WebAnalytics.videoShoppableItemClick({
    video_title: title,
    video_pub_date: publication_date,
    video_slug: slug,
    is_livestream: livestream,
    is_top_item: position === 0,
    item_id: omnia_video_item.item_id,
    item_type: omnia_video_item.item_type,
  });
};

export const useOmniaVideoAddToCart = () => {
  const dispatch = useDispatch();
  const { addCartLinesMutation } = useCart();

  const omniaVideoAddToCart =
    (
      product: Product,
      position: number,
      omnia_video_item: OmniaVideoItem,
      omnia_video: OmniaVideo,
      list_uuid: string,
      openCart?: boolean
    ) =>
    () => {
      const { shopify_graphql_id } = product;

      const lineItemCustomAttributes: LineItemCustomAttribute[] = [
        {
          key: LINE_ATTRIBUTION.OMNIA_VIDEO_ID,
          value: omnia_video.id.toString(),
        },
      ];

      if (omnia_video.affiliate?.campaign_source) {
        lineItemCustomAttributes.push({
          key: LINE_ATTRIBUTION.AFFILIATE,
          value: omnia_video.affiliate.campaign_source,
        });
      }

      addCartLinesMutation.mutate({
        linesToAdd: [
          {
            quantity: 1,
            merchandiseId: shopify_graphql_id,
            attributes: lineItemCustomAttributes,
          },
        ],
        notification: 'flash',
        message: `${product.title} was successfully added to your cart.`,
      });

      if (openCart) {
        dispatch(CartActions.CART_OPEN());
      }
      webAnalyticsVideoItemClick(omnia_video_item, position, omnia_video);
    };

  return { omniaVideoAddToCart };
};
