import { Product, ShopifyCart } from '@omniafishing/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { QueryKeys } from '../constants/query_keys';
import { AlgoliaEvents } from '../lib/algolia_events';
import { apiV1 } from '../lib/api';
import { FacebookEvents } from '../lib/facebook_events';
import { GoogleEvents } from '../lib/google_events';
import { getLineItemCustomAttributes } from '../lib/line_item_attributes';
import {
  addCartLinesMutationGql,
  cartAttributesUpdateMutationGql,
  cartDiscountCodesUpdateMutationGql,
  cartFetchQueryGql,
  cartGiftCardCodesUpdateMutationGql,
  removeCartLinesMutationGql,
  ShopifyCartLineAddInput,
  ShopifyCartLineUpdateInput,
  shopifyClient,
  updateCartLinesMutationGql,
} from '../lib/shopify';
import { WebViewEvents } from '../lib/webview_events';
import { AddToCartParams, WebAnalytics, WebAnalyticsEventAreas } from '../lib/web_analytics';
import {
  AddItemNotifications,
  CartActions,
  getCartId,
  LineItemCustomAttribute,
} from '../redux/cart';
import { FlashMessageActions } from '../redux/flash_message';
import { LocationTypes } from '../routes';
import { useQueryParamLineItemAttribution } from './use_query_param_lineItem_attribution';
import { useUser } from './use_user';

export type WebAnalyticsListViewedType = 'carousel' | 'store' | 'video' | 'search' | 'other';

export type ProductWithListUuid = Product & {
  list_uuid?: string;
};

export const useCart = () => {
  const cartId = useSelector(getCartId);
  const { user } = useUser();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParamCustomAttributes = useQueryParamLineItemAttribution();
  const pathCustomAttributes = getLineItemCustomAttributes(location.pathname);

  const cartQuery = useQuery({
    queryKey: QueryKeys.CART.BY_ID(cartId),
    queryFn: async () => {
      const res = await shopifyClient
        .query<{ cart: ShopifyCart }>(cartFetchQueryGql, {
          cart_id: cartId,
        })
        .toPromise();
      return res.data.cart;
    },
    enabled: !!cartId,
  });
  const { data: cart } = cartQuery;
  const cartLines = cart?.lines?.nodes || [];
  const cartDiscountCodes = cart?.discountCodes || [];

  const addCartLinesMutation = useMutation({
    mutationFn: async (params: {
      linesToAdd: ShopifyCartLineAddInput[];
      notification?: AddItemNotifications;
      message?: string;
    }) => {
      const { linesToAdd } = params;
      const res = await shopifyClient
        .mutation<{
          cartLinesAdd: {
            cart: ShopifyCart;
          };
        }>(addCartLinesMutationGql, {
          cart_id: cartId,
          lines: linesToAdd,
        })
        .toPromise();

      return res.data.cartLinesAdd.cart;
    },
    onSuccess: (data, params) => {
      const { notification, message } = params;
      if (notification === 'flash') {
        dispatch(
          FlashMessageActions.FLASH_MESSAGE_SET({
            header: 'Added to Cart',
            subheader: message,
          })
        );
      }
      if (notification === 'cart') {
        dispatch(CartActions.CART_OPEN());
        WebViewEvents.cartOpen();
      }
      queryClient.setQueryData(QueryKeys.CART.BY_ID(cartId), data);
    },
  });

  const addToCart = (params: {
    productsToAdd: {
      product: ProductWithListUuid;
      quantity: number;
    }[];
    position: number;
    addToCartArea?: WebAnalyticsEventAreas;
    customAttributes?: LineItemCustomAttribute[];
    locationType?: LocationTypes;
    notification?: AddItemNotifications;
    message?: string;
    list_uuid?: string;
  }) => {
    const {
      productsToAdd,
      addToCartArea,
      customAttributes = [],
      locationType,
      notification = 'cart',
      message,
      position,
      list_uuid,
    } = params;

    const uniqCustomAttributes = _.uniqBy(
      [...pathCustomAttributes, ...queryParamCustomAttributes, ...customAttributes],
      'key'
    );
    const allProducts = productsToAdd.map(({ product }) => product);
    const linesToAdd: ShopifyCartLineAddInput[] = productsToAdd.map(({ product, quantity }) => ({
      merchandiseId: product.shopify_graphql_id,
      quantity,
      attributes: uniqCustomAttributes,
    }));
    addCartLinesMutation.mutate({
      linesToAdd,
      notification,
      message,
    });

    FacebookEvents.AddToCart(allProducts.map((p) => p.shopify_variant_id));
    GoogleEvents.AddToCart(allProducts);
    AlgoliaEvents.AddToCart(allProducts, user?.id?.toString());
    allProducts.forEach((product) => {
      const addToCartParams: AddToCartParams = {
        product,
        area: addToCartArea || WebAnalyticsEventAreas.MAIN_VIEW,
        locationType,
        position,
      };
      // different products can have different list uuids, so they are stored on the product
      if (product.list_uuid) {
        addToCartParams.list_uuid = product.list_uuid;
      } else if (list_uuid) {
        addToCartParams.list_uuid = list_uuid;
      }
      WebAnalytics.addToCart(addToCartParams);
    });
  };

  const removeCartLinesMutation = useMutation({
    mutationFn: async (params: { lineIds: string[] }) => {
      const { lineIds } = params;
      const res = await shopifyClient
        .mutation<{
          cartLinesRemove: {
            cart: ShopifyCart;
          };
        }>(removeCartLinesMutationGql, {
          cart_id: cartId,
          line_ids: lineIds,
        })
        .toPromise();

      return res.data.cartLinesRemove.cart;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.CART.BY_ID(cartId), data);
    },
  });

  const updateCartLinesMutation = useMutation({
    mutationFn: async (params: { linesToUpdate: ShopifyCartLineUpdateInput[] }) => {
      const { linesToUpdate } = params;
      const res = await shopifyClient
        .mutation<{
          cartLinesUpdate: {
            cart: ShopifyCart;
          };
        }>(updateCartLinesMutationGql, {
          cart_id: cartId,
          lines: linesToUpdate,
        })
        .toPromise();

      return res.data.cartLinesUpdate.cart;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.CART.BY_ID(cartId), data);
    },
  });

  const updateDiscountCodesMutation = useMutation({
    mutationFn: async (params: { discountCodes: string[] }) => {
      const { discountCodes } = params;
      const res = await shopifyClient
        .mutation<{
          cartDiscountCodesUpdate: {
            cart: ShopifyCart;
          };
        }>(cartDiscountCodesUpdateMutationGql, {
          cart_id: cartId,
          discount_codes: discountCodes,
        })
        .toPromise();

      return res.data.cartDiscountCodesUpdate.cart;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.CART.BY_ID(cartId), data);
    },
  });

  const applyProRewardsMutation = useMutation({
    mutationFn: async () => {
      await apiV1.userPremiumRewardsApply();
      return null;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.CART.BY_ID(cartId) });
    },
  });

  const removeProRewardsMutation = useMutation({
    mutationFn: async (params: { giftCardCodes: string[] }) => {
      const { giftCardCodes } = params;
      const res = await shopifyClient
        .mutation<{
          cartGiftCardCodesUpdate: {
            cart: ShopifyCart;
          };
        }>(cartGiftCardCodesUpdateMutationGql, {
          cart_id: cartId,
          gift_card_codes: giftCardCodes,
        })
        .toPromise();

      return res.data.cartGiftCardCodesUpdate.cart;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.CART.BY_ID(cartId), data);
    },
  });

  const updateAttributesMutation = useMutation({
    mutationFn: async (params: {
      attributes: {
        key: string;
        value: string;
      }[];
    }) => {
      const { attributes } = params;
      const res = await shopifyClient
        .mutation<{
          cartAttributesUpdate: {
            cart: ShopifyCart;
          };
        }>(cartAttributesUpdateMutationGql, {
          cart_id: cartId,
          attributes,
        })
        .toPromise();

      return res.data.cartAttributesUpdate.cart;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.CART.BY_ID(cartId), data);
    },
  });

  return {
    addCartLinesMutation,
    addToCart,
    applyProRewards: applyProRewardsMutation,
    cart,
    cartDiscountCodes,
    cartId,
    cartLines,
    removeCartLines: removeCartLinesMutation,
    removeProRewards: removeProRewardsMutation,
    updateAttributes: updateAttributesMutation,
    updateCartLines: updateCartLinesMutation,
    updateDiscountCodes: updateDiscountCodesMutation,
  };
};
