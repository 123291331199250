import Intercom from '@intercom/messenger-js-sdk';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { FrontloadProvider } from 'react-frontload';
import { useSelector } from 'react-redux';
import { StatsigProvider, StatsigSynchronousProvider, StatsigUser } from 'statsig-react';
import { Provider as UrqlProvider } from 'urql';
import { FrontLoadStateType } from './app_client';
import ErrorBoundary from './components/error_boundary/error_boundary';
import { getEnv, getServerEnv, ServerEnvs } from './env';
import { shopifyClient } from './lib/shopify';
import { WebAnalytics } from './lib/web_analytics';
import { getPath } from './redux/router';
import { getExperimentUuid } from './redux/user';
import { pageTrackAllowed } from './redux/web_analytics_middleware';
import { RootWrapper } from './routes/root_wrapper';

const env = getEnv();
export const queryClient = new QueryClient();

export const App = (props: {
  frontloadState: FrontLoadStateType;
  statsigInitializeValues: Record<string, unknown> | null;
}) => {
  const { frontloadState, statsigInitializeValues } = props;
  const path = useSelector(getPath);
  const experimentUuid = useSelector(getExperimentUuid);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const RudderAnalyticsLib = require('@rudderstack/analytics-js').RudderAnalytics;
      const rudderAnalytics: RudderAnalytics = new RudderAnalyticsLib();
      // this appears to replace window.rudderanalytics automatically
      let rudderConfig: Record<string, any> = {
        anonymousIdOptions: {
          autoCapture: {
            enabled: true,
            source: 'segment',
          },
        },
      };
      if (getServerEnv() === ServerEnvs.PRODUCTION) {
        rudderConfig = {
          ...rudderConfig,
          configUrl: 'https://rsconfig.omnia.direct',
          pluginsSDKBaseURL: 'https://rscdn.omnia.direct/v3/modern/plugins',
          destSDKBaseURL: 'https://rscdn.omnia.direct/v3/modern/js-integrations',
        };
      }
      rudderAnalytics.load(env.RUDDERSTACK_WRITE_KEY, env.RUDDERSTACK_DATA_PLANE, rudderConfig);
    }

    if (pageTrackAllowed(path)) {
      WebAnalytics.page();
    }
  }, []);

  const statsigUser: StatsigUser = {
    userID: experimentUuid,
  };

  const InnerApp = (
    <ErrorBoundary>
      <RootWrapper />
    </ErrorBoundary>
  );

  Intercom({
    app_id: env.INTERCOM_APP_ID,
  });

  return (
    <FrontloadProvider initialState={frontloadState}>
      <UrqlProvider value={shopifyClient}>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          {statsigInitializeValues ? (
            <StatsigSynchronousProvider
              initializeValues={statsigInitializeValues}
              sdkKey={env.STATSIG_CLIENT_KEY}
              user={statsigUser}
            >
              {InnerApp}
            </StatsigSynchronousProvider>
          ) : (
            <StatsigProvider
              waitForInitialization={false}
              sdkKey={env.STATSIG_CLIENT_KEY}
              user={statsigUser}
            >
              {InnerApp}
            </StatsigProvider>
          )}
        </QueryClientProvider>
      </UrqlProvider>
    </FrontloadProvider>
  );
};
