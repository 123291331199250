import { useSelector } from 'react-redux';
import {
  getBrands,
  getCategories,
  getSeasonGroups,
  getSeasons,
  getSpecies,
  getStructures,
  getSubcategories,
  getSubcatTypes,
  getTechniques
} from '../redux/reference_data';

export const useReferenceData = () => {
  const allSpecies = useSelector(getSpecies);
  const allTechniques = useSelector(getTechniques);
  const allSeasons = useSelector(getSeasons);
  const allSeasonGroups = useSelector(getSeasonGroups);
  const allStructures = useSelector(getStructures);
  const allCategories = useSelector(getCategories);
  const allSubcategories = useSelector(getSubcategories);
  const allSubcatTypes = useSelector(getSubcatTypes);
  const allBrands = useSelector(getBrands);

  return {
    allBrands,
    allCategories,
    allSeasonGroups,
    allSeasons,
    allSpecies,
    allStructures,
    allTechniques,
    allSubcategories,
    allSubcatTypes,
  };
};
