import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { apiV1 } from '../../lib/api';
import { hasAccessToken } from '../../redux/auth';

export const UserCartAssociator = (): null => {
  const { cart } = useCart();
  const id = cart?.id;
  const isLoggedIn = useSelector(hasAccessToken);

  useEffect(() => {
    if (isLoggedIn && id) {
      apiV1.cartAssociate({ cart_id: id });
    }
  }, [id, isLoggedIn]);

  return null;
};
