import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { useQueryString } from '../../hooks/use_query_string';
import { GlobalQueryParams } from '../../lib/query_string';
import { getCartId } from '../../redux/cart';

export const CartDiscountHandler = (): null => {
  const cartId = useSelector(getCartId);
  const { updateDiscountCodes, cartLines } = useCart();
  const discountApplied = useRef(false);
  const { getCurrentQuery, replaceQueryString } = useQueryString();
  const { add_to_cart, discount_code } = getCurrentQuery<{
    [GlobalQueryParams.add_to_cart]: string;
    [GlobalQueryParams.discount_code]: string;
  }>();
  const cartContainsAddToCartItem =
    !!add_to_cart && cartLines.some((lineItem) => lineItem.merchandise.id === atob(add_to_cart));

  useEffect(() => {
    if (discountApplied.current) {
      return;
    }
    if (cartId && discount_code != null) {
      // if add_to_cart is present apply discount code after the add to cart
      if ((add_to_cart && cartContainsAddToCartItem) || !add_to_cart) {
        updateDiscountCodes.mutate({ discountCodes: [discount_code] });
        replaceQueryString({ [GlobalQueryParams.discount_code]: null });
        discountApplied.current = true;
      }
    }
  }, [cartId, discount_code, add_to_cart, cartContainsAddToCartItem]);

  return null;
};
