import {
  AmbassadorDetail,
  AmbassadorDetailExcludes,
  AmbassadorEventNotifications,
  AmbassadorOptions,
  OmniaResponse,
} from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { AMBASSADOR_EXCLUDE_ALL, apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';

export const reducerName = 'userProfile';
export type reducerType = typeof initialState;

export enum StateKeys {
  loadingState = 'loadingState',
  userProfile = 'userProfile',
  eventNotificationLoadingState = 'eventNotificationLoadingState',
  ambassadors = 'ambassadors',
  ambassadorsLoadingState = 'ambassadorsLoadingState',
  favoriteLoadingState = 'favoriteLoadingState',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.userProfile]: null as AmbassadorDetail | null,
  [StateKeys.eventNotificationLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.ambassadors]: [] as AmbassadorDetail[],
  [StateKeys.ambassadorsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.favoriteLoadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getUserProfilePublic = (state: ApplicationState) =>
  state[reducerName][StateKeys.userProfile];
export const getEventNotificationLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.eventNotificationLoadingState];
export const getAmbassadors = (state: ApplicationState) =>
  state[reducerName][StateKeys.ambassadors];
export const getAmbassadorsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.ambassadorsLoadingState];
export const getFavoriteLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.favoriteLoadingState];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function userProfileReducer(
  state = initialState,
  action: UserProfileActions
): reducerType {
  switch (action.type) {
    case ReduxActions.USER_PROFILE_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.userProfile]: null,
      };

    case ReduxActions.USER_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.userProfile]: action.payload.data,
      };

    case ReduxActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_PENDING: {
      return {
        ...state,
        [StateKeys.eventNotificationLoadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: {
      return {
        ...state,
        [StateKeys.eventNotificationLoadingState]: LoadingState.DONE,
        [StateKeys.userProfile]: action.payload.data,
      };
    }

    case ReduxActions.AMBASSADORS_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.ambassadors]: [],
        [StateKeys.ambassadorsLoadingState]: LoadingState.PENDING,
      };
    }
    case ReduxActions.AMBASSADORS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.ambassadors]: action.payload.data,
        [StateKeys.ambassadorsLoadingState]: LoadingState.DONE,
      };
    }

    case ReduxActions.USER_PROFILE_FAVORITE_PENDING: {
      return {
        ...state,
        [StateKeys.favoriteLoadingState]: LoadingState.PENDING,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const UserProfileActions = {
  USER_PROFILE_FETCH_PENDING: () => createAction(ReduxActions.USER_PROFILE_FETCH_PENDING),
  USER_PROFILE_FETCH_SUCCESS: (response: OmniaResponse<AmbassadorDetail>) =>
    createAction(ReduxActions.USER_PROFILE_FETCH_SUCCESS, response),
  USER_PROFILE_FETCH_ERROR: (err: any) => createAction(ReduxActions.USER_PROFILE_FETCH_ERROR, err),

  USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_PENDING: () =>
    createAction(ReduxActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_PENDING),
  USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: (response: OmniaResponse<AmbassadorDetail>) =>
    createAction(ReduxActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_SUCCESS, response),
  USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_ERROR, err),

  AMBASSADORS_FETCH_PENDING: () => createAction(ReduxActions.AMBASSADORS_FETCH_PENDING),
  AMBASSADORS_FETCH_SUCCESS: (response: OmniaResponse<AmbassadorDetail[]>) =>
    createAction(ReduxActions.AMBASSADORS_FETCH_SUCCESS, response),
  AMBASSADORS_FETCH_ERROR: (err: any) => createAction(ReduxActions.AMBASSADORS_FETCH_ERROR, err),

  USER_PROFILE_FAVORITE_PENDING: () => createAction(ReduxActions.USER_PROFILE_FAVORITE_PENDING),
  USER_PROFILE_FAVORITE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_PROFILE_FAVORITE_ERROR, err),

  USER_PROFILE_UNFAVORITE_PENDING: () => createAction(ReduxActions.USER_PROFILE_UNFAVORITE_PENDING),
  USER_PROFILE_UNFAVORITE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_PROFILE_UNFAVORITE_ERROR, err),
};
export type UserProfileActions = ActionsUnion<typeof UserProfileActions>;

export function fetchUserProfile(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(UserProfileActions.USER_PROFILE_FETCH_PENDING());

    return apiV1
      .userProfileFetch(slug)
      .then((response) => {
        return dispatch(UserProfileActions.USER_PROFILE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserProfile: ${slug}`, error);
        return dispatch(UserProfileActions.USER_PROFILE_FETCH_ERROR(error));
      });
  };
}

//
export function updateUserNotifications(
  slug: string,
  eventNotifications: AmbassadorEventNotifications,
  email: string,
  exclude?: AmbassadorDetailExcludes[]
): RequestThunk {
  return (dispatch) => {
    dispatch(UserProfileActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_PENDING());

    return apiV1
      .userEventNotificationsUpdate(slug, eventNotifications, email, AMBASSADOR_EXCLUDE_ALL)
      .then((response) => {
        return dispatch(
          UserProfileActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_SUCCESS(response.data)
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: updateUserNotifications: ${slug}`, error);
        return dispatch(UserProfileActions.USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_ERROR(error));
      });
  };
}

export function fetchAmbassadors(params: AmbassadorOptions = {}): RequestThunk {
  return (dispatch) => {
    dispatch(UserProfileActions.AMBASSADORS_FETCH_PENDING());

    return apiV1
      .ambassadorsFetch(params)
      .then((response) => {
        return dispatch(UserProfileActions.AMBASSADORS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchAmbassadors`, error);
        return dispatch(UserProfileActions.AMBASSADORS_FETCH_ERROR(error));
      });
  };
}
