import { SearchResponse } from '@algolia/client-search';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AlgoliaProduct, Product } from '@omniafishing/core';
import { Select, SelectProps } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import { productsIndex } from '../../algolia';
import { isDone, isPending, LoadingState } from '../../constants/loading_state';
import { useUser } from '../../hooks/use_user';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import styles from './product_search.less';

export interface ProductSearchProps
  extends Omit<SelectProps<ProductSelectValue | ProductSelectValue[]>, 'children'> {
  allowHiddenProducts?: boolean;
  placeholderText?: string;
  scopeSearchToBaitsOnly?: boolean;
}

export const buildValueLabel = (product: Product) => {
  return {
    value: product.sku,
    label: (
      <div className={styles.result} data-result={product}>
        <div className={styles.resultImg}>
          <img src={setShopifyImgWidth(product.img_url, 30)} />
        </div>
        <span>{product.title}</span>
      </div>
    ),
    key: product.sku,
  };
};

export const getProductsValueLabels = (products: Product[]) => {
  if (!products?.length) {
    return [];
  }
  return products.map((product) => buildValueLabel(product));
};

export interface ProductSelectValue {
  value: string;
  label: ReactElement<{ 'data-result': Product }>;
  key: string;
}

export const ProductSearch = (props: ProductSearchProps) => {
  const { allowHiddenProducts, placeholderText, scopeSearchToBaitsOnly, ...rest } = props;

  const [products, setProducts] = useState([] as AlgoliaProduct[]);
  const [loadingState, setLoadingState] = useState(LoadingState.NOT_STARTED);
  const { user } = useUser();

  const onSearchDebounced = _.debounce((value: string) => {
    const options: Record<string, string | boolean> = {
      clickAnalytics: true,
      enablePersonalization: true,
      userToken: user?.id?.toString(),
    };
    if (!allowHiddenProducts) {
      options.filters = `hidden:false`;
    }
    if (scopeSearchToBaitsOnly) {
      options.filters =
        `(category:jigs OR category:softbaits OR category:hardbaits OR category:spinnerbaits OR category:rigs)` +
        (options.filters ? ` AND ${options.filters}` : '');
    }
    setLoadingState(LoadingState.PENDING);
    productsIndex.search(value, options).then((results: SearchResponse<AlgoliaProduct>) => {
      setLoadingState(LoadingState.DONE);
      setProducts(results.hits);
    });
  }, 300);

  return (
    <Select
      showSearch
      labelInValue
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      defaultActiveFirstOption={false}
      suffixIcon={<SearchOutlined />}
      filterOption={false}
      onSearch={(val: string) => {
        onSearchDebounced(val);
      }}
      notFoundContent={
        isPending(loadingState) ? (
          <LoadingOutlined style={{ fontSize: 34, marginInline: 'auto', display: 'block' }} spin />
        ) : isDone(loadingState) ? (
          'No results'
        ) : null
      }
      placeholder={placeholderText || 'Search for products'}
      className={styles.select}
      {...rest}
    >
      {products.map((product) => (
        <Select.Option key={product.sku} value={product.sku}>
          <div className={styles.result} data-result={product}>
            <div className={styles.resultImg}>
              <img src={setShopifyImgWidth(product.img_url, 30)} />
            </div>
            <span dangerouslySetInnerHTML={{ __html: product._highlightResult.title?.value }} />
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
