import { CloseOutlined } from '@ant-design/icons';
import { ShopifyCartLine } from '@omniafishing/core';
import { InputNumber } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import { isCartUpsellProductActive } from '../../lib/cart_upsell_product';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { getCartUpsellProducts } from '../../redux/cart';
import { getPath } from '../../redux/router';
import { OtherLocationTypes, RoutePaths } from '../../routes';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { ProductLink } from '../product_link/product_link';
import { QuantityInput } from '../quantity_input/quantity_input';
import styles from './cart_item.less';
import { useUpsellProductsClosedSkus } from './use_upsell_products_closed_skus';

export const lineItemPricing = (lineItem: ShopifyCartLine) => {
  const { cost, merchandise, quantity } = lineItem;

  const price = Number(cost.totalAmount.amount);
  const largestPrice = Math.max(
    Number(merchandise.compareAtPrice.amount),
    Number(merchandise.price.amount)
  );
  const priceFull = largestPrice * quantity;
  const discountAmount = priceFull - price;
  const hasDiscount = discountAmount > 0;

  return {
    price,
    priceFull,
    discountAmount,
    hasDiscount,
  };
};

export interface CartItemProps {
  lineItem: ShopifyCartLine;
  onQuantityChange: (val: React.ReactText) => void;
  removeLineItem: () => void;
  showUpsell: boolean;
  className?: string;
  inventory: number;
}

export const CartItem = (props: CartItemProps) => {
  const { lineItem, className, showUpsell, inventory, onQuantityChange, removeLineItem } = props;
  const { merchandise } = lineItem;
  const cartUpsellProducts = useSelector(getCartUpsellProducts);
  const path = useSelector(getPath);
  const { addToCart, cart } = useCart();
  const {
    lines: { nodes: lineItems },
    cost: { subtotalAmount },
    discountCodes,
  } = cart;
  const [upsellQuantity, setUpsellQuantity] = useState(1);
  const list_uuid = useListAttributionUuid();
  const activeCartUpsellProducts = cartUpsellProducts.filter((cartUpsellProduct) =>
    isCartUpsellProductActive(cartUpsellProduct, new Date())
  );
  const cartUpsellProductMatch = activeCartUpsellProducts.find(
    (cup) => cup.item_in_cart.handle === merchandise.product.handle
  );
  const upsellProduct = cartUpsellProductMatch?.item_to_recommend;
  const cartContainsUpsellProduct = lineItems.some(
    (li) =>
      li.merchandise.product.handle ===
      cartUpsellProductMatch?.item_to_recommend.shopify_product_handle
  );
  const outOfStock = !merchandise.availableForSale;

  const src = merchandise.image ? setShopifyImgWidth(merchandise.image.url, 200) : null;
  const { price, priceFull } = lineItemPricing(lineItem);

  const selectedVariantPrice = price > 0 ? toDollars(price) : 'FREE';
  const onSale = price !== priceFull;

  const lineItemIsPremiumMembership = merchandise.product.handle === 'omnia-premium-membership';

  const { cartUpsellProductsClosedSkus, setCartUpsellProductsClosedSkus } =
    useUpsellProductsClosedSkus();
  const upsellClosed = cartUpsellProductsClosedSkus.includes(upsellProduct?.sku);
  const validUpsell =
    showUpsell && cartUpsellProductMatch && !upsellClosed && !cartContainsUpsellProduct;

  const webAnalyticsCartClickArgs = {
    cart_total_amount: parseFloat(subtotalAmount.amount),
    cart_total_items: lineItems.length,
    cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
  };

  useEffect(() => {
    if (validUpsell) {
      WebAnalytics.productListViewed({
        coupon: discountCodes[0]?.code,
        list_id: `${path}:upsell:Recommended`,
        products: [upsellProduct],
        list_uuid,
      });
    }
  }, [validUpsell]);

  return (
    <>
      <div className={classNames(styles.item, className)}>
        <div className={styles.img}>
          <Link
            to={
              lineItemIsPremiumMembership
                ? RoutePaths.PREMIUM_PRO
                : OmniaUrls.product(merchandise.product.handle, merchandise.selectedOptions)
            }
            onClick={() => {
              WebAnalytics.cartClick('[cart].(line_item_product_click)', webAnalyticsCartClickArgs);
            }}
          >
            <img src={src} alt="" width="100" />
          </Link>
        </div>
        <div>
          <p className={styles.title}>
            <Link
              to={
                lineItemIsPremiumMembership
                  ? RoutePaths.PREMIUM_PRO
                  : OmniaUrls.product(merchandise.product.handle, merchandise.selectedOptions)
              }
              onClick={() => {
                WebAnalytics.cartClick(
                  '[cart].(line_item_product_click)',
                  webAnalyticsCartClickArgs
                );
              }}
            >
              {merchandise.product.title} - {merchandise.title}
            </Link>
          </p>
          <p className={styles.price}>
            {outOfStock ? (
              <span>Out of Stock</span>
            ) : (
              <>
                <span className={classNames({ [styles.price__sale]: onSale })}>
                  {selectedVariantPrice}
                </span>
                {onSale && <s className={styles.priceCompare}>{toDollars(priceFull)}</s>}
              </>
            )}
          </p>

          <div className="flex gap-2 items-center">
            <QuantityInput
              max={inventory}
              quantity={lineItem.quantity}
              onChange={onQuantityChange}
              disabled={outOfStock}
            />
            <button className={styles.remove} onClick={removeLineItem}>
              Remove
            </button>
          </div>
        </div>
      </div>

      {validUpsell && (
        <>
          <div className={styles.upsell}>
            <div className={styles.upsellProduct}>
              <p className={styles.upsellHeading}>
                Recommended
                <span>with the {cartUpsellProductMatch.item_in_cart.title}</span>
              </p>
              <div className={styles.upsellContent}>
                <div className={styles.upsellLeft}>
                  <div className={styles.upsellImg}>
                    <ProductLink
                      product={upsellProduct}
                      locationType={OtherLocationTypes.CART_UPSELL}
                      list_uuid={list_uuid}
                    >
                      <img src={setShopifyImgWidth(upsellProduct.img_url, 100)} alt="" />
                    </ProductLink>
                  </div>
                  <p className={styles.upsellTitle}>
                    <ProductLink
                      product={upsellProduct}
                      currentColor
                      locationType={OtherLocationTypes.CART_UPSELL}
                      list_uuid={list_uuid}
                    >
                      {upsellProduct.title}
                    </ProductLink>
                  </p>
                </div>

                <div className={styles.upsellRight}>
                  <div className={styles.upsellPrice}>{toDollars(upsellProduct.price)}</div>
                  <div className={styles.inputWrapper}>
                    <InputNumber
                      value={upsellQuantity}
                      onChange={(val) => {
                        setUpsellQuantity(val);
                      }}
                    />
                    <OmniaButton
                      size="md"
                      kind="secondary"
                      fontSize={14}
                      block
                      style={{ padding: '4px 8px' }}
                      onPress={() => {
                        addToCart({
                          productsToAdd: [
                            {
                              product: upsellProduct,
                              quantity: upsellQuantity,
                            },
                          ],
                          locationType: OtherLocationTypes.CART_UPSELL,
                          position: null,
                          list_uuid,
                        });
                      }}
                    >
                      Add
                    </OmniaButton>
                  </div>
                  <OmniaLinkButton
                    kind="tertiary"
                    size="md"
                    to={OmniaUrls.product(
                      upsellProduct.shopify_product_handle,
                      upsellProduct.shopify_options
                    )}
                    block
                    onPress={() => {
                      WebAnalytics.productClick({
                        productOrProductFamily: upsellProduct,
                        show_modal: false,
                        position: null,
                        locationType: OtherLocationTypes.CART_UPSELL,
                        list_uuid,
                      });
                    }}
                  >
                    Explore Options
                  </OmniaLinkButton>
                </div>
              </div>
              <CloseOutlined
                className={styles.upsellClose}
                onClick={() => {
                  setCartUpsellProductsClosedSkus([
                    ...cartUpsellProductsClosedSkus,
                    upsellProduct.sku,
                  ]);
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
