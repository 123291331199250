import { MailOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FishingReport, Product, ProductFamily, ShopifyProduct } from '@omniafishing/core';
import { InputNumber } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { useSelector } from 'react-redux';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { addToCartText } from '../../lib/add_to_cart_text';
import { getDistanceBetweenTwoPoints } from '../../lib/distance_between_two_points';
import { isProductInStock } from '../../lib/products';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { getIpLatitude, getIpLongitude, getIpState } from '../../redux/geographic_location';
import { SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX } from '../../routes/product_detail_page/product_detail_page';
import base from '../../styles/base.less';
import { AddToWishlist } from '../add_to_wishlist/add_to_wishlist';
import { ClarityRange } from '../clarity_range/clarity_range';
import { NewBadge } from '../new_badge/new_badge';
import { OmniaButton } from '../omnia_button/omnia_button';
import { ProductCarryModal } from '../product_carry_modal/product_carry_modal';
import { ReportRecommendationBadge } from '../product_variant_selector/report_recommendation_badge';
import styles from './product_detail_grid.less';

interface ProductRowProps {
  onClickAddToCart: (quantity: number) => void;
  onOutOfStockClick: (product: Product) => void;
  onViewFishingReport?: (val: string) => void;
  openAndScrollToFishingReportsPanel?: () => void;
  product: Product;
  productFamily: ProductFamily;
  productFishingReports: FishingReport[];
  shopifyProduct: ShopifyProduct | null;
}

export const ProductRow = (props: ProductRowProps) => {
  const {
    onClickAddToCart,
    onViewFishingReport,
    openAndScrollToFishingReportsPanel,
    product,
    productFamily,
    productFishingReports,
    shopifyProduct,
  } = props;
  const [quantity, setQuantity] = useState(1);
  const [visible] = useState(true);
  const [productCarryModalOpen, setProductCarryModalOpen] = useState(false);
  const ipState = useSelector(getIpState);
  const ipLatitude = useSelector(getIpLatitude);
  const ipLongitude = useSelector(getIpLongitude);
  const { userWaterbodies } = useUserWaterbodies();
  const { isDesktop } = useResponsive();
  const { isPro } = useUser();

  const hasInventory = product.inventory != null && product.inventory_tracked;
  const outOfStock = !isProductInStock(product);
  const inventoryText =
    product.inventory > 5 || !product.inventory_tracked ? '5+' : product.inventory;
  const isHidden = product.hidden;
  const hasColor = product.shopify_options.some((option) => option.name.toLowerCase() === 'color');
  const isBestSellerInIpState = product.best_seller_states.includes(ipState);

  const fishingReportsInIpState = productFishingReports.filter((f) =>
    f.waterbody.locales.map((l) => l.state.abbr).includes(ipState)
  );
  const userWaterbodyIds = userWaterbodies.map((w) => w.id);
  const fishingReportsUserWaterbodies = productFishingReports.filter((f) =>
    userWaterbodyIds.includes(f.waterbody.id)
  );

  const reportsWithin50Miles = useMemo(() => {
    return productFishingReports.filter((fr) => {
      return (
        getDistanceBetweenTwoPoints(
          { lat: ipLatitude, lng: ipLongitude },
          { lat: fr.waterbody.lat, lng: fr.waterbody.lng }
        ) <= 50
      );
    });
  }, [productFishingReports, ipLatitude, ipLongitude]);

  const hasUserWaterbodyReports = fishingReportsUserWaterbodies.length > 0;
  const hasNearbyReports = reportsWithin50Miles.length > 0;
  const hasIpStateReports = fishingReportsInIpState.length > 0;

  const hasReportRecommendation = hasUserWaterbodyReports || hasNearbyReports || hasIpStateReports;

  const reportCount = productFishingReports.length;

  const hasClarities = product.clarities.length !== 0;

  const productPrice = toDollars(product.price);
  const onSale = product.on_sale;
  const saleValue = toDollars(Number(product.compare_at_price) - Number(product.price));
  const proPriceTag = shopifyProduct?.tags.find((t) =>
    t.startsWith(SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX)
  );
  const hasProPrice = proPriceTag != null;
  const proPrice = hasProPrice
    ? Number(proPriceTag.replace(SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX, ''))
    : null;
  const proPriceValue = Number(onSale ? product.compare_at_price : product.price) - proPrice;

  const addCartText = addToCartText({ outOfStock, isHidden, productFamily: false });

  const now = dayjs();
  const createdAtDate = dayjs(product.created_at);
  const isVariantNew = now.diff(createdAtDate, 'days') <= 30;
  const isProductFamilyNew = now.diff(dayjs(productFamily.published_at), 'days') <= 30;

  const onChange = (quant: number) => {
    setQuantity(quant);
  };

  const onOutOfStockClick = () => {
    props.onOutOfStockClick(product);
  };

  const onCarryClick = () => {
    setProductCarryModalOpen(true);
  };

  const onCarryModalCancel = () => {
    setProductCarryModalOpen(false);
  };

  const disabled = outOfStock || isHidden;

  const magnifyProps = {
    smallImage: {
      src: setShopifyImgWidth(product.img_url, 500),
      alt: `${product.title} ${product.title}`,
      isFluidWidth: true,
      loading: 'lazy',
    },
    largeImage: {
      src: setShopifyImgWidth(product.img_url, 1000),
      alt: `${product.title} ${product.title}`,
      width: 700,
      height: 700,
    },
    enlargedImageContainerDimensions: {
      width: '300%',
      height: '300%',
    },
    enlargedImagePosition: isDesktop ? 'beside' : 'over',
    enlargedImageContainerClassName: styles.imgZoomed,
    isHintEnabled: false,
  };

  return (
    <div
      className={classNames(styles.row, {
        [styles.hidden]: !visible,
      })}
    >
      <div className={styles.cell} style={{ position: 'relative' }}>
        {hasColor && <i className={styles.cellHeader}>Color</i>}
        <div className={styles.cellContent}>
          <span>
            {hasColor &&
              product.shopify_options.filter((option) => option.name.toLowerCase() === 'color')[0]
                .value}
          </span>
          <span className={styles.pills}>
            {isBestSellerInIpState && (
              <span className={classNames(styles.pill)}>Best Seller in {ipState}</span>
            )}
            {hasReportRecommendation &&
              openAndScrollToFishingReportsPanel && ( // openAndScrollToFishingReportsPanel is specific to PDP
                <ReportRecommendationBadge
                  className={styles.reportBadgeWidgetContainer}
                  nearbyFishingReports={reportsWithin50Miles}
                  openAndScrollToFishingReportsPanel={openAndScrollToFishingReportsPanel}
                  selectedProductFishingReports={productFishingReports}
                  showIcon
                  userWaterbodiesFishingReports={fishingReportsUserWaterbodies}
                />
              )}
          </span>
          <div className={styles.magnifyContainer}>
            <ReactImageMagnify {...magnifyProps} />
            {(isVariantNew || isProductFamilyNew) && <NewBadge className={styles.newBadge} />}
          </div>
          {reportCount > 0 && (
            <span
              className={styles.link}
              onClick={() => {
                onViewFishingReport?.(product.sku);
              }}
            >
              <u>
                View Fishing Report{reportCount > 1 ? 's' : ''} ({reportCount})
              </u>
            </span>
          )}
        </div>
      </div>
      {product.shopify_options
        .filter((option) => option.name.toLowerCase() !== 'color')
        .map((option) => {
          return (
            <div className={styles.cell} key={option.name}>
              <i className={styles.cellHeader}>{option.name}</i>
              <div className={styles.cellContent}>{option.value}</div>
            </div>
          );
        })}
      <div className={styles.cell}>
        <i className={styles.cellHeader}>Stock</i>
        <div className={classNames(styles.cellContent)}>
          {isHidden ? (
            <>
              <span className={base.link} onClick={onCarryClick}>
                <MailOutlined /> Let us know you want this product stocked
              </span>
              <ProductCarryModal
                open={productCarryModalOpen}
                product={product}
                onClose={onCarryModalCancel}
              />
            </>
          ) : (
            <>
              {outOfStock ? (
                <OmniaButton
                  className={styles.actionsButton}
                  size="lg"
                  kind="tertiary"
                  fontSize={14}
                  onClick={onOutOfStockClick}
                >
                  {isDesktop && <MailOutlined />} Email Me
                </OmniaButton>
              ) : (
                inventoryText
              )}
            </>
          )}
        </div>
      </div>
      {hasClarities && (
        <div className={styles.cell}>
          <i className={styles.cellHeader}>Water Clarity</i>
          <div className={styles.cellContent}>
            <div className={styles.clarityLabel}>
              <ClarityRange clarities={product.clarities} />
            </div>
          </div>
        </div>
      )}
      <div className={classNames(styles.cell, {})}>
        <div className={styles.cellContent}>
          <>
            <div className={styles.priceQuantity}>
              <p
                className={classNames(styles.priceContainer, {
                  [styles.priceContainer__sale]: onSale,
                })}
              >
                {hasProPrice && isPro ? (
                  <>
                    <span className={styles.price}>
                      <span className={styles.sale}>PRO price {toDollars(proPrice)}</span>
                      <span className={styles.originalPrice}>
                        Original Price:
                        <s className={styles.priceCompare}>
                          {toDollars(onSale ? product.compare_at_price : product.price)}
                        </s>
                      </span>
                      <span className={classNames(styles.sale, styles.originalPrice)}>
                        Save {toDollars(proPriceValue)}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    {onSale ? (
                      <span className={styles.price}>
                        <span className={styles.sale}>SALE {productPrice}</span>
                        <span className={styles.originalPrice}>
                          Original Price:{''}
                          <s className={styles.priceCompare}>
                            {toDollars(product.compare_at_price)}
                          </s>
                        </span>
                        <span className={classNames(styles.sale, styles.originalPrice)}>
                          Save {saleValue}
                        </span>
                      </span>
                    ) : (
                      <span className={styles.price}>{productPrice}</span>
                    )}
                  </>
                )}
              </p>
            </div>
            <div className={styles.actionsContainer}>
              <div className={styles.actions}>
                <InputNumber
                  defaultValue={quantity}
                  className={classNames(styles.quantityInput, {
                    [styles.disabled]: disabled,
                    test: true,
                  })}
                  onChange={onChange}
                  min={1}
                  max={hasInventory && product.inventory > 0 ? product.inventory : 99}
                  disabled={disabled}
                  size="large"
                />

                <OmniaButton
                  onPress={() => onClickAddToCart(quantity)}
                  size="lg"
                  kind="primary"
                  fontSize={14}
                  isDisabled={disabled}
                  data-test="add_to_cart"
                  className={styles.addToCart}
                >
                  {addCartText}
                </OmniaButton>
              </div>
              <div className={styles.actions}>
                <AddToWishlist products={[product]} className={styles.actionsButtonWrapper}>
                  <PlusCircleOutlined /> Add to Wishlist
                </AddToWishlist>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
