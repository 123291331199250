import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryKeys } from '../../constants/query_keys';
import { getIsAuthExpired, hasAccessToken } from '../../redux/auth';
import { fetchUserCartRebate } from '../../redux/cart';

export const UserDataHandler = (): null => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasAccessToken);
  const isAuthExpired = useSelector(getIsAuthExpired);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLoggedIn && isAuthExpired === false) {
      dispatch(fetchUserCartRebate());
    }
  }, [isLoggedIn, isAuthExpired, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      queryClient.removeQueries({ queryKey: QueryKeys.ME.BASE });
    }
  }, [isLoggedIn]);

  return null;
};
