import { Product } from '@omniafishing/core';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHIPPING_FREE_CUTOFF, SHIPPING_GROUND_PRICE_DOLLARS } from '../redux/cart';
import { fetchShipping, getLoadingState, getShipping, getShippingExpired } from '../redux/shipping';
import { useCart } from './use_cart';
import { useUser } from './use_user';

export const useShipping = (product: Product | null) => {
  const dispatch = useDispatch();
  const shipping = useSelector(getShipping);
  const isShippingExpired = useSelector(getShippingExpired);
  const loadingState = useSelector(getLoadingState);
  const { isPremium } = useUser();
  const { cart } = useCart();
  const subtotalPrice = cart?.cost?.subtotalAmount?.amount;

  useEffect(() => {
    if (isShippingExpired) {
      dispatch(fetchShipping());
    }
  }, [isShippingExpired]);

  const dateExpress = dayjs(shipping?.express).format('ddd, MMM D');
  const groundStartDate = dayjs(shipping?.ground_start).format('MMM D');
  const groundEndDate = dayjs(shipping?.ground_end).format('MMM D');
  const otherStartDate = dayjs(shipping?.other_start).format('MMM D');
  const otherEndDate = dayjs(shipping?.other_end).format('MMM D');

  const subtotalFreeShipping = parseFloat(subtotalPrice) >= SHIPPING_FREE_CUTOFF;
  const productOverShippingCutoff = parseFloat(product?.price) >= SHIPPING_FREE_CUTOFF;
  let showExpress = true;
  let dateGround = `${groundStartDate}—${groundEndDate}`;

  if (product?.category_name === 'rods' || product?.brand === 'plano') {
    showExpress = false;
  }
  if (!product?.inventory_tracked) {
    dateGround = `${otherStartDate}—${otherEndDate}`;
    showExpress = false;
  }

  let shippingCost = `$${SHIPPING_GROUND_PRICE_DOLLARS}`;
  if (isPremium || subtotalFreeShipping || productOverShippingCutoff) {
    shippingCost = 'FREE';
  }

  return {
    dateExpress,
    dateGround,
    loadingState,
    shippingCost,
    showExpress,
  };
};
