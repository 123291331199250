import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { WindowActions } from '../../redux/window';

export const WindowSizeListener = (): null => {
  const dispatch = useDispatch();

  const setSize = (width: number, height: number) => {
    dispatch(WindowActions.WINDOW_SET_SIZE(width, height));
  };

  useEffect(() => {
    setSize(window.innerWidth, window.innerHeight);
    window.addEventListener(
      'resize',
      _.debounce(() => {
        setSize(window.innerWidth, window.innerHeight);
      }, 100)
    );

    window.addEventListener('load', () => {
      dispatch(WindowActions.WINDOW_SET_LOADED());
    });
  }, []);

  return null;
};
