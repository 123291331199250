import { RouteComponentProps, withRouter } from 'react-router';
import { AppStaticRouterContext } from '../../types/static-context';
import { Root } from './root';

// This will probably need to keep using withRouter to have access to the staticContext
const RootContainer = withRouter(Root);

export type RootProps = RouteComponentProps<any, AppStaticRouterContext>;

export default RootContainer;
