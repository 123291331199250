import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { hasAccessToken } from '../redux/auth';

export const useUserOrganizations = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const queryClient = useQueryClient();

  const userOrganizationsFetchQuery = useQuery({
    queryKey: QueryKeys.ME.ORGANIZATIONS,
    queryFn: async () => {
      const res = await apiV1.userOrganizationFetch();
      return res.data.data;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });

  const { data: userOrganizations = [] } = userOrganizationsFetchQuery;

  const joinOrganization = useMutation({
    mutationFn: async (slug: string) => {
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.organizationJoin(slug);
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.ME.ORGANIZATIONS, () => {
        return data;
      });
    },
  });

  const joinOrganizationByRegistrationCode = useMutation({
    mutationFn: async (registration_code: string) => {
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.organizationJoinByRegistrationCode(registration_code);
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.ME.ORGANIZATIONS, () => {
        return data;
      });
    },
  });

  const leaveOrganization = useMutation({
    mutationFn: async (slug: string) => {
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.organizationLeave(slug);
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(QueryKeys.ME.ORGANIZATIONS, () => {
        return data;
      });
    },
  });

  return {
    userOrganizations,
    joinOrganization,
    joinOrganizationByRegistrationCode,
    leaveOrganization,
  };
};
