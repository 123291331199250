import { PlusOutlined } from '@ant-design/icons';
import { ShopifyCart } from '@omniafishing/core';
import { Input } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useCart } from '../../hooks/use_cart';
import { OmniaButton } from '../omnia_button/omnia_button';
import styles from './cart.less';

interface CartDiscountCodeProps {
  cart: ShopifyCart;
}

export const CartDiscountCode = (props: CartDiscountCodeProps) => {
  const {
    cart: { discountCodes },
  } = props;

  const { updateDiscountCodes } = useCart();
  const [editting, setEditting] = React.useState(false);
  const [discountCode, setDiscountCode] = React.useState('');
  const discountApplied = discountCodes.length > 0;
  const activeDiscount = discountCodes[0];

  let content = (
    <OmniaButton
      kind="link"
      size="lg"
      fontSize={14}
      onPress={() => {
        setEditting(true);
      }}
    >
      <PlusOutlined /> Add Promo Code
    </OmniaButton>
  );

  if (editting) {
    content = (
      <>
        <Input.Group compact>
          <Input
            onChange={(e) => {
              setDiscountCode(e.target.value);
            }}
            placeholder="Promo Code"
            autoFocus
            style={{ width: '60%' }}
            value={discountCode}
          />
          <OmniaButton
            kind="tertiary"
            size="md"
            fontSize={14}
            style={{ boxShadow: 'none', height: '32px' }}
            onPress={() => {
              updateDiscountCodes.mutate(
                { discountCodes: [discountCode] },
                {
                  onSuccess: () => {
                    setEditting(false);
                    setDiscountCode('');
                  },
                }
              );
            }}
          >
            Apply
          </OmniaButton>
        </Input.Group>
      </>
    );
  }

  if (discountApplied && activeDiscount) {
    content = (
      <span className={styles.promoCode}>
        <span>
          {/* TODO: activeDiscount.applicable coloring green/red? */}
          Promo Code: <strong>{activeDiscount.code}</strong>
        </span>
        <button
          className={classNames(styles.buttonAsLink, styles.removePromo)}
          onClick={() => {
            updateDiscountCodes.mutate({ discountCodes: [] });
          }}
        >
          Remove
        </button>
      </span>
    );
  }

  return <div className={styles.section}>{content}</div>;
};
