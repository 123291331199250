import { FishingReport } from '@omniafishing/core';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '../../hooks/use_user';
import { AlgoliaEvents } from '../../lib/algolia_events';
import { apiV1 } from '../../lib/api';
import { GoogleEvents } from '../../lib/google_events';
import {
  getContainsAllColors,
  getContext,
  getListUuid,
  getProductFamily,
  getProducts,
  getRecommendationId,
  isOpen,
  ProductFamilyQuickModalActions,
} from '../../redux/product_family_quick_modal';
import { getBrandsNotHidden } from '../../redux/reference_data';
import { getPath } from '../../redux/router';
import styles from './product_family_quick_modal.less';
import { QuickSelectVariantSelector } from './quick_select_variant_selector';

export const ProductFamilyQuickModal = () => {
  const productFamily = useSelector(getProductFamily);
  const products = useSelector(getProducts);
  const visible = useSelector(isOpen);
  const brands = useSelector(getBrandsNotHidden);
  const context = useSelector(getContext);
  const containsAllColors = useSelector(getContainsAllColors);
  const recommendation_id = useSelector(getRecommendationId);
  const path = useSelector(getPath);
  const list_uuid = useSelector(getListUuid);

  const [fishingReports, setFishingReports] = useState<FishingReport[]>([]);
  const { user } = useUser();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_CLOSE());
  };
  const clear = () => {
    dispatch(ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_CLEAR());
  };

  useEffect(() => {
    if (productFamily && visible) {
      GoogleEvents.ViewItem(products[0]);
      AlgoliaEvents.ViewItem(productFamily, user?.id?.toString());
      setFishingReports([]);
      apiV1.productFamilyFishingReportsFetch(productFamily.handle).then((res) => {
        setFishingReports(res.data.data);
      });
    }
  }, [productFamily?.handle, visible]);

  useEffect(() => {
    close();
  }, [path]);

  if (!productFamily) {
    return null;
  }

  const brand = brands.filter((b) => b.name === productFamily.brand)[0];

  return (
    <Modal
      open={visible}
      onCancel={close}
      afterClose={clear}
      footer={null}
      className={styles.modal}
      width={null}
    >
      <div className={styles.wrapper}>
        <QuickSelectVariantSelector
          recommendation_id={recommendation_id}
          products={products}
          productFamily={productFamily}
          containsAllColors={containsAllColors}
          brand={brand}
          context={context}
          fishingReports={fishingReports}
          list_uuid={list_uuid}
        />
      </div>
    </Modal>
  );
};
