import { SearchResponse } from '@algolia/client-search';
import { AlgoliaWaterbodyDetail, Waterbody, WaterbodyDetail } from '@omniafishing/core';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactElement } from 'react';
import { searchClient } from '../../algolia';
import { getEnv } from '../../env';
import { useUser } from '../../hooks/use_user';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { getWaterbodyPlaceName } from '../../lib/waterbody';
import { waterbodyStatesCountiesString } from '../search_universal/search_waterbody';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import styles from './waterbody_search_select.less';

export interface WaterbodySearchSelectProps
  extends Omit<SelectProps<WaterbodySelectValue | WaterbodySelectValue[]>, 'options' | 'children'> {
  affixToParentNode?: boolean;
  displayImage?: boolean;
  includeUserPrefs?: boolean;
  onSelect?: (val: WaterbodySelectValue | WaterbodySelectValue[]) => void;
  throttleMs?: number;
  waterbodyLookupValueProp?: 'url_slug' | 'id';
}

export interface WaterbodySelectValue {
  label: ReactElement<{ 'data-result': AlgoliaWaterbodyDetail }>;
  value: string;
  key?: string;
}

const env = getEnv();
const waterbodiesIndex = searchClient.initIndex(env.ALGOLIA_INDEX_WATERBODIES);

export const createLabel = (
  waterbody: AlgoliaWaterbodyDetail | Waterbody | WaterbodyDetail,
  displayImage?: boolean,
  mode?: 'multiple' | 'tags'
) => {
  const { primary_name } = waterbody;
  return (
    <div className={styles.result} data-result={waterbody}>
      {displayImage && (
        <div className={styles.resultImg}>
          <WaterbodyImg waterbody={waterbody} size={{ width: 100, height: 80, padding: 30 }} />
        </div>
      )}
      <div
        className={classNames({
          [styles.resultText]: displayImage,
          [styles.resultText__noImage]: !displayImage,
        })}
      >
        {mode === 'multiple' ? (
          <span>{getWaterbodyPlaceName(waterbody)} </span>
        ) : (
          <>
            <strong>{primary_name}</strong>

            <span>
              {waterbodyStatesCountiesString(
                waterbody.locales.map((l) => l.state.abbr),
                _.flatten(waterbody.locales.map((l) => l.counties))
              )}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export function WaterbodySearchSelect({ throttleMs = 300, ...props }: WaterbodySearchSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const fetchRef = React.useRef(0);
  const { user } = useUser();
  const { userPreferencesWaterbodies } = useUserPreferences();
  const {
    displayImage,
    waterbodyLookupValueProp,
    includeUserPrefs = true,
    mode,
    onSelect,
    affixToParentNode,
    ...rest
  } = props;

  rest.getPopupContainer = affixToParentNode ? (trigger: any) => trigger.parentNode : undefined;

  const userPrefsWaterbodiesAsSelectValues = userPreferencesWaterbodies.slice(0, 5).map((w) => ({
    label: createLabel(w, displayImage, mode),
    value: w[waterbodyLookupValueProp],
  }));

  const [options, setOptions] = React.useState<WaterbodySelectValue[]>(
    includeUserPrefs ? userPrefsWaterbodiesAsSelectValues : []
  );

  async function fetchLakes(query: string): Promise<WaterbodySelectValue[]> {
    return waterbodiesIndex
      .search(query, {
        clickAnalytics: true,
        enablePersonalization: true,
        userToken: user?.id?.toString(),
      })

      .then((results: SearchResponse<AlgoliaWaterbodyDetail>) => {
        return results.hits.map((waterbody) => {
          return {
            label: createLabel(waterbody, displayImage, mode),
            value: waterbody[waterbodyLookupValueProp],
          };
        });
      });
  }

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      if (value.length === 0) {
        setFetching(false);
        setOptions(includeUserPrefs ? userPrefsWaterbodiesAsSelectValues : null);
      } else {
        fetchLakes(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      }
    };

    return _.throttle(loadOptions, throttleMs);
  }, [fetchLakes, throttleMs]);

  return (
    <Select<WaterbodySelectValue | WaterbodySelectValue[]>
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
      mode={mode === 'multiple' ? 'multiple' : null}
      onSelect={(val: WaterbodySelectValue | WaterbodySelectValue[]) => onSelect?.(val)}
      {...rest}
    />
  );
}
