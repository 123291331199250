import { FishingReportWithWaterbodyDetail } from '@omniafishing/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../constants/query_keys';
import { apiV1 } from '../lib/api';
import { ONE_MINUTE_IN_MS } from '../lib/time';
import { hasAccessToken } from '../redux/auth';

export const useUserFishingReports = () => {
  const isLoggedIn = useSelector(hasAccessToken);
  const queryClient = useQueryClient();

  const userFishingReportsFetchQuery = useQuery({
    queryKey: QueryKeys.ME.FISHING_REPORTS,
    queryFn: async () => {
      const res = await apiV1.userFishingReportsFetch();
      return res.data.data;
    },
    enabled: isLoggedIn,
    staleTime: 5 * ONE_MINUTE_IN_MS,
  });

  const { data: userFishingReports = [], isLoading: userFishingReportsLoading } =
    userFishingReportsFetchQuery;

  const deleteFishingReport = useMutation({
    mutationFn: async (id: number) => {
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.userFishingReportDelete(id);
      return res.data.data;
    },
    onSuccess: (data, id) => {
      queryClient.setQueryData(
        QueryKeys.ME.FISHING_REPORTS,
        (oldData: FishingReportWithWaterbodyDetail[]) => {
          return oldData.filter((report) => report.id !== id);
        }
      );
    },
  });

  const updateFishingReport = useMutation({
    mutationFn: async (params: { formData: FormData; id: number }) => {
      const { formData, id } = params;
      if (!isLoggedIn) {
        return Promise.reject('User not logged in');
      }
      const res = await apiV1.userFishingReportUpdate(id, formData);
      return res.data.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        QueryKeys.ME.FISHING_REPORTS,
        (oldData: FishingReportWithWaterbodyDetail[]) => {
          return oldData.map((report) => {
            if (report.id === data.id) {
              return data;
            }
            return report;
          });
        }
      );
    },
  });

  return {
    userFishingReports,
    userFishingReportsLoading,
    deleteFishingReport,
    updateFishingReport,
  };
};
