import {
  CheckCircleOutlined,
  MailOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Product, ProductFamily, ShopifyProduct, ShopifyVariant } from '@omniafishing/core';
import { Divider, Tooltip } from 'antd';
import classNames from 'classnames';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useExperiment } from 'statsig-react';
import { subcategoriesExcludePickup } from '../../categories_subcategories';
import { useCart } from '../../hooks/use_cart';
import { useUser } from '../../hooks/use_user';
import { addToCartText } from '../../lib/add_to_cart_text';
import { toDollars } from '../../lib/to_dollars';
import { WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { InventoryModalActions } from '../../redux/inventory_modal';
import { RoutePaths } from '../../routes';
import { SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX } from '../../routes/product_detail_page/product_detail_page';
import base from '../../styles/base.less';
import { AddToWishlist } from '../add_to_wishlist/add_to_wishlist';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { ProductCarryModal } from '../product_carry_modal/product_carry_modal';
import { QuantityInput } from '../quantity_input/quantity_input';
import { ShippingEstimator as ShippingEstimatorOld } from '../shipping_estimator/shipping_estimator';
import SvgShipped from '../svg/shipped';
import proIcons from './pro-icons.webp';
import styles from './product_variant_selector.less';
import shipFast from './shipfast.webp';
import { ShippingEstimator } from './shipping_estimator';

interface ProductVariantQuantityAddToCartProps {
  openProductGrid: () => void;
  productFamily: ProductFamily;
  selectedProduct: Product;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
  variantMatch: boolean;
}

export const ProductVariantQuantityAddToCart = (props: ProductVariantQuantityAddToCartProps) => {
  const {
    openProductGrid,
    productFamily,
    selectedProduct,
    selectedVariant,
    shopifyProduct,
    variantMatch,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const { addToCart } = useCart();
  const { isPro } = useUser();
  const [quantity, setQuantity] = useState(1);
  const [productCarryModalOpen, setProductCarryModalOpen] = useState(false);

  const discontinued = productFamily?.eol || false;
  const hasInventory = selectedProduct?.inventory != null && selectedProduct?.inventory_tracked;

  const inStock = !!selectedProduct?.in_stock;
  const outOfStock = !inStock;
  const inventoryText =
    selectedProduct?.inventory > 5 || !selectedProduct?.inventory_tracked
      ? '5+'
      : selectedProduct?.inventory;
  const anyVariantInStock = productFamily.products.some((p) => p.inventory && p.inventory > 0);

  const isHidden = selectedProduct?.hidden;

  const addCartText = addToCartText({ outOfStock, variantMatch, isHidden, productFamily: true });

  const onClickAddToCart = () => {
    addToCart({
      productsToAdd: [
        {
          product: selectedProduct,
          quantity,
        },
      ],
      position: null,
    });
  };

  const selectedVariantPrice = toDollars(selectedVariant.price.amount);
  const onSale =
    selectedVariant.compareAtPrice &&
    Number(selectedVariant.compareAtPrice.amount) > Number(selectedVariant.price.amount);
  const saleValue = toDollars(
    Number(selectedVariant.compareAtPrice.amount) - Number(selectedVariant.price.amount)
  );
  const proPriceTag = shopifyProduct?.tags.find((t) =>
    t.startsWith(SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX)
  );
  const hasProPrice = proPriceTag != null;
  const proPrice = hasProPrice
    ? Number(proPriceTag.replace(SHOPIFY_PRODUCT_TAG_PRO_PRICE_PREFIX, ''))
    : null;
  const proPriceValue =
    Number(onSale ? selectedVariant.compareAtPrice.amount : selectedVariant.price.amount) -
    proPrice;
  const showLocalShopping =
    queryString.parse(location.search)?.store === 'OMNIAFLAGSHIP' &&
    subcategoriesExcludePickup.indexOf(selectedProduct?.subcategory_name) === -1;

  const inputDisabled = !variantMatch || outOfStock || isHidden;

  const onOutOfStockClick = () => {
    dispatch(
      InventoryModalActions.INVENTORY_MODAL_OPEN(selectedProduct, WebAnalyticsEventAreas.MAIN_VIEW)
    );
  };

  const onCarryClick = useCallback(() => {
    setProductCarryModalOpen(true);
  }, []);

  const onCarryModalCancel = useCallback(() => {
    setProductCarryModalOpen(false);
  }, []);

  const onQuantityChange = useCallback((val: number) => {
    setQuantity(val);
  }, []);

  const shipFastVersion = useExperiment('pdp_new_ship_fast').config.get<'new' | 'old'>(
    'version',
    'old'
  );

  if (discontinued) {
    return null;
  }

  return (
    <>
      {hasInventory && (
        <div className={styles.stockWrapper}>
          <p className={styles.stock}>
            Stock: <span className={styles.stockValue}>{inventoryText}</span>
          </p>
          {selectedProduct?.inventory === 0 && anyVariantInStock && (
            <span className={base.link} onClick={openProductGrid}>
              See All Available Stock
            </span>
          )}
          {!anyVariantInStock && <span>Sorry, all variations out of stock</span>}
        </div>
      )}

      <Divider type="horizontal" />

      <div className={styles.priceQuantity}>
        <p className={styles.price}>
          {hasProPrice && isPro ? (
            <>
              <span className={styles.priceContainer}>
                <span className={styles.price__sale}>PRO price {toDollars(proPrice)}</span>
                <span className={styles.originalPrice}>
                  Original Price:
                  <s className={styles.priceCompare}>
                    {toDollars(
                      onSale ? selectedVariant.compareAtPrice.amount : selectedVariant.price.amount
                    )}
                  </s>
                </span>
                <span className={classNames(styles.price__sale, styles.originalPrice)}>
                  Save {toDollars(proPriceValue)}
                </span>
              </span>
            </>
          ) : onSale ? (
            <span className={styles.priceContainer}>
              <span className={styles.price__sale}>SALE {selectedVariantPrice}</span>
              <span className={styles.originalPrice}>
                Original Price:
                <s className={styles.priceCompare}>
                  {toDollars(selectedVariant.compareAtPrice.amount)}
                </s>
              </span>
              <span className={classNames(styles.price__sale, styles.originalPrice)}>
                Save {saleValue}
              </span>
            </span>
          ) : (
            <span>{selectedVariantPrice}</span>
          )}
        </p>

        <div className={styles.quantityWrapper}>
          <QuantityInput
            onChange={onQuantityChange}
            disabled={inputDisabled}
            quantity={quantity}
            max={hasInventory && selectedProduct.inventory > 0 ? selectedProduct.inventory : 99}
          />
        </div>
      </div>

      <Divider />

      <div className={styles.bottomButtons}>
        {inStock ? (
          <OmniaButton
            onPress={onClickAddToCart}
            kind="primary"
            size="lg"
            fontSize={14}
            isDisabled={inputDisabled}
            data-test="add_to_cart"
            style={{ marginLeft: '12px' }}
          >
            {addCartText}
          </OmniaButton>
        ) : (
          !isHidden && (
            <Tooltip title="Sign up to be emailed as soon as it's back in stock">
              <div>
                <OmniaButton
                  size="lg"
                  kind="primary"
                  fontSize={14}
                  onPress={onOutOfStockClick}
                  style={{ marginLeft: '12px' }}
                >
                  <MailOutlined />
                  Email Me When In Stock
                </OmniaButton>
              </div>
            </Tooltip>
          )
        )}

        {selectedProduct && (
          <AddToWishlist products={[selectedProduct]} className={styles.actionButton}>
            <PlusCircleOutlined /> Add to Wishlist
          </AddToWishlist>
        )}

        {isHidden && (
          <>
            <Tooltip title="Let us know you want this product stocked">
              <div>
                <OmniaButton size="lg" kind="tertiary" fontSize={14} onPress={onCarryClick}>
                  <MailOutlined />I want this
                </OmniaButton>
              </div>
            </Tooltip>
            <ProductCarryModal
              open={productCarryModalOpen}
              product={selectedProduct}
              onClose={onCarryModalCancel}
            />
          </>
        )}
      </div>

      {shipFastVersion === 'new' ? (
        <div className="flex flex-col gap-3 mt-3">
          <div
            className={
              'p-4 sm:p-6 bg-[#F4F4F4] rounded-2xl items-center grid grid-rows-2 sm:grid-rows-none sm:grid-cols-2 gap-2 sm:gap-3'
            }
          >
            <div className=" flex items-center gap-2 sm:gap-3">
              <div className="w-16 flex-grow-0 flex-shrink-0">
                <img src={shipFast} alt="" />
              </div>
              <p className={''}>
                <strong className="text-lg text-[#FF2A00]">We Ship Fast!</strong>
                <br />
                {selectedProduct?.inventory_tracked ? (
                  <span>
                    <strong>Order by 1pm CT, shipped today—</strong>
                    straight from our MN Warehouse
                  </span>
                ) : (
                  <span className={''}>
                    <em>
                      This item will ship directly from the manufacturer. Shipping times may vary.
                    </em>
                  </span>
                )}
              </p>
            </div>
            {selectedProduct?.inventory_tracked && (
              <div className="">
                <ShippingEstimator product={selectedProduct} availableForSale={!inputDisabled} />
              </div>
            )}
          </div>

          {!isPro && (
            <div className={'p-4 sm:p-6 flex bg-[#F4F4F4] rounded-2xl items-center gap-2 sm:gap-3'}>
              <div className="w-16 flex-grow-0 flex-shrink-0 -mb-5">
                <img src={proIcons} alt="" />
              </div>
              <div>
                <p className="text-[16px] leading-tight">
                  <strong className="font-termina ">OmniaPRO</strong> Members Get FREE Shipping,
                  Local Lake Maps & Rewards.
                </p>
              </div>
              <div>
                <OmniaLinkButton to={RoutePaths.PREMIUM_PRO} size="md" kind="tertiary">
                  Learn More
                </OmniaLinkButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.shippingContainer}>
          <SvgShipped height="2.2em" width="2.2em" />
          <p className={styles.shipFast}>
            <strong>We ship fast!</strong>
            <br />
            {selectedProduct && !selectedProduct?.inventory_tracked ? (
              <span className={styles.shipDrop}>
                <em>
                  This item will ship directly from the manufacturer. Shipping times may vary.
                </em>
              </span>
            ) : (
              <span>Orders placed before 1pm Central ship same day.</span>
            )}
          </p>
          {selectedProduct?.inventory_tracked && (
            <ShippingEstimatorOld
              product={selectedProduct}
              className={styles.shippingEstimate}
              availableForSale={selectedVariant.availableForSale}
            />
          )}
        </div>
      )}

      {showLocalShopping && (
        <div className={styles.pickup}>
          <p className={styles.pickupHeading}>
            <ShoppingCartOutlined /> Store Pickup
          </p>
          <ul className={styles.pickupList}>
            <li className={styles.pickupListItem}>
              <CheckCircleOutlined className={styles.pickupListIcon} /> Free
            </li>
            <li className={styles.pickupListItem}>
              <CheckCircleOutlined className={styles.pickupListIcon} /> Available today at <br />
              6325 Sandburg Road <br />
              Suite 100
              <br />
              Golden Valley, MN 55427 <br />
              <a className={styles.pickupListLink} href="tel:763-710-5581">
                (763) 710-5581
              </a>{' '}
              <br />
              <a className={styles.pickupListLink} href="mailto:hello@omniafishing.com">
                hello@omniafishing.com
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
